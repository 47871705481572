import { limpopo } from './lp'
import { pitToilets } from './pit-toilet-map-new'
let tooltip = document.querySelector('.tooltip')
let colors = ['#8c510a', '#d8b365', '#f6e8c3', '#c7eae5', '#5ab4ac', '#01665e']
import { GestureHandling } from 'leaflet-gesture-handling'
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css'

export function addMap() {
  const map = L.map('map', {
    zoomControl: true,
    scrollWheelZoom: false,
    dragging: true,
    zoomSnap: 0.1,
    gestureHandling: true,
  }).setView([-23.9116036, 29.3810656], 7)

  L.tileLayer(
    'https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.{ext}',
    {
      attribution:
        'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      subdomains: 'abcd',
      minZoom: 0,
      maxZoom: 20,
      ext: 'png',
    }
  ).addTo(map)

  let mapStyle = {
    color: 'gray',
    fillColor: '#000000',
    fillOpacity: 0.2,
  }

  let lpMap = L.geoJSON(limpopo, {
    style: mapStyle,
  }).addTo(map)
  map.fitBounds(lpMap.getBounds(), { padding: [10, 10] })

  function markerOptions(feature) {
    let color
    if (feature.properties.Category === 'A') {
      color = colors[0]
    }
    if (feature.properties.Category === 'B') {
      color = colors[1]
    }
    if (feature.properties.Category === 'C') {
      color = colors[2]
    }
    if (feature.properties.Category === 'D') {
      color = colors[3]
    }
    if (feature.properties.Category === 'E') {
      color = colors[4]
    }
    return {
      radius: 6,
      fillColor: color,
      color: '#000',
      weight: 1,
      opacity: 1,
      fillOpacity: 0.8,
      id: feature.properties.NatEmis,
      className:
        'marker marker_' +
        feature.properties.NatEmis +
        ' category_' +
        feature.properties.Category,
    }
  }
  function onEachFeature(feature, layer) {
    layer.on({
      mouseover: hover,
      mousemove: move,
      mouseout: mouseout,
      //   click: click,
    })
  }

  function hover(feature) {
    console.log(feature)

    let ttInner = `<div class='tt-row'><div class='tt-title'>${feature.target.feature.properties.school}</div></div>`
    ttInner += `<div class='tt-row'><div class='tt-details'>${feature.target.feature.properties.status}</div></div>`
    tooltip.innerHTML = ttInner

    let ttWidth = tooltip.offsetWidth
    // if (feature.originalEvent.clientX < window.innerWidth / 2) {
    //   tooltip.style.left = feature.originalEvent.clientX + 10 + 'px'
    // } else {
    tooltip.style.left = feature.originalEvent.clientX - ttWidth / 2 + 'px'
    // }
    tooltip.style.top = feature.originalEvent.clientY + 10 + 'px'
  }

  function move(feature) {
    let ttWidth = tooltip.offsetWidth
    // if (feature.originalEvent.clientX < window.innerWidth / 2) {
    //   tooltip.style.left = feature.originalEvent.clientX + 10 + 'px'
    // } else {
    tooltip.style.left = feature.originalEvent.clientX - ttWidth / 2 + 'px'
    // }
    tooltip.style.top = feature.originalEvent.clientY + 20 + 'px'
  }

  function mouseout() {
    tooltip.style.left = '-1000px'
  }
  setTimeout(() => {
    let layerA
    let layerB
    let layerC
    let layerD
    let layerE
    // A  //
    let f = {
      type: 'FeatureCollection',
      features: [],
    }
    f.features = pitToilets.features.filter(
      (e) => e.properties.Category === 'A'
    )
    layerA = L.geoJSON(f, {
      onEachFeature: onEachFeature,
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng)
      },
      style: markerOptions,
    }).addTo(map)

    // B  //
    f = {
      type: 'FeatureCollection',
      features: [],
    }
    f.features = pitToilets.features.filter(
      (e) => e.properties.Category === 'B'
    )
    layerB = L.geoJSON(f, {
      onEachFeature: onEachFeature,
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng)
      },
      style: markerOptions,
    }).addTo(map)

    // C  //
    f = {
      type: 'FeatureCollection',
      features: [],
    }
    f.features = pitToilets.features.filter(
      (e) => e.properties.Category === 'C'
    )
    layerC = L.geoJSON(f, {
      onEachFeature: onEachFeature,
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng)
      },
      style: markerOptions,
    }).addTo(map)

    // D //
    f = {
      type: 'FeatureCollection',
      features: [],
    }
    f.features = pitToilets.features.filter(
      (e) => e.properties.Category === 'D'
    )
    layerD = L.geoJSON(f, {
      onEachFeature: onEachFeature,
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng)
      },
      style: markerOptions,
    }).addTo(map)

    // E  //
    f = {
      type: 'FeatureCollection',
      features: [],
    }
    f.features = pitToilets.features.filter(
      (e) => e.properties.Category === 'E'
    )
    layerE = L.geoJSON(f, {
      onEachFeature: onEachFeature,
      pointToLayer: function (feature, latlng) {
        return L.circleMarker(latlng)
      },
      style: markerOptions,
    }).addTo(map)

    var overlayMaps = {
      'Not on any list': layerA,
      'Projects finished': layerB,
      'On a list/outcome uncertain': layerC,
      'Scheduled to start': layerD,
      'Merged/closed': layerE,
    }
    L.control.layers(overlayMaps).addTo(map)
    // let toiletsLayer = L.geoJSON(pitToilets, {
    //   onEachFeature: onEachFeature,
    //   pointToLayer: function (feature, latlng) {
    //     return L.circleMarker(latlng)
    //   },
    //   style: markerOptions,
    // }).addTo(map)

    // toiletsLayer.eachLayer(function (layer) {
    //   layer._path.id = layer.feature.properties.NatEmis
    // })
  }, 500)
}
