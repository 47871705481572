export let limpopo = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [31.880930000013905, -23.96793000001344],
            [31.769860000000335, -23.88821000000462],
            [31.690709999995306, -23.631709999986924],
            [31.560900000011316, -23.481139999988955],
            [31.562650000007125, -23.194959999993443],
            [31.309299999993527, -22.42775000000256],
            [31.259539999999106, -22.36650999999256],
            [30.84474999998929, -22.29263000001083],
            [30.488320000004023, -22.316439999995055],
            [30.28458999999566, -22.35435999999754],
            [30.12338999999338, -22.310690000012983],
            [30.01818503026152, -22.228193917952012],
            [29.765400000003865, -22.140559999999823],
            [29.578059999999823, -22.140019999991637],
            [29.37677000000258, -22.19513000000734],
            [29.222420000005513, -22.17819999999483],
            [29.032119999988936, -22.219249999994645],
            [28.91769000000204, -22.460460000002058],
            [28.643849999993108, -22.55661999998847],
            [28.34739000000991, -22.570960000011837],
            [28.209210000000894, -22.6627800000133],
            [28.044490000000224, -22.831030000001192],
            [27.918919999996433, -23.068969999992987],
            [27.57329000000027, -23.264930000004824],
            [27.52355000001262, -23.38727999999537],
            [27.331260000006296, -23.40280999999959],
            [27.30496948075597, -23.45979442319367],
            [26.972060000000056, -23.696369999990566],
            [26.865099999995437, -24.251259999989998],
            [26.678589999995893, -24.34302000000025],
            [26.512329999997746, -24.48741999999038],
            [26.40877988821012, -24.64612982305698],
            [26.463099709595554, -24.801953990157926],
            [26.779979999992065, -24.837570000003325],
            [26.980349999997998, -24.771789999998873],
            [27.04087999998592, -24.883070000010775],
            [27.226048098615138, -24.96405124926241],
            [27.33955999999307, -25.087369999993825],
            [27.545240000006743, -25.095079999999143],
            [27.62383999998565, -24.973259999998845],
            [27.97518000000855, -24.965720000007423],
            [28.258885719813406, -25.05172770988429],
            [28.24307999998564, -25.211509999993723],
            [28.29823867091909, -25.311053253913997],
            [28.50225571435294, -25.308874740585452],
            [28.656319639645517, -25.213946930278325],
            [28.65209056882304, -25.14274672011379],
            [28.606407090381254, -25.004848780372413],
            [28.761209999996936, -24.911469999991823],
            [28.894759999995586, -24.954120000009425],
            [29.03479000000516, -25.239919999992708],
            [29.35120000000461, -25.404000000009546],
            [29.77118274240638, -25.399903621408157],
            [30.006250684004044, -25.340285049634986],
            [30.026439999986906, -25.08507999998983],
            [30.14235592444311, -25.123535411577905],
            [30.190520000003744, -24.862369999988005],
            [30.422159999987343, -24.854409999999916],
            [30.454020000004675, -24.914439999993192],
            [30.63633999999729, -24.84977999998955],
            [30.718759999988833, -24.70626999999513],
            [30.758990109956358, -24.460225770046236],
            [30.95955218106974, -24.581474527920363],
            [31.226189818728017, -24.55600347937434],
            [31.160000000003492, -24.378150000004098],
            [31.056406679970678, -24.32293208295596],
            [31.04999999998836, -24.187899999989895],
            [31.191159999987576, -24.03159999998752],
            [31.261278142628726, -24.102797289495356],
            [31.880930000013905, -23.96793000001344],
          ],
        ],
      },
      properties: {
        AREA: 0,
        PERIMETER: 0,
        ID: 'LP',
        CAPTION: 'Limpopo',
        VALUE: 23,
      },
    },
  ],
}
