import L from 'leaflet'
import { addMap } from './map'
import { addSchoolChart } from './chart'

addMap()
addSchoolChart()

document.querySelector('.close-budget').addEventListener('click', () => {
  document.querySelector('.budget-sidebar').style.left = '-5000px'
})

document.querySelector('.budget-link').addEventListener('click', () => {
  document.querySelector('.budget-sidebar').style.left = '0px'
})
