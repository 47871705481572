export let s27schools = [
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school was earmarked to receive 10 new Enviro Loos and two Grade R toilets. The last known status of the project is \'under construction\'. It is one of the 507 schools on the SAFE Initiative list, but is labelled as "not proceeding".',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 901540478,
    School_Name: 'Allegraine Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Labelled as 'not proceeding'",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      "Learners' toilets are full so boys relieve themselves in the bush and girls share two pit toilets with the teachers",
    start_date_of_upgrade_project: '2019-10-31',
    end_date_of_project: 'unknown',
    GPS_Long: 30.38892288,
    GPS_Lat: '-24.08584994',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'Seven pit toilets will be demolished, 36 new Enviro Loos will be built and 12 will be refurbished. The project is on a list to start 1 April 2021.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924650580,
    School_Name: 'AM Mashego Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      "Learner’s toilets are in good condition, but there aren't enough of them.",
    start_date_of_upgrade_project: '2021-04-01',
    end_date_of_project: '2024-03-31',
    GPS_Long: 29.72962732,
    GPS_Lat: '-25.01440385',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The project was on a list to receive 2 new Grade R Enviro Loos, as part of the SAFE Initiative in 2019/20. A new project to refurbish all the existing 26 Enviro Loos is scheduled to start 1 April 2022',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 925661580,
    School_Name: 'Arethabeng Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '2019/2020 ad 2022-04-01',
    end_date_of_project: 'unknown',
    GPS_Long: 29.86257685,
    GPS_Lat: '-24.75600391',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'Enrollment at the school exceeds the maximum carrying capacity of a primary school so a new offshoot school needs to be established, according to the LDOE.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 925660044,
    School_Name: 'Baropodi Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      "Toilets in good condition, but there aren't enough of them.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.87537375,
    GPS_Lat: '-24.76422944',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 926540010,
    School_Name: 'Bessie Maake Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.25451902,
    GPS_Lat: '-24.01094055',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school was on the list provided to the SAFE Initiative and it was on a list in 2019 to get 14 new toilets, but the LDOE noted in 2020 that it was in consultation with the community to relocate the school to nearby Seunane Secondary school, which has "proper state of the art facilities".',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 918510040,
    School_Name: 'Bolotswi Secondary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "Male learners relieve themselves in the bush because the boys' toilets are full.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.4719,
    GPS_Lat: '-23.49279',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2019/20 the school appeared on a project list to receive 20 new Enviro Loos and five Grade R toilets and to demolish 12 pit toilets. The school was one of those on the list provided to the SAFE Initiative.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 924651323,
    School_Name: 'Bonang Bonani Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'active' and proceeding.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'The toilets are very old and have no roof or doors.',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 29.61751616,
    GPS_Lat: '-24.96614839',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'An additional 4 urinals are needed to meet the norms and standards requirements, but urgent intervention is not required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 921410065,
    School_Name: 'Chamandu Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.58200838,
    GPS_Lat: '-23.28183889',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 922250140,
    School_Name: 'Cracauw Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 28.80411713,
    GPS_Lat: '-23.41808537',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'An additional 4 urinals are needed to meet the norms and standards requirements, but urgent intervention is not required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 930321202,
    School_Name: 'Davhana Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.44606862,
    GPS_Lat: '-23.20501452',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'In 2020 the LDE noted that the school was a candidate for merger due to low enrolment, but in 2019 the Mvula Trust reported that construction had started on 3 new toilets, 18 toilets were being renovated and 18 toilets were being demolished. ',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 996606313,
    School_Name: 'Dithamaga Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Pit toilets built in 2003 that still in good condition.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.1572882,
    GPS_Lat: '-24.8374949',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school received new toilets through Asidi in 2018. Since then no further intervention is required, according to the LDE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 929311052,
    School_Name: 'Dzamba Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '2018-08-31',
    GPS_Long: 30.32934,
    GPS_Lat: '-22.81022',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2019 the school appeared on a project list to receive 10 new Enviro Loos and three Grade R toilets.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 930361019,
    School_Name: 'Gonela Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'The Grade R toilets are in such poor condition the learners relieve themselves outside.',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 30.71376987,
    GPS_Lat: '-22.81251098',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school is on a project list to receive 10 new Enviro Loos, its 36 existing Enviro Loos are to be refurbished, and 2 pit toilets will be demolished. The work is scheduled to start in April 2023.\n In 2020 the LDOE said the existing facilities were in need of maintenance.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 924651965,
    School_Name: 'Hlabi Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '2023-04-01',
    end_date_of_project: '2026-03-31',
    GPS_Long: 29.6417586,
    GPS_Lat: '-25.00967182',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2018, 19 toilets were renovated. No further intervention is required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 931331261,
    School_Name: 'Hlalelani Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '2017-11-21',
    end_date_of_project: '2018-07-20',
    GPS_Long: 30.0823533,
    GPS_Lat: '-23.15753365',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 916410294,
    School_Name: 'Hlovani Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.8885,
    GPS_Lat: '-23.39635',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 928330954,
    School_Name: 'Humbelani Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.16934142,
    GPS_Lat: '-22.94064137',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'Ten new toilets were built and 14 were renovated in 2018 and additional toilets to meet the school norms and standards were under construction in 2020, so no further intervention is required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 915530532,
    School_Name: 'Jack Mashamba Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '2017-12-13',
    end_date_of_project: '2018-08-31',
    GPS_Long: 30.39673269,
    GPS_Lat: '-23.94730574',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2018, 8 new toilets were built and 12 were renovated. According to the LDOE, in 2020, under the Asidi programme, 8 pit toilets were being demolished, 4 new toilets and a urinal as well as 3 junior toilets were being built and 12 toilets were being renovated.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 924651590,
    School_Name: 'Jafta/Bantabethu Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: -25.06027,
    GPS_Lat: '29.72168',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 922210234,
    School_Name: 'Kanana Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.13922,
    GPS_Lat: '-23.45228',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 922220361,
    School_Name: 'Kgwaredi Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.2415455,
    GPS_Lat: '-23.69347403',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The LDOE noted in 2020 that the school needed 16 new Enviro Loos, 20 exisiting Enviro Loos needed to be refurbished and 12 pit toilets needed to be demolished. The project was allocated to the IDT in 2018/19, according to the LDOE, but it is unknown whether this project was ever started or completed.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 919341800,
    School_Name: 'Khudugane Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.17725861,
    GPS_Lat: '-23.38397443',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The LDOE noted in 2020 that the school needed 6 new toilets and urinals, and maintenance was needed on the exiting facilities. The school is on a project list to receive 4 new Grade R waterborne toilets and 12 of its existing Enviro Loos will be refurbished, starting from April 2023. 19 pit toilets are also scheduled to be demolished.\n',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 916410416,
    School_Name: 'Khungulu Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 30.5242326,
    GPS_Lat: '-23.37358498',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'The LDOE noted in 2020 that the school needed additonal toilets because the number of learners had grown. But this was not considered urgent',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 925630715,
    School_Name: 'Kwata Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.15521,
    GPS_Lat: '-24.34595',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The LDOE noted in 2020 that the school needed 12 new Enviro Loos and the project had been allocated to the SAFE Initiative in 2019/20. It is not know whether the project has been started or completed. ',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 925660235,
    School_Name: 'Lamdzandvo Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      'The toilets are filthy and almost full. They have no doors and learners have no privacy.',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 30.05392101,
    GPS_Lat: '-24.74111277',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 996606606,
    School_Name: 'Lehwelere Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.76123628,
    GPS_Lat: '-24.87253012',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that 20 new Enviro Loos would be built and 10 pit toilets would be demolished as part of the 2019/20 SAFE Initiative. It is unclear whether this project has been completed. The school is also on a list to receive 6 new Enviro Loos and have of its 8 existing Enviro Loos refurbished starting in April 2023.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924650627,
    School_Name: 'Loboli Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': 'School built the toilets themselves.',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-25',
    GPS_Long: 29.71003,
    GPS_Lat: '-25.06873',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOW noted that the school needed 32 new Enviro Loos and its 16 existing Enviro Loos needed refurbishment. It is not clear whether that project was started or has been completed.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924651354,
    School_Name: 'Luckau Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 29.61091527,
    GPS_Lat: '-24.99917578',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school got one new toilet and 10 of its existing toilets were refurbished in 2018. Then, in 2020, the LDOE noted that the school needed 8 new Enviro Loos. It is unclear if this project was started.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 922210517,
    School_Name: 'Madikana Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Toilets for Grade Rs have been under construction since February 2018.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.12607,
    GPS_Lat: '-23.40942',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that school needed 42 new Enviro Loos and that 4 existing Enviro Loos need to be refurbished. 24 pit toilets needed to be demolished. It is unclear whether this project was ever started or completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 916411341,
    School_Name: 'Mafumani Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "The girls' pit toilets do not have proper seats, only bricks.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.788346,
    GPS_Lat: '-23.318234',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 26 new Enviro Loos and the demolition of 16 pit toilets. It is unclear whether this project was ever started or completed. It was on the list provided to the SAFE Initiative in 2018 and is labelled as "proceeding". ',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 919341619,
    School_Name: 'Makala Secondary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Labelled as 'active' and 'proceeding'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'The toilets are dilapidated with cracked walls and missing doors. The school inserted steel bars under the toilet seats to prevent learners from falling through.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.20821,
    GPS_Lat: '-23.42421',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924640996,
    School_Name: 'Makwe Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': 'Toilets are in good condition.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.777218,
    GPS_Lat: '-24.885842',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 922220583,
    School_Name: 'Malema Primary School 2',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.243267,
    GPS_Lat: '-23.843783',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      "In 2020, the LDOE noted that the school's existing sanitation needed maintenance, but that it was not urgent.",
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 925631114,
    School_Name: 'Mamogege Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.15649,
    GPS_Lat: '-24.4962',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 6 new Enviro Loos and the project was allocated to the SAFE Initiative. It is unclear whether this project was completed. The school is also scheduled to receive 4 new Grade R waterborne toilets, a septic tank and 10 existing Enviro Loos will be refurbished from Apri 2022.\n',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 922220620,
    School_Name: 'Mamoloko Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '1-Apr-22',
    end_date_of_project: '31-Mar-24',
    GPS_Long: null,
    GPS_Lat: '',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 16 new enviro loos, and the demolition of 11 pit toilets. The project was allocated to the SAFE Initiative but it is unclear if it was started or completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924651255,
    School_Name: 'Mareseleng Secondary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Labelled as 'active' and 'proceeding'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.62279,
    GPS_Lat: '-24.95871',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school\'s toilet facilities needed urgent maintenance. It also noted that the sanitation was "adequate" except that the existing pit toilets had not been demolished.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 918510521,
    School_Name: 'Mashao Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      "The toilets are filthy and some of the seats in the girls' toilets are broken and no longer used.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.43909095,
    GPS_Lat: '-23.47774811',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 930321936,
    School_Name: 'Masuvhelele Lower Primary',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.29216,
    GPS_Lat: '-23.05338',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2018 the school received 4 new toilets and four toilets were renovated. In 2020, the LDOE noted that no further intervention was required.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 925660747,
    School_Name: 'Matime Primary',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.01166,
    GPS_Lat: '-24.76539',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 14 new Enviro Loos, its 2 Grade R flushing toilets needed refurbishment and 4 pit toilets needed to be demolished. It is not clear if this project was completed. The school is on a project list to receive 4 new Grade R waterborne toilets have 13 Waterborne toilets refurbished and 3 pit toilets demolished from April 2023.\n',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 916420369,
    School_Name: 'Matsotsosela Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      'The toilets are just open holes with no seats and they are almost full.',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-25',
    GPS_Long: 30.82825375,
    GPS_Lat: '-23.60612998',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 14 new Enviro Loos and the demolition of 6 pit toilets. The project was allocated to the Mvula Trust in 2019 but it is not clear if it was completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 920210951,
    School_Name: 'Matsuokwane Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "The toilets are in bad condition. The walls have cracks and some don't have a roof.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 28.69941401,
    GPS_Lat: '-22.97554451',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE in 2020, although it added that maintenance would be done in 2021.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 915530792,
    School_Name: 'Mbangwa Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: null,
    GPS_Lat: '',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the existing pit toilets had to be demolished and that the Mvula Trust was instructed to carry this out. It is not clear whether this work has been completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 924641807,
    School_Name: 'Mokgoma/Phokoane Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': 'Toilets are in good condition.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.7449679,
    GPS_Lat: '-24.86908937',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school received10 new Enviro Loos around 2018. In 2020 the LDOE noted that no further intervetion was required.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 925620770,
    School_Name: 'Mokobola Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      'There are no toilets for Grade R learners.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.27803841,
    GPS_Lat: '-24.50649131',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'The school is on a LDOE list for construction that includes two disabled toilets and the renovation toilets. It is not clear whether this work has started. In 2020, the LDE noted that the school needed 6 urinals, but it is also. not clear whether this work was carried out.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 918510712,
    School_Name: 'Molai Jubilee Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': 'Toilets are in good condition.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.37890189,
    GPS_Lat: '-23.56202642',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      ' The school is on a project list to receive 8 new boys\' toilets, 14 girls\' toilets and 9 Grade R toilets. The project is scheduled to start in April 2021. The school is on the list provided to the SAFE Initiative in 2018 where it is labelled as "not proceeding".  ',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 924641838,
    School_Name: 'Moleijane Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Labelled as 'not proceeding'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Learners are using corrugated iron pit toilets.',
    start_date_of_upgrade_project: '1-Apr-21',
    end_date_of_project: '31-Mar-24',
    GPS_Long: 29.7688628,
    GPS_Lat: '-24.86771734',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that it had instructed the Mvula Trust to demolish the 12 pit toilets that were being used to discard sanitary waste. ',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 920212377,
    School_Name: 'Moloko Secondary School / not primary school as per S27 list',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      'There are 12 unused pit toilets that have not been demolished.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: null,
    GPS_Lat: '',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 10 Enviro Loos and seven Grade R toilets, the refurbishment of 12 Enviro Loos and the demolition of two pit toilets. The project was allocated to the SAFE Initiative in 2019. It is not clear if it was completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 921111481,
    School_Name: 'Monala Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "Toilets are in good condition but there aren't enough of them.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 28.53275263,
    GPS_Lat: '-23.48441537',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 12 Enviro Loos, 4 Grade R toilets and the demolition of 8 pit toilets and that it should be allocated to the SAFE Initiative in 2019/20. It is not clear if this work was started or completed. The project is labelled as "not active" on the SAFE list. ',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 905651132,
    School_Name: 'Morope-Matlala Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'not active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "The pit toilets don't have doors and there are cracks in the walls.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.60835206,
    GPS_Lat: '-24.94998872',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention is required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 918510767,
    School_Name: 'Motsipa Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.35508483,
    GPS_Lat: '-23.5107492',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention is required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 923260932,
    School_Name: 'Moupo Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.64541688,
    GPS_Lat: '-24.2738869',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school needed 14 waterborne toilets refurbished, a septic tank, a water supply system and 14 new toilets. The project was allocated to the SAFE Initiative in 2019 but it is not clear if this work was completed. It is on a project list to receive 20 Enviro Loos and a septic tank, 14 Enviro Loos will be refurbished and 12 pit toilets will be demolished. The project is scheduled to start in April 2023.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924650696,
    School_Name: 'Mphezulu Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': 'The toilets are filthy and almost full.',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 29.73533613,
    GPS_Lat: '-25.02841144',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      "In 2020, the LDOE noted that the school's existing sanitation urgently needed maintenance.",
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 928331674,
    School_Name: 'Mudimeli Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.95120347,
    GPS_Lat: '-22.79759314',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that Grade R facilities with inbuilt toilets were needed and that this project would be allocated to the IDT for 2021/22',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 918520764,
    School_Name: 'Nakampe Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: -23.39249534,
    GPS_Lat: 'MOLOTOTSI',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that 14 new Enviro Loos were built, two waterborne toilets were refurbished and 2 pit toilets demolished through public-private partnerships between the LDOE and the National Lotteries Commission. The school also appears on a project list to receive 12 Enviro Loos and four refurbished staff toilets, scheduled to start in April 2023.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 922251754,
    School_Name: 'Ncheleleng Secondary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Porject labelled as 'not active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      '87 boys share one functioning pit toilet. 16 dilapidated old pit toilets are not fit to use.',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 28.90712,
    GPS_Lat: '-23.66065',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 924651866,
    School_Name: 'Ngwanakwena Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.40192532,
    GPS_Lat: '-24.96793442',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that no intervention was required because the school was a candidate for merging.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 921250829,
    School_Name: 'Nkobo Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'The toilets are filthy and almost full. The school has no water. Leaners have to bring their own water from home.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 28.74151239,
    GPS_Lat: '-23.39993172',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the school urgently needed 6 Enviro Loos and urinals. The project was allocated to the SAFE Initiative but it is unclear if it was completed. The school is on a project list to receive 4 Gade R waterborne toilets, a septic tank and 12 Enviro Loos will be refurbished, scheduled to start in April 2022. \n',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 910651880,
    School_Name: 'Nyaane Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '1-Apr-22',
    end_date_of_project: '31-Mar-25',
    GPS_Long: 29.61743928,
    GPS_Lat: '-24.93650322',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted the school needed 16 new Enviro Loos and 4 Grade R toilets and the demolition of 27 pit toilets. The project was allocated to the SAFE Initiative. It is unclear if the work was completed. The school is on a project list to receive 12 new enviro loos, six Grade R waterborne toilets, a new septic tank, four refurbished Enviro Loos and the demolition of four pit toilets, schedule to start in April 2023.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 924651545,
    School_Name: 'Phakgamang Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': 'School built its own toilets.',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 29.61322851,
    GPS_Lat: '-24.98527186',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      "In 2020, the LDOE noted the school's toilets required urgent maintenance.",
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 924651040,
    School_Name: 'Puputle Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.535467,
    GPS_Lat: '-24.93115',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020 the LDOE noted that the school needed 18 new Enviro Loos, 7 Grade R toilets, 18 storm-damaged waterborne toilets reburbished and a water supply system uprade. The project was allocated to the SAFE Initiative. It is not clear if it was completed. The school appears on a project list to receive 18 new enviro loos, 3 Grade R waterborne toilets, a septic tank, and the demolition of 20 plain pit toilets, scheduled to start in April 2023.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 918520924,
    School_Name: 'Ramollo Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "Schools's flush toilets were blown away by a storm. The learners now use pit toilets.",
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 30.40459,
    GPS_Lat: '-23.42346',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 925631220,
    School_Name: 'Rasupi Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.15421,
    GPS_Lat: '-24.49614',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2018 the school received 14 new toilets and 20 were renovated. In 2019 9 new toilets and 5 Grade R toilets were reportedly under construction. In 2020 the LDOE noted that 12 waterborne toilets needed refurbishment and the construction of 14 seats were underway. ',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 906111696,
    School_Name: 'Reabilwe Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 28.61276,
    GPS_Lat: '-23.42865',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020 the LDOE noted that the school needed 12 new Enviro Loos and 14 needed refurbishing and that the project was allocted to the Mvula Trust in 2018/19 ',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 924642589,
    School_Name: 'Rebone Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.82812742,
    GPS_Lat: '-24.79603104',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020 the LDOE noted that the school needed 14 new Enviro Loos, 4 needed refurbishing and 12 pit toilets needed demolishing. The school was on a project list with work scheduled to start in April 2021 on 14 new enviro loos, four Grade R waterborne toilets, a new septic tank and the demolition of 24 pit toilets. The schools was on the SAFE Initiative list labelled as "active".',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 922221425,
    School_Name: 'Sebushi Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "Some of the boys' pit toilets are corroded and none have roofs or doors. None of the girls' toilets has a door.",
    start_date_of_upgrade_project: '1-Apr-21',
    end_date_of_project: '31-Mar-24',
    GPS_Long: 29.198685,
    GPS_Lat: '-23.907875',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 926540423,
    School_Name: 'Sekaba Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.24498684,
    GPS_Lat: '-23.9658659',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE reported that 24 Enviro Loos had been constructed at the school.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 992201202,
    School_Name: 'Sekete Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      'Shool is using old pit toilets while it waits for new toilets to be built.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.13922,
    GPS_Lat: '-23.45228',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the 16 existing Enviro Loos met the norms and standards but the 4 pit toilets were to be demolished by the Mvula Trust. It is not clear if this was completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 918511340,
    School_Name: 'Sekgothi Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      "Toilets are in good condition. But there aren't enough of them so learner's still use old pit latrines.",
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.34558043,
    GPS_Lat: '-23.571975',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2018, the school received 21 new toilets. In 2020, the LDOE noted that the school needed 20 Enviro Loos and the demolition of 21 pit toilets because the school had a sudden increase in enrolment.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 918510934,
    School_Name: 'Sekgware Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'School has recently built Enviro-Loos, but old pit toilets have yet to be demolished.',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 30.33613151,
    GPS_Lat: '-23.58162555',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 926541228,
    School_Name: 'Serare Junior Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.23513025,
    GPS_Lat: '-24.02786903',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No ',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 923241375,
    School_Name: 'Sesoai Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No ',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.80074484,
    GPS_Lat: '-23.80622693',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 921230764,
    School_Name: 'Seula Mmako Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No ',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.392973,
    GPS_Lat: '-24.56113551',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Green',
    classification_two: 1,
    NatEmis: 996603101,
    School_Name: 'Thobela Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No ',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: -25.001128,
    GPS_Lat: '29.724878',
  },
  {
    upgrade_completed: 'No information',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that a project to build 12 Enviro loos and four grade R toilets had been allocated to Mvula Trust in 2018/19. It is unclear if this project has been completed. It is on the SAFE Initiative list labelled as "proceeding".',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 925621179,
    School_Name: 'Thorometsane Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'proceeding' and 'active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Schools built its own pit toilets in 2017.',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 30.69848238,
    GPS_Lat: '-24.52780307',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'No',
    blurb_on_description_of_upgrades:
      'No intervention required, according to the LDOE.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 924642930,
    School_Name: 'Thotaneng Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.75803215,
    GPS_Lat: '-24.86563495',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that a project to build 30 Enviro Loos, 12 Grade R toilets and demolish 25 pit toilets had been allocated to the SAFE Iniative. It is unclear if this was completed. A project is scheduled to start in April 23 to refurbish 28 Enviro Loos and demolish 4 pit toilets.\n',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 928331650,
    School_Name: 'Tshianane Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 30.10429142,
    GPS_Lat: '-22.78562229',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      ' In 2020 the LDOE noted that no further intervention was required because the school was going to be merged with another school. In May 2021, the LDOE confirmed the school is still open. It is also on the SAFE Initiative project list labelled as "active".',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 929311298,
    School_Name: 'Tshitandani Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Toilets are full but learners still use them because they have no other option.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.40915522,
    GPS_Lat: '-22.70750842',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020 the LDOE noted that a project to build 30 Enviro Loos, 12 Grade R toilets and demolish 25 pit toilets had been allocated to the SAFE Initiative. It is unclear if this project was started or completed. It is labelled as "active" on the SAFE list.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 924643056,
    School_Name: 'Tshwatlhakge Primary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'active'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Pit toilets, they are in fairly good condition.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 29.76118328,
    GPS_Lat: '-24.92365044',
  },
  {
    upgrade_completed: 'Yes',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2018 the school received 20 new toilets. In 2019 the school received 17 new toilets and 3 Grade R toilets, according to a Mvula Trust project list. In 2020 the LDOE noted that no further intervention was required.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 918511487,
    School_Name: 'Tsogang Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'Yes',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'New toilets have been built, but all the old pit toilets have not been demolished yet.',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: 30.55351776,
    GPS_Lat: '-23.47690788',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020 the LDOE noted that a project to build 14 Enviro Loos and 3 Grade R toilets had been allocated to the SAFE Initative. The school is also on a project list to receive 14 Enviro Loos, 3 Grade R waterborne toilets, a septic tank, and the demolition of 15 pit toilets starting in April 2023.\n',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Red',
    classification_two: 4,
    NatEmis: 922221616,
    School_Name: 'Utjane Primary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project':
      "Toilets don't have door and a portion of the roof is leaking.",
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 29.16936667,
    GPS_Lat: '-23.93029229',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that the SAFE Initiative had been allocated a project to build 34 Enviro Loos, refurbish 2 waterborne toilets and demolish 36 pit toilets in 2019/20. It is not clear if this project was completed.',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 928330930,
    School_Name: 'Vhulaudzi Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      'Female toilets are in bad condition and the male toilets are filthy.',
    start_date_of_upgrade_project: 'unknown',
    end_date_of_project: 'unknown',
    GPS_Long: 30.18506529,
    GPS_Lat: '-22.96128931',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'Scheduled to start in April 2023, the school is on a project list to receive 20 Enviro Loos and a septic tank; 29 existing Enviro Loos will be refurbished, its borehole upgraded and 80KL water storage tanks will be provided.\n',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 905361620,
    School_Name: 'Ximunwana Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '1-Apr-23',
    end_date_of_project: '31-Mar-26',
    GPS_Long: 30.54621346,
    GPS_Lat: '-23.06876229',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that a project to build 12 Enviro Loos had been allocated to the SAFE Initiative in 2019. Maintenance was needed on the existing 20 Enviro Loos. It is not clear if the project was completed.',
    did_school_have_pit_toilets: 'No',
    S27Classification: 'Yellow',
    classification_two: 2,
    NatEmis: 924650757,
    School_Name: 'Zama Zama Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'No',
    is_school_on_pit_toilet_list: 'No',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '2019',
    end_date_of_project: 'unknown',
    GPS_Long: 29.74084577,
    GPS_Lat: '-25.00939743',
  },
  {
    upgrade_completed: 'No',
    Upgrades: 'Yes',
    blurb_on_description_of_upgrades:
      'In 2020, the LDOE noted that a project to build 12 Enviro Loos had been allocated to the Mvula Trust in 2018/19. It is unclear if that project was completed. It is on the SAFE Initiative list labelled as "proceeding".',
    did_school_have_pit_toilets: 'Yes',
    S27Classification: 'Orange',
    classification_two: 3,
    NatEmis: 924650771,
    School_Name: 'Zenzeleni Secondary School',
    on_SAFE_list: 'Yes',
    SAFE_details:
      "Demolish of all unsafe sanitation, desludge. Construction on new sanitation informed by the N&S, provision of water harvesting and walkways. Project labelled as 'active' and 'proceeding'.",
    On_Asidi_List: 'No',
    On_LDE_pit_list: 'Yes',
    is_school_on_pit_toilet_list: 'Yes',
    'Blurb for map in old project':
      "Male learners' toilets have no roofs or doors and some of the seats are missing. Boys sometimes use old pit toilets with severely cracked walls.",
    start_date_of_upgrade_project: '2018/19',
    end_date_of_project: 'unknown',
    GPS_Long: 29.71837528,
    GPS_Lat: '-25.0627186',
  },
  {
    upgrade_completed: '',
    Upgrades: '',
    blurb_on_description_of_upgrades: '',
    did_school_have_pit_toilets: '',
    S27Classification: '',
    classification_two: null,
    NatEmis: null,
    School_Name: 'Mapotla Secondary School',
    on_SAFE_list: 'No',
    SAFE_details: '',
    On_Asidi_List: '',
    On_LDE_pit_list: '',
    is_school_on_pit_toilet_list: '',
    'Blurb for map in old project': '',
    start_date_of_upgrade_project: '',
    end_date_of_project: '',
    GPS_Long: null,
    GPS_Lat: '',
  },
]
