export let pitToilets = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.510309, -25.044443],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650160,
        school: 'ABRAHAM SEROTE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.51030904,
        old_latitude: -25.044443,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.971801, -22.762415],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360948,
        school: 'ADOLF MHINGA HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.97180118,
        old_latitude: -22.76241487,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.267725, -22.913168],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330190,
        school: 'ADZIMMBAMBI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.26772493,
        old_latitude: -22.91316779,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.153642, -23.611812],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922250010,
        school: 'AGISANANG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.15364195,
        old_latitude: -23.6118122,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.295898, -23.308442],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520016,
        school: 'AKANI HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.29589812,
        old_latitude: -23.30844215,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.985399, -23.585771],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420017,
        school: 'ALBERT MABE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.98539944,
        old_latitude: -23.58577137,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.948948, -23.939559],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120027,
        school: 'ALFRED MASIBE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.94894755,
        old_latitude: -23.93955906,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.9301, -23.533317],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251747,
        school: 'AMBERGATE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9301,
        old_latitude: -23.533317,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.350252, -25.176261],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606538,
        school: 'AQUAVILLE PRIMARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.35025244,
        old_latitude: -25.17626148,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.75971, -24.995253],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640019,
        school: 'AREIKHULENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7597099,
        old_latitude: -24.99525289,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.87621, -23.45801],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250034,
        school: 'AURORA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.87621,
        old_latitude: -23.45801,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.523755, -22.962495],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351203,
        school: 'AZWIFARWI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.52375464,
        old_latitude: -22.96249466,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.325087, -23.975096],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530013,
        school: 'B. MKHABELE JUNIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.32508734,
        old_latitude: -23.97509601,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.553935, -22.743596],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310523,
        school: 'B.R. NEMULODI PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55393472,
        old_latitude: -22.74359619,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.611861, -22.815176],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351289,
        school: 'BABABA SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.61186111,
        old_latitude: -22.81517586,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.569919, -25.023916],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651316,
        school: 'BAFALADI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.56991878,
        old_latitude: -25.02391611,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.308828, -23.694859],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922220040,
        school: 'BAILAFURI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.30882765,
        old_latitude: -23.69485903,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.529248, -24.737102],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640040,
        school: 'BAITHUDI MOHLAHLEDI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.52924764,
        old_latitude: -24.73710246,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.88096, -23.22185],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 908110020,
        school: 'BAITSWE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 27.88096,
        old_latitude: -23.22185,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.764539, -23.864706],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142454,
        school: 'BAKENBERG HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.76453925,
        old_latitude: -23.86470593,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.032547, -22.899285],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330770,
        school: 'BALALILA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.03254727,
        old_latitude: -22.89928537,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.860252, -23.48546],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410034,
        school: 'BALENI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.86025172,
        old_latitude: -23.48546025,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.630629, -24.48508],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620015,
        school: 'BANARENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63062935,
        old_latitude: -24.48507958,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.04858, -23.857109],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 917421101,
        school: 'BARANUKA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 31.04858035,
        old_latitude: -23.85710896,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.506083, -24.549459],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261416,
        school: 'BASELOANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.50608301,
        old_latitude: -24.54945863,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.536064, -22.727408],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929311069,
        school: 'BASHASHA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.53606354,
        old_latitude: -22.72740839,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.05386259, -25.19827071],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606632,
        school: 'BATAU PRIMARY SCHOOL',
        status: 'School is on the SAFE project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.05386259,
        old_latitude: -25.19827071,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.58588, -23.6875],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921140045,
        school: 'BATHOKWA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.58588,
        old_latitude: -23.6875,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.135211, -23.526018],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110075,
        school: 'BATLHALERWA HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.13521142,
        old_latitude: -23.52601768,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.200758, -22.991433],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331032,
        school: 'BELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.20075839,
        old_latitude: -22.99143284,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.341323, -22.988495],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320018,
        school: 'BELEMU JUNIOR PRIMARY',
        status: 'Construction underway',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34132282,
        old_latitude: -22.98849541,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.987192, -24.080217],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120058,
        school: 'BEN HLOGWANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.98719181,
        old_latitude: -24.0802165,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.430489, -22.973484],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350941,
        school: 'BEUSTER SENIOR PRIMARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.43048941,
        old_latitude: -22.97348385,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.975684, -23.130982],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210050,
        school: 'BODIROA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.97568421,
        old_latitude: -23.13098238,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.827159, -23.461902],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340593,
        school: 'BODUMA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82715861,
        old_latitude: -23.46190166,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.871456, -24.261715],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631329,
        school: 'BOGALATLADI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.87145624,
        old_latitude: -24.26171534,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.621765, -24.363618],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260031,
        school: 'BOGALENG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.62176513,
        old_latitude: -24.36361843,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.316299, -24.580767],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620053,
        school: 'BOGWASHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31629895,
        old_latitude: -24.58076654,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.625564, -23.936965],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921142140,
        school: 'BOHLAPA KOLOBE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.62556391,
        old_latitude: -23.93696501,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.74458, -24.700592],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643087,
        school: 'BOHWELABATHO PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7445804,
        old_latitude: -24.70059216,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.097043, -23.062714],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210067,
        school: 'BOIKHUTSO PRIMARY-1',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.09704329,
        old_latitude: -23.06271385,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.531012, -24.239577],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923260048,
        school: 'BOIKHUTSONG SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.53101161,
        old_latitude: -24.23957697,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.94648073, 30.05160296],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606304,
        school: 'BOKONI PRIMARY SCHOOL',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05160296,
        old_latitude: -24.94648073,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.65338, -23.82414],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142294,
        school: 'BOKWIDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.65338,
        old_latitude: -23.82414,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.040678, -23.977769],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901420031,
        school: 'BOLLANOTO PRIMARY',
        status: 'Project reached "Close Out" status in 2019',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 31.04067775,
        old_latitude: -23.97776869,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.345867, -23.622485],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510033,
        school: 'BOLOBEDU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.34586661,
        old_latitude: -23.62248488,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.4719, -23.49279],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510040,
        school: 'BOLOTSWI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.4719,
        old_latitude: -23.49279,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.617516, -24.966148],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651323,
        school: 'BONANG BONANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.61751616,
        old_latitude: -24.96614839,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.906997, -24.772647],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660068,
        school: 'BONEGA MADIKUBUNG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.90699695,
        old_latitude: -24.77264686,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.147716, -24.598275],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630340,
        school: 'BONWANKWE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.14771621,
        old_latitude: -24.59827466,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.886467, -24.203039],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923262047,
        school: 'BOREDI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88646679,
        old_latitude: -24.2030392,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.04117, -23.474166],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250089,
        school: 'BORUME PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.0411698,
        old_latitude: -23.47416562,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.125474, -23.404269],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110105,
        school: 'BORUTLA MOSIMA PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.12547447,
        old_latitude: -23.40426944,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.709063, -23.074971],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210074,
        school: 'BORWALATHOTO LOWER & HIGHER PRI',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.70906325,
        old_latitude: -23.07497067,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.276216, -24.267525],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 907130079,
        school: 'BOSCHDRAAI PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.27621573,
        old_latitude: -24.26752521,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.70768288, -23.4881331],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340456,
        school: 'BOTLOKWA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70768288,
        old_latitude: -23.4881331,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.381963, -23.65105],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510064,
        school: 'BOTLUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.38196281,
        old_latitude: -23.65105009,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.147267, -23.551774],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251594,
        school: 'BOTSHOLLA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.14726693,
        old_latitude: -23.55177385,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.873167, -23.169833],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210098,
        school: 'BOTSHWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.873167,
        old_latitude: -23.169833,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.94732, -22.837113],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360368,
        school: 'BOXAHUKU PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.9473198,
        old_latitude: -22.83711289,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.148904, -23.373237],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922210104,
        school: 'BRUSSELS NGOAKO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.14890383,
        old_latitude: -23.373237,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.56796, -25.188681],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606534,
        school: 'BUFFELSVALLEI PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.56796033,
        old_latitude: -25.18868113,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.54519, -24.32901],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 926540508,
        school: 'BUTSHWANA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.54518959,
        old_latitude: -24.32900981,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.809212, -23.555323],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410058,
        school: 'BVUMA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.80921206,
        old_latitude: -23.55532303,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.366799, -23.247735],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520382,
        school: 'CALEDON PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36679863,
        old_latitude: -23.24773548,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.350421, -23.958827],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530051,
        school: 'CATA SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.35042108,
        old_latitude: -23.95882707,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.653764, -23.7959],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141956,
        school: 'CHARLES MARAKALALA SECONDARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.65376385,
        old_latitude: -23.79590005,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.256799, -23.901018],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530068,
        school: 'CHARLES MATHONSI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25679876,
        old_latitude: -23.90101824,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.1381, -23.19839],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520399,
        school: 'CHAVANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.1381,
        old_latitude: -23.19839,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.07324, -23.523151],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250133,
        school: 'CHIKA SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.07324019,
        old_latitude: -23.52315069,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.323531, -24.36376],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230023,
        school: 'CHITA KEKANA SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.32353093,
        old_latitude: -24.36376005,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.953571, -24.921014],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 996606817,
        school: 'CON ROUX PRIMARY SCHOOL',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95357091,
        old_latitude: -24.92101389,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.512493, -22.840462],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350316,
        school: 'DAMANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.51249281,
        old_latitude: -22.84046222,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.68912, -22.509623],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929310042,
        school: 'DAMBALE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.68911965,
        old_latitude: -22.50962285,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.89451842, 30.46403753],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350736,
        school: 'DAMBAVHUSHA SENIOR PRIMARY',
        status: 'School not on 2021 master list, may have closed.',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.46403753,
        old_latitude: -22.89451842,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.446069, -23.205015],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321202,
        school: 'DAVHANA SECONDARY',
        status: 'Projet completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.44606862,
        old_latitude: -23.20501452,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.984925, -23.371664],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340982,
        school: 'DAVID LUVHIMBA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.98492509,
        old_latitude: -23.37166422,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.60033, -22.75603],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311564,
        school: 'DAVID MUTSHINYALO SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.60033,
        old_latitude: -22.75603,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.867616, -23.466621],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340555,
        school: 'DEELKRAAL PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.86761583,
        old_latitude: -23.46662094,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.971611, -23.278962],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340999,
        school: 'DENGA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.97161121,
        old_latitude: -23.27896168,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.513021, -22.911523],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351357,
        school: 'DENGENYA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.51302094,
        old_latitude: -22.91152313,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.767513, -23.852589],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921140090,
        school: 'DENNIS MATLHABA II SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.76751309,
        old_latitude: -23.85258931,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.69139, -22.82872],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930360504,
        school: 'DENZHE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.69139047,
        old_latitude: -22.82872012,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.05879, -25.250225],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606601,
        school: 'DIBATHUTO SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.05878992,
        old_latitude: -25.25022507,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.511021, -23.01399],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 930350491,
        school: 'DIDIDI',
        status:
          'Project completed in 2018, but also on project list to start from 2021',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.51102065,
        old_latitude: -23.01398998,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.037126, -24.709409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660075,
        school: 'DIKANKATLA PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.03712565,
        old_latitude: -24.70940934,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.064211, -24.473368],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630364,
        school: 'DIKETEPE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.06421082,
        old_latitude: -24.47336839,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.928659, -24.871851],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660099,
        school: 'DIKGAGENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.92865901,
        old_latitude: -24.8718508,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82658, -24.22694],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260116,
        school: 'DIKGEU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82657998,
        old_latitude: -24.2269397,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.97659, -23.252412],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210111,
        school: 'DIKGOALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.97659011,
        old_latitude: -23.25241179,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.79342, -23.753647],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240099,
        school: 'DIKGOPHENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.79342018,
        old_latitude: -23.75364683,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.63945, -24.309411],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 923260123,
        school: 'DIKOBE MOLABA SECONDARY',
        status:
          'Project completed in 2018, but also on project list to start from 2021',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.63945042,
        old_latitude: -24.30941059,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.00094, -23.208673],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210128,
        school: 'DIKOLOI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.00094024,
        old_latitude: -23.20867335,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.364654, -24.543029],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620060,
        school: 'DIKOTOPE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36465395,
        old_latitude: -24.54302889,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.83643, -24.86293],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640101,
        school: 'DIKWETSE PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.83643,
        old_latitude: -24.86293,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11529, -22.892928],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330893,
        school: 'DIMBANYIKA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.11529047,
        old_latitude: -22.89292764,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.521094, -24.93761],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924651941,
        school: 'DIMO SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.52109362,
        old_latitude: -24.93760951,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.690915, -24.497845],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620077,
        school: 'DINKWANYANE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.69091532,
        old_latitude: -24.49784537,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.723846, -23.89949],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920235734,
        school: 'DIOPONG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.72384604,
        old_latitude: -23.89949024,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.568373, -24.414701],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 926540034,
        school: 'DIPONE SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.56837266,
        old_latitude: -24.4147013,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.158964, -25.145426],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606602,
        school: 'DITHAMAGA SECONDARY SCHOOL',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.15896419,
        old_latitude: -25.14542558,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.115273, -23.143654],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904210159,
        school: 'DITHEBELE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.11527252,
        old_latitude: -23.14365423,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.661819, -23.481608],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140120,
        school: 'DITLOU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.66181898,
        old_latitude: -23.48160768,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.02673, -24.5035],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630012,
        school: 'DJATE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.02673,
        old_latitude: -24.5035,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.744116, -22.906765],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361385,
        school: 'DLAMANI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.74411568,
        old_latitude: -22.90676548,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.053725, -24.739964],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660105,
        school: 'DLAMINI PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05372505,
        old_latitude: -24.73996392,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.745628, -22.488637],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310189,
        school: 'DOMBONI PRIMARY',
        status: 'School is on the SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.74562803,
        old_latitude: -22.48863697,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.273134, -23.401164],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520412,
        school: 'DUMANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27313353,
        old_latitude: -23.40116411,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.993351, -24.091264],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120140,
        school: 'DUMAZI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.99335114,
        old_latitude: -24.09126419,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.78963, -22.756204],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360511,
        school: 'DUVHULEDZA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.78963043,
        old_latitude: -22.7562042,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.6363, -22.573214],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929310417,
        school: 'DYELAMANAVHA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63630003,
        old_latitude: -22.57321376,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.32934, -22.81022],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929311052,
        school: 'DZAMBA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.32934,
        old_latitude: -22.81022,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.429845, -23.02401],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320155,
        school: 'DZINDI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42984503,
        old_latitude: -23.02401025,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.52111, -22.91131],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351005,
        school: 'DZINGAHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.52111,
        old_latitude: -22.91131,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.52388, -22.96877],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350507,
        school: 'DZIVHANI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.52388,
        old_latitude: -22.96877,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.38222, -23.05355],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320483,
        school: 'DZONDO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38222,
        old_latitude: -23.05355,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.251001, -22.916167],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930332363,
        school: 'DZUMBULUWANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.25100085,
        old_latitude: -22.91616733,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.54003, -22.93046],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930351371,
        school: 'DZWABONI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.54003,
        old_latitude: -22.93046,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.404097, -23.117748],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321219,
        school: 'EDISON NESENGANI SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.40409656,
        old_latitude: -23.11774768,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.650842, -24.560602],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640156,
        school: 'EKELE PRIMARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.65084157,
        old_latitude: -24.56060154,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.745029, -25.170226],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606533,
        school: 'EMBHOKODWENI PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.74502942,
        old_latitude: -25.17022626,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.371441, -23.8212],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922220170,
        school: 'ERNEST MATLOU PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.37144094,
        old_latitude: -23.82120019,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.32582158, 30.49197133],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 902331926,
        school: 'ESMEFOUR PRIMARY FARM',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.49197133,
        old_latitude: -22.32582158,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.640386, -23.298518],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410119,
        school: 'FAMANDHA HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.64038566,
        old_latitude: -23.29851771,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.6716647, 30.75724321],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310615,
        school: 'FANDANI PRIMARY',
        status: 'School may have merged with another school',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.75724321,
        old_latitude: -22.6716647,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.554248, -24.163987],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260185,
        school: 'FEKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.55424796,
        old_latitude: -24.16398676,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.407523, -23.499163],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510085,
        school: 'FEMANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.40752276,
        old_latitude: -23.49916264,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.636338, -22.871395],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 905350095,
        school: 'FHATUWANI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.63633836,
        old_latitude: -22.87139455,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.884875, -22.475665],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929310400,
        school: 'FHETANI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.88487502,
        old_latitude: -22.47566537,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.33133, -23.70374],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510811,
        school: 'FOBENI HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 30.33133,
        old_latitude: -23.70374,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.426585, -22.587362],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310288,
        school: 'FOLOVHODWE JUNIOR AND SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.4265845,
        old_latitude: -22.58736225,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.258198, -22.921636],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330244,
        school: 'FONDWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25819786,
        old_latitude: -22.92163619,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.234516, -23.17517],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916321431,
        school: 'FRANK MUKHASWAKULE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.23451631,
        old_latitude: -23.17516991,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.437338, -22.908427],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930351463,
        school: 'FRANS RASIMPHI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.43733765,
        old_latitude: -22.90842725,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88489, -24.717228],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661712,
        school: 'FREDDY MOKGABUDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88489,
        old_latitude: -24.71722764,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.915051, -23.141945],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360016,
        school: 'FUMANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.915051,
        old_latitude: -23.14194491,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.673668, -22.839708],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360535,
        school: 'FUNZWANI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.67366827,
        old_latitude: -22.83970849,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.727587, -22.797378],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360528,
        school: 'GABA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.72758719,
        old_latitude: -22.79737818,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.54357, -22.865671],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350309,
        school: 'GALANANZHELE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.54357043,
        old_latitude: -22.86567066,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.995069, -24.407585],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630029,
        school: 'GANGADZA SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.99506889,
        old_latitude: -24.40758459,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.293606, -23.033742],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320513,
        school: 'GANYANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.29360627,
        old_latitude: -23.03374151,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.653034, -24.819841],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640200,
        school: 'GAREAGOPOLA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.6530337,
        old_latitude: -24.81984062,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.294548, -24.352597],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230054,
        school: 'GAUTA JONATHAN PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.29454846,
        old_latitude: -24.35259722,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.333065, -23.988988],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530495,
        school: 'GAVAZA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.33306546,
        old_latitude: -23.98898753,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.598225, -22.805576],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350293,
        school: 'GEOGENHOLTZ PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5982247,
        old_latitude: -22.80557629,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.645275, -23.001409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361491,
        school: 'GEORGE HASANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.64527462,
        old_latitude: -23.00140876,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.52127, -23.594915],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141932,
        school: 'GEORGE MASIBE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 28.52126977,
        old_latitude: -23.59491541,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.950983, -22.901403],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330879,
        school: 'GEORGE MBULAHENI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.95098305,
        old_latitude: -22.90140307,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.21821, -23.246799],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918521095,
        school: 'GEORGE MSWAZI',
        status: 'School name has changed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.21821037,
        old_latitude: -23.24679856,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.637245, -23.050458],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360870,
        school: 'GEORGE SONTO HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.63724504,
        old_latitude: -23.05045842,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.237899, -23.316546],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210173,
        school: 'GEORGE TLADI TECHNICAL HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.23789868,
        old_latitude: -23.31654648,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.34014, -23.406764],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521543,
        school: 'GIDELA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.34013975,
        old_latitude: -23.4067636,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.88502, -23.015524],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361262,
        school: 'GIDJANA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.88501993,
        old_latitude: -23.01552402,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.735482, -23.35697],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410140,
        school: 'GIJA NGOVE SENIOR PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.73548159,
        old_latitude: -23.3569703,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.286054, -23.20862],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520429,
        school: 'GIJA PRIMARY',
        status: 'Project reached "Close Out" status in 2019',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28605373,
        old_latitude: -23.20861988,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.294505, -22.924679],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330053,
        school: 'GILBERT MULONDO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.29450519,
        old_latitude: -22.92467924,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.567021, -22.838272],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350286,
        school: 'GILBERT NNDANGANENI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.56702107,
        old_latitude: -22.83827168,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.945393, -23.3494],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 914610157,
        school: 'GIVA-MAHLATHI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.94539252,
        old_latitude: -23.3493996,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.955441, -23.932477],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 904242323,
        school: 'GLENSHIEL COMBINED',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95544108,
        old_latitude: -23.93247729,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.877465, -23.915226],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120249,
        school: 'GODWIN MASIBE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.87746517,
        old_latitude: -23.91522554,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.33434, -22.777443],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311083,
        school: 'GOGOGO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3343403,
        old_latitude: -22.77744317,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.68359229, 30.31773133],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929331841,
        school: 'GOMBANI PRIMARY',
        status: 'School may have merged with another school',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31773133,
        old_latitude: -22.68359229,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.71377, -22.812511],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361019,
        school: 'GONELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.71376987,
        old_latitude: -22.81251098,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.41017, -24.95522],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650047,
        school: 'GOSHETSENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.41017,
        old_latitude: -24.95522,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.705887, -23.617426],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420109,
        school: 'GOVANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.70588749,
        old_latitude: -23.61742636,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.862759, -23.024013],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360153,
        school: 'GOVHU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.86275869,
        old_latitude: -23.02401293,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.173243, -24.6094],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630999,
        school: 'GOWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.17324304,
        old_latitude: -24.60940005,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.33048, -23.29331],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520436,
        school: 'GOZA PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.33048,
        old_latitude: -23.29331,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.415273, -22.956075],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351388,
        school: 'GUVHUKUVHU SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.41527333,
        old_latitude: -22.95607548,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.477291, -22.726829],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311006,
        school: 'GUYUNI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.47729143,
        old_latitude: -22.72682924,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.359973, -23.051459],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320445,
        school: 'HAMUTSHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.35997296,
        old_latitude: -23.05145901,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.848475, -22.891506],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911321806,
        school: 'HANGALAKANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.84847518,
        old_latitude: -22.89150619,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82636, -24.443662],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610100,
        school: 'HANS KOMANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.82635988,
        old_latitude: -24.44366154,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.690121, -22.530411],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310370,
        school: 'HANYANI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.69012118,
        old_latitude: -22.53041099,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.277996, -23.210903],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912510061,
        school: 'HANYANI NKUZANA HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27799578,
        old_latitude: -23.210903,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.712951, -23.147857],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921410195,
        school: 'HANYANYANI HIGHER PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.71295099,
        old_latitude: -23.1478569,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.530103, -23.35507],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 902971823,
        school: 'HAWUKA SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.53010315,
        old_latitude: -23.35507045,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.82019615, 30.04945459],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330916,
        school: 'HENDRIC NTHAMBELENI SECONDARY',
        status: 'School not on 2021 master list, may have closed.',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.04945459,
        old_latitude: -22.82019615,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.807929, -22.908314],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931312052,
        school: 'HITEKANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.80792897,
        old_latitude: -22.90831367,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.28336, -23.62603],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220200,
        school: 'HLABANA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.28336,
        old_latitude: -23.62602995,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.531307, -23.352367],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410256,
        school: 'HLAKHETO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.53130746,
        old_latitude: -23.35236695,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.49209, -25.01944],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651569,
        school: 'HLAKUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.49209,
        old_latitude: -25.01944,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.672121, -22.971942],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361644,
        school: 'HLALUKO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.67212059,
        old_latitude: -22.9719425,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.505818, -23.289173],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410263,
        school: 'HLANEKI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.50581804,
        old_latitude: -23.28917334,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.30582406, 29.02042571],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210203,
        school: 'HLOHLODI PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.02042571,
        old_latitude: -23.30582406,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.049158, -24.541304],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630371,
        school: 'HLONG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.04915777,
        old_latitude: -24.54130392,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.231518, -23.205519],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 912520078,
        school: 'HLUVUKA HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.23151847,
        old_latitude: -23.20551898,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.883954, -23.018733],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905360160,
        school: 'HOJI SENIOR PRIMARY',
        status: 'School is on the SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.88395436,
        old_latitude: -23.01873284,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.27088, 27.48751],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909132037,
        school: 'Hoopdal Primary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.48751,
        old_latitude: -24.27088,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.588393, -24.709913],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640279,
        school: 'HOPEFIELD PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.58839299,
        old_latitude: -24.70991298,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.094766, -25.152533],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606612,
        school: 'HOSIA APHANE PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.09476604,
        old_latitude: -25.15253279,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.169341, -22.940641],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330954,
        school: 'HUMBELANI SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.16934142,
        old_latitude: -22.94064137,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.455781, -24.875216],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651910,
        school: 'HUTUTU SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.45578076,
        old_latitude: -24.8752156,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.66848, 28.90274],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251686,
        school: 'HWIBI PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.90274,
        old_latitude: -23.66848,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.835432, -23.466234],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340579,
        school: 'IKAGELENG LOWER AND HIGHER PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83543171,
        old_latitude: -23.46623351,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.462776, -25.093535],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650061,
        school: 'IKAGENG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4627759,
        old_latitude: -25.09353464,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.30648, -23.46688],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 908111528,
        school: 'IKITSENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.30648,
        old_latitude: -23.46688,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.385077, -24.686454],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906120348,
        school: 'IMMERPAN PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.38507695,
        old_latitude: -24.68645403,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82279, -24.37678],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906610650,
        school: 'INDIE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82279,
        old_latitude: -24.37678,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.836755, -23.519403],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340784,
        school: 'ITIELENE PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83675497,
        old_latitude: -23.51940252,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.910227, -23.478381],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905340562,
        school: 'ITSHOMELENG PUBLIC',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.9102266,
        old_latitude: -23.47838124,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.274533, -22.926346],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330398,
        school: 'JACK LAVHENGWA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.2745328,
        old_latitude: -22.92634564,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.396733, -23.947306],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530532,
        school: 'JACK MASHAMBA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39673269,
        old_latitude: -23.94730574,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.48385, -25.06944],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924650078,
        school: 'JACOB SEFAKO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.48385,
        old_latitude: -25.06944,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.72168, -25.06027],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 924651590,
        school: 'JAFTA-BANTABETHU',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.72168,
        old_latitude: -25.06027,
        quintile: '1',
        no_fee: 'Yes',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.94349, -24.15198],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120379,
        school: 'JAKAMENE LOWER AND HIGHER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.94349,
        old_latitude: -24.15198,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.348967, -23.446253],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918520450,
        school: 'JAMELA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.34896704,
        old_latitude: -23.4462533,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.85161, -24.7533],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606201,
        school: 'JANE FURSE COMPREHENSIVE SCHOOL',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.85161,
        old_latitude: -24.7533,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.076042, -23.82215],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220262,
        school: 'JAWEJAWE LEDWABA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.07604224,
        old_latitude: -23.82214982,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.55482, -22.80988],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350279,
        school: 'JIM MASINDI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55482,
        old_latitude: -22.80988,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.444257, -23.00463],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350484,
        school: 'JIM TSHIVHONELO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44425734,
        old_latitude: -23.00462964,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.91679, -22.87125],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360399,
        school: 'JOAS PHAHLELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.91679,
        old_latitude: -22.87125,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.372603, -23.234009],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520467,
        school: 'JOE MABEDLE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.37260287,
        old_latitude: -23.23400858,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.067826, -23.868675],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220286,
        school: 'JOEL SIBASA SENIOR SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.06782553,
        old_latitude: -23.86867502,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.8887, -24.035629],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120393,
        school: 'JOHANNES MASIBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.88869981,
        old_latitude: -24.03562924,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.37921, -22.9515],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351029,
        school: 'JOHN MARUBINI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37921,
        old_latitude: -22.9515,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.002192, -23.14146],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210227,
        school: 'JOHN MOLOKOMME PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.00219205,
        old_latitude: -23.14146035,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.797366, -23.124649],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331407,
        school: 'JONATHAN THIFULUFHELWI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.79736593,
        old_latitude: -23.124649,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.1377, -23.61822],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250218,
        school: 'KABELA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.1377,
        old_latitude: -23.61822,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.324865, -23.659053],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220323,
        school: 'KAPUTLA NKOANA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.3248655,
        old_latitude: -23.65905307,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.177445, -23.566947],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250225,
        school: 'KARABI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.1774446,
        old_latitude: -23.56694654,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.83707, -22.64145],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310493,
        school: 'KAREL NGIGIDENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.83707,
        old_latitude: -22.64145,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.554592, -24.727286],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640316,
        school: 'KATANG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.554592,
        old_latitude: -24.72728556,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.736729, -23.639945],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916420123,
        school: 'KATEKANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.73672883,
        old_latitude: -23.63994457,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.61011, -24.82373],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640330,
        school: 'KATISI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.61011,
        old_latitude: -24.82373,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.7492, -25.03331],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650405,
        school: 'KAWUSIME PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7492,
        old_latitude: -25.03331,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.539016, -24.769296],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640378,
        school: 'KEDIKETSE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.53901559,
        old_latitude: -24.76929606,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.759078, -23.317484],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920212384,
        school: 'KETUDI HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.75907832,
        old_latitude: -23.31748372,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.988146, -24.119778],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921120430,
        school: 'KGABA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9881464,
        old_latitude: -24.11977845,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.946591, -24.138947],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120454,
        school: 'KGABETLI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.94659062,
        old_latitude: -24.13894741,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.12185, -23.64191],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 992202401,
        school: 'KGABO PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.12185,
        old_latitude: -23.64191,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.102092, -24.135813],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260246,
        school: 'KGADIMO SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.10209174,
        old_latitude: -24.13581266,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.65339, -24.31494],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 923260253,
        school: 'KGAGANOKO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65339,
        old_latitude: -24.31494,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.053185, -24.340298],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631350,
        school: 'KGAGUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05318512,
        old_latitude: -24.34029752,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.272908, -23.971227],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540577,
        school: 'KGAHARA JUNIOR PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27290849,
        old_latitude: -23.97122705,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.46018911, 30.27052316],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620169,
        school: 'KGAKANTSHANE PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27052316,
        old_latitude: -24.46018911,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.59527, -23.8938],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140229,
        school: 'KGAKGALA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.59527,
        old_latitude: -23.8938,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.83228, -23.57393],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142126,
        school: 'KGAKGATHU SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.83228,
        old_latitude: -23.57393,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.397952, -23.716574],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220934,
        school: 'KGAKOA (MOGAKOA) SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.3979516,
        old_latitude: -23.71657442,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.762491, -23.757585],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240174,
        school: 'KGALAKA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.76249145,
        old_latitude: -23.75758462,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.77574, -22.903796],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210258,
        school: 'KGALUSHI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.77574036,
        old_latitude: -22.90379629,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.58898, -24.58742],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620176,
        school: 'KGAOLA SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.58898,
        old_latitude: -24.58742,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.707296, -23.483768],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340678,
        school: 'KGARAHARA HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70729616,
        old_latitude: -23.48376784,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.711258, -24.761088],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640408,
        school: 'KGARUTHUTHU PRIMARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.71125829,
        old_latitude: -24.76108822,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.98194, -24.130372],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120461,
        school: 'KGATABELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.98193985,
        old_latitude: -24.13037184,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.82029476, 29.83488454],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240181,
        school: 'KGATLA HIGHER PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.83488454,
        old_latitude: -23.82029476,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.5897, -23.94578],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921142447,
        school: 'KGAUBOTLHALE SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.5897,
        old_latitude: -23.94578,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.633878, 30.020612],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660174,
        school: 'KGETEDI PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.020612,
        old_latitude: -24.633878,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.379564, -24.51108],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620183,
        school: 'KGOBALANE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37956404,
        old_latitude: -24.51108012,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.9979, -24.7323],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660181,
        school: 'KGOBISE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.9979,
        old_latitude: -24.7323,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.12301, -24.7566],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660198,
        school: 'KGOBOKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12301,
        old_latitude: -24.7566,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.560527, -23.467458],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111719,
        school: 'KGOBUKI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.56052725,
        old_latitude: -23.46745846,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.810848, -24.417124],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610667,
        school: 'KGOEDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.81084827,
        old_latitude: -24.41712425,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.77803, -23.62415],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140274,
        school: 'KGOKARI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.77803,
        old_latitude: -23.62415,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.977678, -23.802846],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250249,
        school: 'KGOKE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.97767775,
        old_latitude: -23.80284551,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.012598, -23.609556],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901340449,
        school: 'KGOLAKALELEME HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.01259847,
        old_latitude: -23.60955554,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.64587, -23.46803],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142409,
        school: 'KGOLODI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.64587,
        old_latitude: -23.46803,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.857407, -24.729421],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660211,
        school: 'KGOLOKO SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.85740744,
        old_latitude: -24.72942097,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.99376, -23.69476],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250256,
        school: 'KGOMONTSWERE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.99376,
        old_latitude: -23.69476,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.68293, -23.28325],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140304,
        school: 'KGOMOSHIAKWENA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.68293,
        old_latitude: -23.28325,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.071873, -25.063255],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606809,
        school: 'KGOMOTLOU PRIMARY SCHOOL',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.07187297,
        old_latitude: -25.06325493,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.6489, -24.57924],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640422,
        school: 'KGOOGO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.6489,
        old_latitude: -24.57924,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.813476, -23.784028],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142737,
        school: 'KGOPEDI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.81347553,
        old_latitude: -23.78402785,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.679099, -24.826031],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640439,
        school: 'KGOPOLO E BOTSE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.67909884,
        old_latitude: -24.82603108,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.195553, -25.281737],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606715,
        school: 'KGOTHALA SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.195553,
        old_latitude: -25.28173668,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.559906, -24.450263],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260307,
        school: 'KGWADIAMOLEKE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.5599063,
        old_latitude: -24.45026309,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.768781, -23.223255],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210357,
        school: 'KGWALE PRIMARY',
        status: 'School is on SAFE project list, but not active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.76878115,
        old_latitude: -23.22325524,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.852967, -23.267471],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410348,
        school: 'KHAKHALA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.8529674,
        old_latitude: -23.26747068,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.26999, -22.83586],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929330435,
        school: 'KHAKHU SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 30.26999,
        old_latitude: -22.83586,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.27973, -22.92376],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330091,
        school: 'KHALAVHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27973,
        old_latitude: -22.92376,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.27535, -23.33858],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520474,
        school: 'KHAMANYANI LOWER PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.27535,
        old_latitude: -23.33858,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.67413, -22.66997],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310738,
        school: 'KHANGALE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.67413,
        old_latitude: -22.66997,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.218155, -22.719218],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332622,
        school: 'KHARIVHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.21815521,
        old_latitude: -22.7192176,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.81561, -22.66036],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310646,
        school: 'KHAVHAMBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.81561,
        old_latitude: -22.66036,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.78322943, 30.80420398],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361033,
        school: 'KHAVUWE PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.80420398,
        old_latitude: -22.78322943,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.321543, -23.62089],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510132,
        school: 'Khekhutini Primary',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.321522,
        old_latitude: -23.62094,
        quintile: '1',
        no_fee: 'Yes',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398214, -23.428195],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918520108,
        school: 'KHEODI HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39821439,
        old_latitude: -23.42819516,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.360761, -23.639018],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510149,
        school: 'KHEOPENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36076112,
        old_latitude: -23.63901799,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.53946, -23.5974],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510156,
        school: 'KHESETHWANE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.53946,
        old_latitude: -23.5974,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.869403, -23.580832],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410386,
        school: 'KHEYI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.86940275,
        old_latitude: -23.58083182,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.839526, -23.019014],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360207,
        school: 'KHODOBI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.83952623,
        old_latitude: -23.01901396,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.626346, -23.060602],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 928332165,
        school: 'KHOGONYANE JUNIOR PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.62634596,
        old_latitude: -23.06060205,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.26659, -22.70622],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331858,
        school: 'KHOMELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.26659,
        old_latitude: -22.70622,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.287192, -23.972004],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915531344,
        school: 'KHOPO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28719185,
        old_latitude: -23.9720038,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.63626, -24.56884],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640460,
        school: 'KHUDU TSEKE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.63626,
        old_latitude: -24.56884,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.125426, -22.953056],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331247,
        school: 'KHUNDA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12542577,
        old_latitude: -22.95305559,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.761523, -23.050409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361514,
        school: 'KHUPUKANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.76152349,
        old_latitude: -23.05040903,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.39242, -24.56117],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230108,
        school: 'KHURENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.39242,
        old_latitude: -24.56117,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.266162, -23.159612],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321608,
        school: 'KHWARA SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.26616206,
        old_latitude: -23.15961234,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.871553, -23.485033],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410423,
        school: 'KHWEZU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.87155282,
        old_latitude: -23.48503307,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.12283, -23.474495],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250287,
        school: 'KHWINANA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.12282996,
        old_latitude: -23.47449543,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.525431, -24.278473],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540607,
        school: 'KOBJANAME PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.52543099,
        old_latitude: -24.27847312,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.310289, -24.572514],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621230,
        school: 'KOBOTI PRIMARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31028871,
        old_latitude: -24.57251387,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.969299, -23.750513],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250294,
        school: 'KOKAMI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.96929904,
        old_latitude: -23.75051254,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.754317, -23.829433],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923242361,
        school: 'KOKONA DIKGALE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.754317,
        old_latitude: -23.829433,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.06822, -22.88064],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330640,
        school: 'KOKWANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.06822,
        old_latitude: -22.88064,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.65168, -23.82139],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142430,
        school: 'KOLOBE RAMASOBANA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.65168,
        old_latitude: -23.82139,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.782341, -23.747637],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240235,
        school: 'KONOTO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.78234068,
        old_latitude: -23.74763667,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.21252, -23.41024],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340302,
        school: 'KOPANANG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.21252,
        old_latitude: -23.41024,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.716854, -24.944819],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640507,
        school: 'KOPJENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.71685428,
        old_latitude: -24.94481862,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.838311, -23.824052],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240242,
        school: 'KOTANKWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.83831142,
        old_latitude: -23.82405155,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.479188, -24.830471],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650887,
        school: 'KOTOLE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.47918816,
        old_latitude: -24.83047114,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.37267, -24.705011],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 991102501,
        school: 'KUBELA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.37266959,
        old_latitude: -24.70501113,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.700714, -23.367913],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921250300,
        school: 'KUBUSHE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.70071412,
        old_latitude: -23.36791341,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.32338, -23.29568],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520504,
        school: 'KULANI PRIMARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.32338,
        old_latitude: -23.29568,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.358829, -23.147217],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905321462,
        school: 'KURULEN PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.35882889,
        old_latitude: -23.14721695,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.497141, -23.350593],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410430,
        school: 'KUTSAKENI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49714125,
        old_latitude: -23.35059278,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.97097, -24.24446],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631374,
        school: 'KWANO PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.97097,
        old_latitude: -24.24446,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.800226, -24.952303],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640514,
        school: 'KWENATSHWENE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.80022563,
        old_latitude: -24.95230283,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.65838, -23.979993],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240259,
        school: 'LAASTE HOOP HIGHER PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65838044,
        old_latitude: -23.97999306,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.559821, -22.717127],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310622,
        school: 'LADZANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55982069,
        old_latitude: -22.71712652,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.833282, -22.721626],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361040,
        school: 'LAMBANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.83328171,
        old_latitude: -22.72162606,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.64019, -23.67386],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906141727,
        school: 'LAMOLA SENIOR SECONDARY',
        status: 'School is on SAFE project list, but not active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.64019,
        old_latitude: -23.67386,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.77353, -22.656102],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310578,
        school: 'LAMVI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.77352967,
        old_latitude: -22.65610212,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.93695, -23.990424],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120546,
        school: 'LANGALIBALELE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.93695045,
        old_latitude: -23.99042372,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.74003, -22.6714],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310516,
        school: 'LAVHURALA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.74003,
        old_latitude: -22.6714,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.297238, -24.361884],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620220,
        school: 'LEAGATHOKO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.29723809,
        old_latitude: -24.36188449,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.997548, -23.946256],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 913420192,
        school: 'LEBEKO HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.99754834,
        old_latitude: -23.94625613,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.23811329, 29.93614421],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631381,
        school: 'LEBELO PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.93614421,
        old_latitude: -24.23811329,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.839092, -22.840421],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210401,
        school: 'LEBOHO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.8390922,
        old_latitude: -22.84042129,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.069065, -24.312483],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631398,
        school: 'LEDINGWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0690647,
        old_latitude: -24.31248305,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.35442, -23.73441],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220460,
        school: 'LEETETJA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.35442,
        old_latitude: -23.73441,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.93389, -24.35038],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630043,
        school: 'LEFAKGOMO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.93389,
        old_latitude: -24.35038,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.305605, -23.948549],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530129,
        school: 'LEFARA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.30560532,
        old_latitude: -23.94854866,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.412245, -24.213129],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540614,
        school: 'LEFOKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.4122454,
        old_latitude: -24.21312912,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.655107, -24.546927],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620244,
        school: 'LEGABENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65510663,
        old_latitude: -24.54692657,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.46654, -24.83593],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650788,
        school: 'LEGADIMANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.46654,
        old_latitude: -24.83593,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88413, -24.47334],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610759,
        school: 'LEGANABATHO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88413,
        old_latitude: -24.47334,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.1035, -24.72597],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660242,
        school: 'LEGAPANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1035,
        old_latitude: -24.72597,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.807286, -24.227153],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260383,
        school: 'LEGOBOLE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.807286,
        old_latitude: -24.22715253,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.54573, -24.6162],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620251,
        school: 'LEGOLENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.54573,
        old_latitude: -24.6162,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.243367, -23.293267],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210418,
        school: 'LEHAIWA SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.243367,
        old_latitude: -23.293267,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.068143, -23.90537],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 901530587,
        school: 'LEHLABA FARM',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.0681427,
        old_latitude: -23.90536958,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.211573, -24.622065],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620268,
        school: 'LEHLABA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.21157344,
        old_latitude: -24.62206464,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.617702, -24.356421],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260308,
        school: 'LEHLAGA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.61770151,
        old_latitude: -24.35642129,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.72768, -24.869299],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640545,
        school: 'LEHLAKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.72767956,
        old_latitude: -24.86929879,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.886742, -24.952121],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640552,
        school: 'LEHLAKONG PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.88674159,
        old_latitude: -24.95212133,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.913478, -24.769296],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925660266,
        school: 'LEHUTJWANA SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.91347848,
        old_latitude: -24.76929626,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.446586, -24.6297],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230122,
        school: 'LEHWELERE MATLALA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.44658623,
        old_latitude: -24.62970009,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.115494, -25.155128],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606606,
        school: 'LEHWELERE PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.11549428,
        old_latitude: -25.15512848,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.653103, -25.057469],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651231,
        school: 'LEKALA SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65310258,
        old_latitude: -25.05746927,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.429331, -24.261758],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540638,
        school: 'LEKANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.429331,
        old_latitude: -24.2617581,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.966243, -24.605061],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610025,
        school: 'LEKENTLE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96624312,
        old_latitude: -24.60506076,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.250664, -23.974698],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540621,
        school: 'LEKGOLO MAAKE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25066421,
        old_latitude: -23.97469778,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.96555, -23.370207],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340197,
        school: 'LEKGOLO PRIMARY-1',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.96555,
        old_latitude: -23.37020676,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.546768, -24.714149],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640583,
        school: 'LEKHINE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.54676801,
        old_latitude: -24.71414853,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.92299, -23.576623],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250348,
        school: 'LEKHURENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.92299031,
        old_latitude: -23.57662335,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.43576463, 28.92021258],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250355,
        school: 'LEKITING PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.92021258,
        old_latitude: -23.43576463,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.66083, -24.745072],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640590,
        school: 'LEKOKO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66082961,
        old_latitude: -24.74507151,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.552889, -24.93839],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651897,
        school: 'LEKOMETSE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.55288908,
        old_latitude: -24.93838995,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398656, -24.45353],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925620282,
        school: 'LEKUBOSHAI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3986564,
        old_latitude: -24.45353019,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.308634, -23.613208],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510217,
        school: 'LEKUKELA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.30863428,
        old_latitude: -23.61320757,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.887497, -23.50256],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410461,
        school: 'LELENI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.88749735,
        old_latitude: -23.50255975,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.571463, -23.59784],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142393,
        school: 'LEMETJA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.57146321,
        old_latitude: -23.59784019,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.860932, -24.756194],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925661450,
        school: 'LENGAMA SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.86093242,
        old_latitude: -24.75619381,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.022197, -23.522034],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251624,
        school: 'LEOKANENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.0221972,
        old_latitude: -23.52203399,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.9, -24.9326],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640620,
        school: 'LEOKENG SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.9,
        old_latitude: -24.9326,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.044986, -23.976118],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 913420208,
        school: 'LEPATO M. HIGH',
        status: 'Project reached "Close Out" status in 2019',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 31.04498558,
        old_latitude: -23.97611817,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.80899, -23.808],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141833,
        school: 'LEPHADIMISHA SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.80899,
        old_latitude: -23.808,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.036969, -23.454594],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340364,
        school: 'LEPHAI SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.03696903,
        old_latitude: -23.45459374,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.610891, -23.524323],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510231,
        school: 'LERALE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.61089134,
        old_latitude: -23.52432346,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.72699, -25.12554],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606531,
        school: 'LERIANA SENIOR SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.72699,
        old_latitude: -25.12554,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.163735, -23.988708],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540676,
        school: 'LESEKA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.16373464,
        old_latitude: -23.98870756,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.734664, -24.880317],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924640675,
        school: 'LESHALABE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73466354,
        old_latitude: -24.88031724,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.478192, -24.201956],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260432,
        school: 'LESHIKISHIKI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.47819192,
        old_latitude: -24.20195642,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.971296, -24.137163],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120607,
        school: 'LESHOBA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.97129631,
        old_latitude: -24.13716308,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.6125, -23.963451],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921140441,
        school: 'LESODI MOTLANA HIGHER PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.61249999,
        old_latitude: -23.96345126,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.223696, -24.554493],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620329,
        school: 'LETAU SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.22369553,
        old_latitude: -24.55449268,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.772276, -24.71676],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640699,
        school: 'LETEBELE MARISHANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.77227593,
        old_latitude: -24.71676021,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.699335, -23.461818],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340685,
        school: 'LETHEBA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.69933476,
        old_latitude: -23.46181834,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.430976, -24.411775],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230139,
        school: 'LETJATJI PRESIDENTIAL PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.43097616,
        old_latitude: -24.41177538,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.03904, -23.25096],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110396,
        school: 'LETLAMORENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.03904,
        old_latitude: -23.25096,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.89806, -23.133587],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210432,
        school: 'LETLHOEDI  PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.89805967,
        old_latitude: -23.13358661,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.135, -24.40133],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630722,
        school: 'LETOLWANE PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.135,
        old_latitude: -24.40133,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.42908, 28.83021],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922250386,
        school: 'LETSHEGA-MALOKWANE SECONDARY',
        status:
          'Project completed in 2018, but also on project list to start from 2021',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.83021,
        old_latitude: -23.42908,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.783427, 29.067002],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250393,
        school: 'LETSWALELA BAHLALOGA SECONDARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.067002,
        old_latitude: -23.783427,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.69448, -23.84988],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140465,
        school: 'LEUBANENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.69448,
        old_latitude: -23.84988,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398162, -22.974691],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320292,
        school: 'LIGEGE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39816234,
        old_latitude: -22.97469062,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.95484, -22.868996],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331889,
        school: 'LIPHAKHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.95484012,
        old_latitude: -22.86899614,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.089546, -23.230893],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 931521392,
        school: 'LISHAVHANA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.0895461,
        old_latitude: -23.2308931,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.810053, -23.113795],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332509,
        school: 'LITSHOVHU SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.81005309,
        old_latitude: -23.11379485,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.085099, -24.639006],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660327,
        school: 'LOBAMBA JUNIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.08509871,
        old_latitude: -24.63900562,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.623206, -22.853504],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350255,
        school: 'LONDOLANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.62320562,
        old_latitude: -22.85350361,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.175913, -23.87251],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 901530631,
        school: 'LORETTO FARM',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.17591306,
        old_latitude: -23.87251022,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.073215, -23.35244],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340050,
        school: 'LOTAVHA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.07321492,
        old_latitude: -23.35243991,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.106213, -23.261047],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931520528,
        school: 'LOTSHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.10621285,
        old_latitude: -23.26104735,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.489128, -22.83445],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930310967,
        school: 'LUCAS RATSHALINGWA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48912772,
        old_latitude: -22.83444953,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.088913, -22.874127],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330824,
        school: 'LUDANE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0889128,
        old_latitude: -22.87412681,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.517263, -22.96373],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350477,
        school: 'LUFULE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5172627,
        old_latitude: -22.96373015,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.369661, -22.805219],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311113,
        school: 'LUHENI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.369661,
        old_latitude: -22.80521945,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.832811, -22.776914],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361057,
        school: 'LUKALO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.83281057,
        old_latitude: -22.7769136,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.5157, -22.7406],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311120,
        school: 'LUKAU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5157,
        old_latitude: -22.7406,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.076431, -23.859183],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 917420222,
        school: 'LULEKANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 31.07643115,
        old_latitude: -23.85918323,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.490944, -22.806367],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311021,
        school: 'LUNWANNGWE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49094405,
        old_latitude: -22.80636727,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.244828, -23.16166],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321400,
        school: 'LUPEDZE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24482807,
        old_latitude: -23.16165989,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.950527, -23.356721],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340875,
        school: 'LUPENYO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.95052695,
        old_latitude: -23.35672078,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.387745, -22.919665],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 902351487,
        school: 'LUPHAI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38774502,
        old_latitude: -22.91966512,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.504617, -22.942371],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351043,
        school: 'LUTANDALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.50461661,
        old_latitude: -22.94237121,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.378284, -23.017448],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320704,
        school: 'LUVHAIVHAI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.3782843,
        old_latitude: -23.01744832,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.69844, 30.67599],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311014,
        school: 'LUVHENGO SECONDARY',
        status:
          'School is on a 2019 project list but it is not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.67599,
        old_latitude: -22.69844,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.65617, -23.04575],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332318,
        school: 'LUVHIVHINI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65617,
        old_latitude: -23.04575,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.619934, -22.69177],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310462,
        school: 'LUVHONE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.61993392,
        old_latitude: -22.6917697,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.393857, -23.042871],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320735,
        school: 'LWAMONDO HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.39385748,
        old_latitude: -23.04287111,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.390795, -22.990434],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320223,
        school: 'LWANDANI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.39079522,
        old_latitude: -22.99043383,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.587703, -22.486139],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 993302201,
        school: 'LWAPHUNGU SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.58770325,
        old_latitude: -22.48613865,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.41392, -23.09477],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321233,
        school: 'LWENZHE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.41392,
        old_latitude: -23.09477,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.81399, -23.96779],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120959,
        school: 'M.C. LANGA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.81399,
        old_latitude: -23.96779,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.45055, -23.39131],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918520535,
        school: 'M.R. MAMAILA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.45055,
        old_latitude: -23.39131,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.671145, -24.349668],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 926540119,
        school: 'MAAHLEMELE HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.67114469,
        old_latitude: -24.34966848,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.25451, -24.01942],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915541549,
        school: 'MAAKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25450982,
        old_latitude: -24.01941984,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.277116, -24.028708],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540706,
        school: 'MAALE SENIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27711647,
        old_latitude: -24.02870776,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.706884, -24.323483],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540720,
        school: 'MAATLA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.70688427,
        old_latitude: -24.32348348,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.396816, -24.58661],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620336,
        school: 'MAATSEREPE PRIMARY',
        status: 'School is on SAFE project list, but not active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.39681556,
        old_latitude: -24.58661027,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.042671, -24.937884],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606812,
        school: 'MABAKE SECONDARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.04267107,
        old_latitude: -24.93788424,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.8482, -23.081147],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 993301201,
        school: 'MABAYENI PRIMARY',
        status: 'Project is complete',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: null,
        old_latitude: null,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.755467, -23.481437],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340838,
        school: 'MABEBA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.75546741,
        old_latitude: -23.48143678,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.624243, -23.885404],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140038,
        school: 'MABETHA  PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.62424327,
        old_latitude: -23.88540388,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.480135, -22.690441],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929311144,
        school: 'MABILA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48013482,
        old_latitude: -22.6904415,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.99082, -23.935741],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 913420239,
        school: 'MABINE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.99082023,
        old_latitude: -23.93574072,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.596125, -24.934059],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651101,
        school: 'MABITSI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.59612501,
        old_latitude: -24.93405899,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.815132, -24.797444],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640750,
        school: 'MABODIBENG SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81513197,
        old_latitude: -24.7974436,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.792583, -24.59557],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610742,
        school: 'MABOE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.79258272,
        old_latitude: -24.59556979,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.12648, -24.5262],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630395,
        school: 'MABOELETSE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12648,
        old_latitude: -24.5262,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.6561, -24.47977],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643655,
        school: 'MABOOE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.6561,
        old_latitude: -24.47977,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.67633, -23.39062],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921250423,
        school: 'MABOPA-ABO-MPUWA PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.67633,
        old_latitude: -23.39062,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.031978, -24.484241],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630081,
        school: 'MABORAGANE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.03197783,
        old_latitude: -24.48424066,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.782329, -23.747362],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240303,
        school: 'MABOYANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.78232855,
        old_latitude: -23.74736219,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11502, -24.36327],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630739,
        school: 'MABU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11502,
        old_latitude: -24.36327,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.37867, -22.9837],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931321952,
        school: 'MABUDUGA JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37867,
        old_latitude: -22.9837,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.84839, -23.86553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140472,
        school: 'MABUSELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.84839,
        old_latitude: -23.86553,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.736145, -23.431927],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340845,
        school: 'MABYANENE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73614543,
        old_latitude: -23.43192696,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.852956, -23.858974],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904241092,
        school: 'MACHAKA ROBERT MAMABOLO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.85295572,
        old_latitude: -23.85897384,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.71782, -24.55145],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640774,
        school: 'MACHELANE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.71782,
        old_latitude: -24.55145,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.95766, -24.01919],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 906120676,
        school: 'MACHIKIRI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95766,
        old_latitude: -24.01919,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.7154, -23.09053],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332127,
        school: 'MADABANI JUNIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.7154,
        old_latitude: -23.09053,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.727653, -22.888286],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360900,
        school: 'MADADZHE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.72765252,
        old_latitude: -22.88828646,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11967, -23.31354],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340036,
        school: 'MADADZHI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11967,
        old_latitude: -23.31354,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.243855, -22.835738],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330213,
        school: 'MADALA JUNIOR PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24385533,
        old_latitude: -22.83573818,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.812422, -22.493667],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310165,
        school: 'MADANGANI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.81242244,
        old_latitude: -22.49366704,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.343589, -22.737815],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930311526,
        school: 'MADATSHITSHI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.34358949,
        old_latitude: -22.73781497,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.616157, -24.368177],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540140,
        school: 'MADIE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.61615747,
        old_latitude: -24.36817686,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.6211, -22.52682],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310226,
        school: 'MADIFHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.6211,
        old_latitude: -22.52682,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.989198, -23.28749],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340043,
        school: 'MADIHADZULI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.989198,
        old_latitude: -23.28749,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.757479, -24.580724],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924640781,
        school: 'MADIKALEPUDI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.757479,
        old_latitude: -24.580724,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.12607, -23.40942],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922210517,
        school: 'MADIKANA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.12607,
        old_latitude: -23.40942,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.98082, -24.7553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660396,
        school: 'MADIKANONO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.98082,
        old_latitude: -24.7553,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.28849, -25.08088],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606518,
        school: 'MADIKOTI PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.28849,
        old_latitude: -25.08088,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.89576, -24.04332],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120683,
        school: 'MADIKOTI PUTSOA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.89576,
        old_latitude: -24.04332,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.89155, -24.03082],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120690,
        school: 'MADIKWE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.89155,
        old_latitude: -24.03082,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.75197, -24.4227],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 925610254,
        school: 'MADITHAME SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.75197,
        old_latitude: -24.4227,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.394545, -23.189514],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520542,
        school: 'MADOBI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.394545,
        old_latitude: -23.189514,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.67814, -23.09045],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332035,
        school: 'MADODONGA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.67814,
        old_latitude: -23.09045,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.81889, -23.1233],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331179,
        school: 'MADOMBIDZHA JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.81889,
        old_latitude: -23.1233,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.782805, -23.00945],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361439,
        school: 'MADONSI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.78280511,
        old_latitude: -23.00944991,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.025259, -23.623745],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250447,
        school: 'MADUMA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.025259,
        old_latitude: -23.623745,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398261, -23.628306],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511258,
        school: 'MADUMANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39826068,
        old_latitude: -23.62830644,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.74937, -22.87262],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360405,
        school: 'MADZIKUSE COMBINED',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.74937,
        old_latitude: -22.87262,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.783128, -23.326845],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410485,
        school: 'MADZIVI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.783128,
        old_latitude: -23.326845,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.65434, -23.04147],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 928332103,
        school: 'MAEBANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65434,
        old_latitude: -23.04147,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88167, -24.49313],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610773,
        school: 'MAEBE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88167,
        old_latitude: -24.49313,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.05394, -24.37554],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631428,
        school: 'MAEPA PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05394,
        old_latitude: -24.37554,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.86431, -24.44553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610346,
        school: 'MAESELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.86431,
        old_latitude: -24.44553,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.17736, -23.44103],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110426,
        school: 'MAETELETJA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.17736,
        old_latitude: -23.44103,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.3505, -23.9612],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530655,
        school: 'MAFARANA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3505,
        old_latitude: -23.9612,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.3312, -24.64036],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620404,
        school: 'MAFEMANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3312,
        old_latitude: -24.64036,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.83169, -24.30675],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925611035,
        school: 'MAFENE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83169,
        old_latitude: -24.30675,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.647733, -22.871535],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350972,
        school: 'MAFENYA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.647733,
        old_latitude: -22.871535,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.05612, -24.49208],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630401,
        school: 'MAFETE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05612,
        old_latitude: -24.49208,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.36736, -23.05692],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320520,
        school: 'MAFHUMULELE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36736,
        old_latitude: -23.05692,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.8841, -23.41798],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250454,
        school: 'MAFIFING PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.8841,
        old_latitude: -23.41798,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82707, -24.25914],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630104,
        school: 'MAFISE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.82707,
        old_latitude: -24.25914,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.44557, 29.86206],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610117,
        school: 'MAFOUFALE SECONDARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.86206,
        old_latitude: -24.44557,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.41573, -24.40374],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230177,
        school: 'MAFSIAKGOMO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.41573,
        old_latitude: -24.40374,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.55651, -22.67262],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311168,
        school: 'MAFUKANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.55651,
        old_latitude: -22.67262,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.788346, -23.318234],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411341,
        school: 'MAFUMANI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.788346,
        old_latitude: -23.318234,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.477976, -24.305118],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540737,
        school: 'MAGAINGWANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.477976,
        old_latitude: -24.305118,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.42028, -23.01452],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320254,
        school: 'MAGALANNGWE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.42028,
        old_latitude: -23.01452,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.760768, -24.92851],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924640828,
        school: 'MAGALE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.760768,
        old_latitude: -24.92851,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.28651, -24.46784],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620411,
        school: 'MAGATAGABOTSE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28651,
        old_latitude: -24.46784,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.149733, -25.199333],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606614,
        school: 'MAGATE LEOPE PRIMARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.14973333,
        old_latitude: -25.19933333,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.405083, -24.4531],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230184,
        school: 'MAGATLE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.405083,
        old_latitude: -24.4531,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.595751, -22.735154],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310509,
        school: 'MAGILEDZHI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.595751,
        old_latitude: -22.735154,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.788838, -23.692419],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240358,
        school: 'MAGOGO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.788838,
        old_latitude: -23.692419,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.24562, -23.64083],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 918510279,
        school: 'MAGOLETSA SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24562,
        old_latitude: -23.64083,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.943, -24.63351],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660419,
        school: 'MAGOMARELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.943,
        old_latitude: -24.63351,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.885835, -23.018464],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360191,
        school: 'MAGONA LOWER PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.885835,
        old_latitude: -23.018464,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.74843, -23.10022],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332578,
        school: 'MAGONI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.74843,
        old_latitude: -23.10022,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.199267, -23.512817],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250461,
        school: 'MAGOSHI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.199267,
        old_latitude: -23.512817,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.207931, -22.988625],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331162,
        school: 'MAGUADA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.207931,
        old_latitude: -22.988625,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.813012, -24.99534],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650672,
        school: 'MAGUKUBJANE SENIOR SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.813012,
        old_latitude: -24.99534,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.27992, -23.23741],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520566,
        school: 'MAHATLANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27992,
        old_latitude: -23.23741,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.423095, -23.066793],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320131,
        school: 'MAHEMATSHENA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.423095,
        old_latitude: -23.066793,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.56504, -24.64162],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620435,
        school: 'MAHLAHLE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.56504,
        old_latitude: -24.64162,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.804912, -24.892017],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640842,
        school: 'MAHLAKANASELENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.804912,
        old_latitude: -24.892017,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.18061, -24.00493],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540171,
        school: 'MAHLANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.18061,
        old_latitude: -24.00493,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.808744, -23.904135],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240365,
        school: 'MAHLANHLE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.808744,
        old_latitude: -23.904135,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.935516, -23.221578],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210548,
        school: 'MAHLASE SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.935516,
        old_latitude: -23.221578,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.174501, -24.365652],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630746,
        school: 'MAHLO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.174501,
        old_latitude: -24.365652,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.60242, -24.57959],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640880,
        school: 'MAHLOLWANENG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.60242,
        old_latitude: -24.57959,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.60705, -23.73601],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140526,
        school: 'MAHLOMA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 28.60705,
        old_latitude: -23.73601,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.304467, -23.312289],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520160,
        school: 'MAHLORI HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.304467,
        old_latitude: -23.312289,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.788833, -23.417833],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921250485,
        school: 'MAHLWARENG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.788833,
        old_latitude: -23.417833,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.63737, -23.055485],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361552,
        school: 'MAHONISI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63737,
        old_latitude: -23.055485,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.86375, -24.8099],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630111,
        school: 'MAHUDU PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.86375,
        old_latitude: -24.8099,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.779856, -23.420362],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410515,
        school: 'MAHUMANI HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.779856,
        old_latitude: -23.420362,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.69268, -23.02285],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361651,
        school: 'MAHUNTSI SECONDARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.69268,
        old_latitude: -23.02285,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.537724, -24.364833],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540782,
        school: 'MAHUPJE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.537724,
        old_latitude: -24.364833,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.86728, -24.62657],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925610445,
        school: 'MAILA PRIMARY-1',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.86728,
        old_latitude: -24.62657,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.744399, -22.889781],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210555,
        school: 'MAIMELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.744399,
        old_latitude: -22.889781,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11112, -23.32001],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931341008,
        school: 'MAINGANYA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.11112,
        old_latitude: -23.32001,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.54567, -24.96878],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651958,
        school: 'MAJANE MATLALA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.54567,
        old_latitude: -24.96878,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.204491, -23.980096],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540799,
        school: 'MAJE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.204491,
        old_latitude: -23.980096,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.54338, -25.01354],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650092,
        school: 'MAKAEPEA PRIMARY-2',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.54338,
        old_latitude: -25.01354,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.916814, -22.891972],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360436,
        school: 'MAKAHLULE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.916814,
        old_latitude: -22.891972,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.3464, -22.99291],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320315,
        school: 'MAKAKAVHALE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.3464,
        old_latitude: -22.99291,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.20821, -23.42421],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919341619,
        school: 'MAKALA SECONDARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.20821,
        old_latitude: -23.42421,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.68422, 29.10664],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 992201502,
        school: 'MAKAMA SECONDARY SCHOOL',
        status:
          'Project completed in 2018, but school is not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.10664,
        old_latitude: -22.68422,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.746101, -23.269478],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922210579,
        school: 'MAKANGWANE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.746101,
        old_latitude: -23.269478,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.67978, -24.47413],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924610353,
        school: 'MAKANTANE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.67978,
        old_latitude: -24.47413,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.51624, -22.98238],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350446,
        school: 'MAKANYU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.51624,
        old_latitude: -22.98238,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.00933, -23.45565],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250508,
        school: 'MAKARAPENG PRIMARY',
        status: 'School is on SAFE project list, but not active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.00933,
        old_latitude: -23.45565,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.631093, -24.084023],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240396,
        school: 'MAKATA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.63109327,
        old_latitude: -24.08402251,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.71261, -22.44954],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311649,
        school: 'MAKAVHINI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.71261,
        old_latitude: -22.44954,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.95311, -23.11162],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210586,
        school: 'MAKGAFELA PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95311,
        old_latitude: -23.11162,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.001689, -24.342449],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631435,
        school: 'MAKGALANOTO PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.00168908,
        old_latitude: -24.34244857,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.712208, -24.088462],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 992203300,
        school: 'MAKGALAPANE COMBINED',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.712208,
        old_latitude: -24.088462,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.872947, -22.943367],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210593,
        school: 'MAKGARI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.872947,
        old_latitude: -22.943367,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.66531, -23.50452],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340661,
        school: 'MAKGATO HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66531,
        old_latitude: -23.50452,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.74591, -24.72909],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640934,
        school: 'MAKGATSIKE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.74591,
        old_latitude: -24.72909,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.45444, -24.286359],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 926540805,
        school: 'MAKGAUNG LOWER AND HIGHER PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.45444,
        old_latitude: -24.286359,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.435606, -24.328724],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260574,
        school: 'MAKGOATHANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.435606,
        old_latitude: -24.328724,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.7225, -23.914667],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240433,
        school: 'MAKGOBAKETSE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7225,
        old_latitude: -23.914667,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.46367, -23.61733],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510293,
        school: 'MAKGOPE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.46367,
        old_latitude: -23.61733,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.560414, -23.429152],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918521309,
        school: 'MAKGOPELE HIGH',
        status: 'School is on a project list',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.560414,
        old_latitude: -23.429152,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.8291, -24.98336],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924640958,
        school: 'MAKGOPHENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8291,
        old_latitude: -24.98336,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.986149, -24.084865],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120768,
        school: 'MAKGUBUKETJA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.986149,
        old_latitude: -24.084865,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.741683, -23.872767],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240471,
        school: 'MAKGWADIBA PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.741683,
        old_latitude: -23.872767,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.001547, -23.605483],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901340531,
        school: 'MAKHABENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.001547,
        old_latitude: -23.605483,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.77822, -22.92762],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360184,
        school: 'MAKHASA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.77822,
        old_latitude: -22.92762,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.330362, -23.596349],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510309,
        school: 'MAKHEALA PRIMARY',
        status: 'School is on SAFE project list, but not active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.330362,
        old_latitude: -23.596349,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.056726, -23.552949],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916412031,
        school: 'MAKHETO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 31.056726,
        old_latitude: -23.552949,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.74692, -25.12066],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606519,
        school: 'MAKHUMA PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.74692,
        old_latitude: -25.12066,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.08156, -23.29807],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110464,
        school: 'MAKHURUMULA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.08156,
        old_latitude: -23.29807,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.043643, -23.976311],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 913420277,
        school: 'MAKHUSHANE LOWER PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 31.043643,
        old_latitude: -23.976311,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.50821, -22.8715],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350767,
        school: 'MAKHUVHA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.50821,
        old_latitude: -22.8715,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.31919, -24.33948],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620480,
        school: 'MAKHWESE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.31919,
        old_latitude: -24.33948,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.28077, -24.32147],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620473,
        school: 'MAKIDI SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.28077,
        old_latitude: -24.32147,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.933847, -23.690869],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 913420291,
        school: 'MAKIKELA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.933847,
        old_latitude: -23.690869,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.81192, -23.53889],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142003,
        school: 'MAKOBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.81192,
        old_latitude: -23.53889,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.569955, -22.803859],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350248,
        school: 'MAKONDE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.569955,
        old_latitude: -22.803859,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.74687, -24.44219],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924610124,
        school: 'MAKOPOLE I SENIOR SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.74687,
        old_latitude: -24.44219,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.44914, 27.14272],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909130758,
        school: 'MAKOPPA COMBINED',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.14272,
        old_latitude: -24.44914,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.59277, -24.50387],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620497,
        school: 'MAKOPUNG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.59277,
        old_latitude: -24.50387,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.095559, -24.34259],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630760,
        school: 'MAKUANE SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.095559,
        old_latitude: -24.34259,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.80085, -24.733671],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641029,
        school: 'MAKUBARATE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.80085,
        old_latitude: -24.733671,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.920225, -22.864],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360443,
        school: 'MAKULEKE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.920225,
        old_latitude: -22.864,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.408494, -22.996664],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930320148,
        school: 'MAKUMBANE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.408494,
        old_latitude: -22.996664,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11963, -22.91224],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330756,
        school: 'MAKUMBWI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11963,
        old_latitude: -22.91224,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.033109, -23.448401],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340517,
        school: 'MAKWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.033109,
        old_latitude: -23.448401,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.31767, -24.35231],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620510,
        school: 'MAKWALE PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31767,
        old_latitude: -24.35231,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.42752, -22.85827],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930310882,
        school: 'MAKWARANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42752,
        old_latitude: -22.85827,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.126465, -22.990376],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 928331315,
        school: 'MAKWATAMBANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.126465,
        old_latitude: -22.990376,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.966406, -23.626658],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901340500,
        school: 'MALADUMA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96640636,
        old_latitude: -23.62665754,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.39713853, 28.98583577],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922210623,
        school: 'MALAKA PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.98583577,
        old_latitude: -23.39713853,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.594099, -22.396443],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928310011,
        school: 'MALALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.59409877,
        old_latitude: -22.39644314,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.36436, -22.90683],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350705,
        school: 'MALAMGWA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36436,
        old_latitude: -22.90683,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.642977, -23.988628],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240501,
        school: 'MALATSWA HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.64297685,
        old_latitude: -23.98862778,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.502387, -24.207743],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540829,
        school: 'MALEBALONG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.50238693,
        old_latitude: -24.20774328,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.941869, -23.138798],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210630,
        school: 'MALEBOHO SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.94186937,
        old_latitude: -23.13879832,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.262015, -24.916855],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 910130765,
        school: 'MALEBONE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.26201481,
        old_latitude: -24.91685546,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.885988, -22.868516],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360450,
        school: 'MALEDZA PRIMARY',
        status: 'Project reached "Close Out" status in 2019',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.88598757,
        old_latitude: -22.86851601,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.968955, -24.610392],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660495,
        school: 'MALEGALE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96895533,
        old_latitude: -24.61039246,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.101454, -24.349286],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630777,
        school: 'MALEGODI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1014535,
        old_latitude: -24.34928611,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.599331, -24.393935],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260598,
        school: 'MALEKAPANE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.59933086,
        old_latitude: -24.3939348,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82223, -24.72569],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641067,
        school: 'MALEKUTU SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82223,
        old_latitude: -24.72569,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.282954, -23.61819],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510347,
        school: 'MALEMATSHA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.282954,
        old_latitude: -23.61819,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.72577, -23.7473],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140564,
        school: 'MALEMOCHA HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.72577,
        old_latitude: -23.7473,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.76285, -23.90168],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240518,
        school: 'MALESA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76285,
        old_latitude: -23.90168,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.797541, -23.720782],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240525,
        school: 'MALESELA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.797541,
        old_latitude: -23.720782,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.55485, -22.618891],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311359,
        school: 'MALILELE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.55485,
        old_latitude: -22.618891,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.20849, -23.164759],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321639,
        school: 'MALINGA WILSON SECONDARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.20849,
        old_latitude: -23.164759,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.5178, -22.57462],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310141,
        school: 'MALINGE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5178,
        old_latitude: -22.57462,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.563249, -23.434523],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510361,
        school: 'MALOBA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.563249,
        old_latitude: -23.434523,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.40116, -23.64739],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922220590,
        school: 'MALOKANENG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.40116,
        old_latitude: -23.64739,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.986861, -24.68128],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660518,
        school: 'MALOKE PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.986861,
        old_latitude: -24.68128,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.79355, -23.83263],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140571,
        school: 'MALOKONG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.79355,
        old_latitude: -23.83263,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.921167, -22.941833],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210654,
        school: 'MALOLOANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.921167,
        old_latitude: -22.941833,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.49648, -23.733636],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530693,
        school: 'MALUBANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.49648,
        old_latitude: -23.733636,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.100356, -23.144006],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 920210661,
        school: 'MALUSI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.100356,
        old_latitude: -23.144006,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.8231, -23.12051],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331513,
        school: 'MALUTA JUNIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.8231,
        old_latitude: -23.12051,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.737586, -23.87067],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240549,
        school: 'MAMABUDUSHA HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.737586,
        old_latitude: -23.87067,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.67783, -25.0035],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651507,
        school: 'MAMADI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.67783,
        old_latitude: -25.0035,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.042067, -23.33835],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922210685,
        school: 'MAMADISHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.042067,
        old_latitude: -23.33835,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.733041, -23.432186],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340326,
        school: 'MAMAFA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.733041,
        old_latitude: -23.432186,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.665783, -23.878422],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240556,
        school: 'MAMAHLO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.665783,
        old_latitude: -23.878422,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.571107, -24.305303],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260628,
        school: 'MAMAOLO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.571107,
        old_latitude: -24.305303,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.5122, -24.91689],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650832,
        school: 'MAMASEGARE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.5122,
        old_latitude: -24.91689,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.043501, -23.226109],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210692,
        school: 'MAMASONYA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.043501,
        old_latitude: -23.226109,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.99047, -23.60977],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901341176,
        school: 'MAMERIRI SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.99047,
        old_latitude: -23.60977,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.666548, -24.035908],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923240563,
        school: 'MAMODIKELENG SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.666548,
        old_latitude: -24.035908,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.857554, -22.915025],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210715,
        school: 'MAMODUWANE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.857554,
        old_latitude: -22.915025,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.280932, -24.321285],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620534,
        school: 'MAMOGOLO PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.280932,
        old_latitude: -24.321285,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.93286, -24.35019],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630142,
        school: 'MAMOKGALAKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.93286,
        old_latitude: -24.35019,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.811312, -23.453918],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340371,
        school: 'MAMOKUTUPI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.811312,
        old_latitude: -23.453918,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.24627, -23.285008],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904210722,
        school: 'MAMOLELE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.24627,
        old_latitude: -23.285008,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.350168, -23.670248],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220613,
        school: 'MAMOLEMANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.350168,
        old_latitude: -23.670248,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.93321, -24.63244],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610452,
        school: 'MAMOLOBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.93321,
        old_latitude: -24.63244,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.22598, -24.48977],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620541,
        school: 'MAMOLOBELA SECONDARY',
        status: 'School is on a project list',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.22598,
        old_latitude: -24.48977,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.818164, -23.469995],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340487,
        school: 'MAMOTHE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.818164,
        old_latitude: -23.469995,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.994483, -23.017486],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210746,
        school: 'MAMOYAHABO PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.994483,
        old_latitude: -23.017486,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.677079, -24.049828],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923240594,
        school: 'MAMPA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.677079,
        old_latitude: -24.049828,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.642561, -23.441078],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918521347,
        school: 'MAMPEULE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.642561,
        old_latitude: -23.441078,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.461976, -22.65187],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929311373,
        school: 'MAMPHODO SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.461976,
        old_latitude: -22.65187,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.964904, -23.193652],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210753,
        school: 'MAMPOTE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.964904,
        old_latitude: -23.193652,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.08759, -24.81197],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660570,
        school: 'MAMPURU PRIMARY-2',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.08759,
        old_latitude: -24.81197,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88482, -24.47351],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610544,
        school: 'MAMPURU-THULARE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88482,
        old_latitude: -24.47351,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.445216, 28.933454],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906120812,
        school: 'MAMRUNYANA FARM COMBINED',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.933454,
        old_latitude: -24.445216,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.28867, -24.400877],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230245,
        school: 'MANAILENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.28867,
        old_latitude: -24.400877,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.03289, -24.73826],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660587,
        school: 'MANCHAKGATHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.03289,
        old_latitude: -24.73826,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.152914, -23.640972],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250546,
        school: 'MANCHIMUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.152914,
        old_latitude: -23.640972,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.124877, -22.867481],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330022,
        school: 'MANDIWANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.124877,
        old_latitude: -22.867481,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.79294, -23.07984],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332264,
        school: 'MANELEDZI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.79294,
        old_latitude: -23.07984,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.682756, -22.470502],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310271,
        school: 'MANENZHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.682756,
        old_latitude: -22.470502,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.626787, -22.719101],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311533,
        school: 'MANGAYA',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.626787,
        old_latitude: -22.719101,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.957722, -23.521858],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410553,
        school: 'MANGHEZI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.957722,
        old_latitude: -23.521858,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.113632, -23.397088],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 919341053,
        school: 'MANGOAKO SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.11363228,
        old_latitude: -23.39708792,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.543901, -24.740391],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641142,
        school: 'MANGOLWANE SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.54390094,
        old_latitude: -24.74039125,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.61407, -22.93956],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350415,
        school: 'MANGONDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.61407,
        old_latitude: -22.93956,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.62057, -23.95159],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140595,
        school: 'MANGWEDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.62057,
        old_latitude: -23.95159,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.480952, -22.987075],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350422,
        school: 'MANIINI JUNIOR AND SENIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48095244,
        old_latitude: -22.98707513,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.310293, -24.289765],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620565,
        school: 'MANKELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.31029322,
        old_latitude: -24.28976538,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.366259, -24.49748],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230252,
        school: 'MANKHOLE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.366259,
        old_latitude: -24.49747982,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.820342, -23.877865],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240648,
        school: 'MANKOENG HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82034218,
        old_latitude: -23.87786508,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82837, -24.4335],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918610360,
        school: 'MANKOPANE LOWER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82837,
        old_latitude: -24.4335,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.923353, -24.543161],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610551,
        school: 'MANKOPODI PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.92335326,
        old_latitude: -24.54316052,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.859384, -23.18723],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210791,
        school: 'MANOE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.85938389,
        old_latitude: -23.18722997,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.61514315, 30.28927573],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510408,
        school: 'MANOKWE SECONDARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28927573,
        old_latitude: -23.61514315,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.10955, -24.77406],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660617,
        school: 'MANTE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.10955,
        old_latitude: -24.77406,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.741284, -23.767681],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240679,
        school: 'MANTHEDING PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.74128356,
        old_latitude: -23.76768138,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.91945, -24.84591],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641210,
        school: 'MANTLHANYANE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.91945,
        old_latitude: -24.84591,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.27969, -24.43366],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620589,
        school: 'MANTOPI PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27969,
        old_latitude: -24.43366,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.016727, -23.615942],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901340470,
        school: 'MANTSHA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.01672717,
        old_latitude: -23.61594187,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.6074, -23.4239],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918512145,
        school: 'MANWAGAE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.60740038,
        old_latitude: -23.42389988,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.926702, -22.865325],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331865,
        school: 'MANYII PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.92670155,
        old_latitude: -22.86532464,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.758144, -22.824149],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361071,
        school: 'MANZEMBA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.75814361,
        old_latitude: -22.82414882,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.64664, -22.46482],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310196,
        school: 'MAPAKONI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.64664,
        old_latitude: -22.46482,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.039817, -22.886186],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 928330732,
        school: 'MAPAKOPHELE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.03981725,
        old_latitude: -22.88618637,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.622176, -23.912249],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142478,
        school: 'MAPALA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.62217621,
        old_latitude: -23.91224887,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.71155485, 29.04727137],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250560,
        school: 'MAPALE SENIOR SECONDARY',
        status: 'School may have merged with another school',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.04727137,
        old_latitude: -23.71155485,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82406, -23.45086],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340463,
        school: 'MAPALEDI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82406,
        old_latitude: -23.45086,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.056165, -22.76469],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331896,
        school: 'MAPANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05616541,
        old_latitude: -22.76469029,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.737749, -23.064016],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361378,
        school: 'MAPAPILA PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.73774947,
        old_latitude: -23.06401594,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.358772, -22.990159],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320124,
        school: 'MAPATE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.35877155,
        old_latitude: -22.99015899,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.412052, -24.451284],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230269,
        school: 'MAPATJAKENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.41205168,
        old_latitude: -24.4512838,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.84762, -23.95803],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120843,
        school: 'MAPELA JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.84762,
        old_latitude: -23.95803,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.665409, -23.898926],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240686,
        school: 'MAPELOANA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66540903,
        old_latitude: -23.89892615,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.85735, -24.63314],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610469,
        school: 'MAPHALE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.85735,
        old_latitude: -24.63314,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.15384, -23.19148],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520610,
        school: 'MAPHANYI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.15384,
        old_latitude: -23.19148,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.653181, -24.010058],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923246693,
        school: 'MAPHEFO PRIMARY-1',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.6531812,
        old_latitude: -24.01005832,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.492716, -23.667675],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530181,
        school: 'MAPHETO JUNIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.49271569,
        old_latitude: -23.6676754,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.994146, -23.972733],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 913420307,
        school: 'MAPHOKWANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.99414573,
        old_latitude: -23.97273287,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.955364, -24.918006],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660631,
        school: 'MAPHOOKO LOWER AND HIGHER PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95536354,
        old_latitude: -24.91800566,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.77166, -24.41179],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610681,
        school: 'MAPHOTLE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.77166,
        old_latitude: -24.41179,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.367787, -23.019257],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320438,
        school: 'MAPHUPHE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36778736,
        old_latitude: -23.01925657,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.890291, -23.495012],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916410560,
        school: 'MAPHUSHA HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.89029137,
        old_latitude: -23.49501163,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.92301, -23.65213],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251662,
        school: 'MAPHUTHA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.92301,
        old_latitude: -23.65213,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.11086, -23.09187],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904210821,
        school: 'MAPHUTHA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.11086,
        old_latitude: -23.09187,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.583354, -23.938752],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140625,
        school: 'MAPITIKAMA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.58335375,
        old_latitude: -23.93875173,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.32248, -23.69539],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510446,
        school: 'MAPITLULA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 30.32248,
        old_latitude: -23.69539,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.95536, -25.1251],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606302,
        school: 'MAPOGHS PRIMARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95536,
        old_latitude: -25.1251,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.78744, -24.77371],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641272,
        school: 'MAPOGO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.78744,
        old_latitude: -24.77371,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.945758, -23.2479],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210838,
        school: 'MAPOTLA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9457576,
        old_latitude: -23.24789965,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.347814, -23.692311],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510453,
        school: 'MAPULA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.34781382,
        old_latitude: -23.69231108,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.485938, -24.620374],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641111,
        school: 'MAPURU TSEKE SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.48593773,
        old_latitude: -24.62037362,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.81425, -23.96563],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120850,
        school: 'MAPUSO PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.81425,
        old_latitude: -23.96563,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.1542, -24.54511],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630449,
        school: 'MAPUTLE SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1542,
        old_latitude: -24.54511,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.447067, -24.5669],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230279,
        school: 'MARAGANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.447067,
        old_latitude: -24.5669,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.37061, -24.6392],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 996602103,
        school: 'MARAKABELA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37061,
        old_latitude: -24.6392,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.805403, -22.429033],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310295,
        school: 'MARAMANZHI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.80540272,
        old_latitude: -22.42903291,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.815182, -24.05842],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921120874,
        school: 'MARCUS MASIBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.81518231,
        old_latitude: -24.05841978,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.659807, -24.481952],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925620626,
        school: 'MAREMISHA HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65980684,
        old_latitude: -24.48195243,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.65247, -24.50617],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620633,
        school: 'MAREOLOGE PRIMARY',
        status: 'Project completed',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.65247,
        old_latitude: -24.50617,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.62279, -24.95871],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651255,
        school: 'MARESELENG  HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.62279,
        old_latitude: -24.95871,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.574984, -24.317336],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260680,
        school: 'MARETLWANENGG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.57498413,
        old_latitude: -24.31733623,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.348224, -23.892494],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530747,
        school: 'MARIVENI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.34822448,
        old_latitude: -23.89249402,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.412662, -23.730409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530242,
        school: 'MARK SHOPE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.41266163,
        old_latitude: -23.73040947,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.36306, -24.349214],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230283,
        school: 'MAROBA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.36305998,
        old_latitude: -24.3492138,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.784453, -23.690412],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240730,
        school: 'MAROBALA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.78445258,
        old_latitude: -23.69041163,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.272803, -24.030217],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540881,
        school: 'MAROBONI JUNIOR PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27280333,
        old_latitude: -24.03021725,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.14825, -24.54151],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630456,
        school: 'MAROGA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 30.14825,
        old_latitude: -24.54151,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.871906, -22.194934],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 902332554,
        school: 'MAROI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.87190559,
        old_latitude: -22.1949337,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.962116, -24.79047],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660662,
        school: 'MAROTA MAKGANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96211617,
        old_latitude: -24.79047005,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.349176, -24.558604],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620640,
        school: 'MAROTA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34917615,
        old_latitude: -24.55860356,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.814782, -24.730103],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641357,
        school: 'MAROTOBANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81478193,
        old_latitude: -24.73010274,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.81500116, 29.73025998],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641333,
        school: 'MARUTLE PRIMARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.73025998,
        old_latitude: -24.81500116,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.975654, -24.885335],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661702,
        school: 'MASAGO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.97565359,
        old_latitude: -24.88533497,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.257192, -23.273164],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931520627,
        school: 'MASAKONA SENIOR PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.2571917,
        old_latitude: -23.2731639,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.873555, -22.469906],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905310325,
        school: 'MASALA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.87355494,
        old_latitude: -22.46990586,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.349984, -23.622946],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510477,
        school: 'MASALANABO HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34998427,
        old_latitude: -23.62294638,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.06323, -24.44804],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630470,
        school: 'MASEBUDI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.06323014,
        old_latitude: -24.44804013,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.835046, -23.463126],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340388,
        school: 'MASEDI SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83504589,
        old_latitude: -23.4631264,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.40763, -23.69701],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220682,
        school: 'MASEGELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.40763,
        old_latitude: -23.69701,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.95366, -24.55838],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610575,
        school: 'MASEHLENG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95366,
        old_latitude: -24.55838,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.555899, -24.290454],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541563,
        school: 'MASEKANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55589906,
        old_latitude: -24.29045426,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.810108, -23.843578],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240778,
        school: 'MASEKWAMENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.81010784,
        old_latitude: -23.84357777,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.423795, -23.592055],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510507,
        school: 'MASELEKWANE HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42379454,
        old_latitude: -23.59205518,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.887135, -24.647742],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661723,
        school: 'MASELESELENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88713535,
        old_latitude: -24.64774215,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.2401, -23.32868],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210876,
        school: 'MASENWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.2401,
        old_latitude: -23.32868,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.19398, -24.39614],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630814,
        school: 'MASENYELETJE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.19398,
        old_latitude: -24.39614,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.955204, -24.797204],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660693,
        school: 'MASERALA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.95520378,
        old_latitude: -24.79720368,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.244139, -23.697255],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220699,
        school: 'MASEROLE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.24413856,
        old_latitude: -23.69725485,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.912997, -22.850104],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920331964,
        school: 'MASETE PRIMARY',
        status:
          'Construction started on project around 2019, but school is also on project list to start from 2021',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.91299742,
        old_latitude: -22.85010379,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.051169, -23.257671],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340173,
        school: 'MASETHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05116885,
        old_latitude: -23.25767094,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.881881, -22.72276],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361002,
        school: 'MASETONI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.88188147,
        old_latitude: -22.72275989,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.099085, -24.17703],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260697,
        school: 'MASHABASHABA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.09908482,
        old_latitude: -24.17702969,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.72691, -24.62265],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641388,
        school: 'MASHABELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.72691,
        old_latitude: -24.62265,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.833367, -23.455946],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340395,
        school: 'MASHAHA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83336667,
        old_latitude: -23.45594629,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.958223, -22.77304],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360481,
        school: 'MASHAKADZI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.95822346,
        old_latitude: -22.77303978,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.122941, -23.933782],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220705,
        school: 'MASHASHANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.12294068,
        old_latitude: -23.93378182,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.47066, -23.06146],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350392,
        school: 'MASHAWANA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.47066,
        old_latitude: -23.06146,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.94833, -24.866],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660716,
        school: 'MASHEGO JUNIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.94833,
        old_latitude: -24.866,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.84781, -24.78051],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921661412,
        school: 'MASHEGOANYANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.84781,
        old_latitude: -24.78051,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.11564, -23.87235],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220712,
        school: 'MASHIANOKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.11564,
        old_latitude: -23.87235,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.808538, -22.918854],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210883,
        school: 'MASHIE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.80853809,
        old_latitude: -22.91885401,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.306334, -24.341473],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230449,
        school: 'MASHIGOANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.30633415,
        old_latitude: -24.34147291,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.797312, -23.387765],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250591,
        school: 'MASHILO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.79731234,
        old_latitude: -23.38776452,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.701318, -23.004353],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920213157,
        school: 'MASHILOMPANA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.7013181,
        old_latitude: -23.00435283,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.696806, -24.311674],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260710,
        school: 'MASHITE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.69680613,
        old_latitude: -24.31167427,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.738173, -25.04493],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905650443,
        school: 'MASHIYANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73817336,
        old_latitude: -25.04493003,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.69966, -23.64291],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921421027,
        school: 'MASHOBELA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.69966,
        old_latitude: -23.64291,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.891255, -22.966489],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360122,
        school: 'MASHOBYE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.89125467,
        old_latitude: -22.96648883,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.84043, -23.56985],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142263,
        school: 'MASHOSHO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.84043,
        old_latitude: -23.56985,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.629687, -23.530308],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141918,
        school: 'MASHUBASHUBA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.62968712,
        old_latitude: -23.53030788,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.106731, -25.050695],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 996606805,
        school: 'MASHUNG PRIMARY SCHOOL',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10673101,
        old_latitude: -25.05069508,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.18670475, 30.31240923],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321509,
        school: 'MASIA SENIOR PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31240923,
        old_latitude: -23.18670475,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.610808, -22.809702],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350231,
        school: 'MASIKHWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.61080762,
        old_latitude: -22.80970176,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.960647, -23.225341],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931331223,
        school: 'MASINDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96064686,
        old_latitude: -23.22534112,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.86892, -22.41825],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310158,
        school: 'MASISI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.86892,
        old_latitude: -22.41825,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.96024, -24.12971],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921120898,
        school: 'MASODI SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.96024,
        old_latitude: -24.12971,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.438217, -24.769666],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924650849,
        school: 'MASOGANENG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.43821714,
        old_latitude: -24.76966615,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.048673, -23.170213],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912331056,
        school: 'MASUNGULO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.04867253,
        old_latitude: -23.17021335,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.835009, -23.523124],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340876,
        school: 'MASWAHLANE JUNIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83500942,
        old_latitude: -23.52312407,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.090132, -24.190896],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260727,
        school: 'MATALANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.09013202,
        old_latitude: -24.19089594,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.46176, -23.21177],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320940,
        school: 'MATAMELA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.46176,
        old_latitude: -23.21177,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.53738, -22.80203],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930310790,
        school: 'MATANGARI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.53738,
        old_latitude: -22.80203,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.627069, -23.570264],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918512138,
        school: 'MATARAPANE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.62706882,
        old_latitude: -23.57026363,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.76501, -22.56364],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310103,
        school: 'MATATANI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.76501,
        old_latitude: -22.56364,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.320062, -23.063159],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320568,
        school: 'MATAVHA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.3200615,
        old_latitude: -23.0631586,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.525555, -22.672923],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311380,
        school: 'MATAVHELA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.52555508,
        old_latitude: -22.67292332,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.493405, -24.55796],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620664,
        school: 'MATHAFENG SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49340515,
        old_latitude: -24.55796022,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.88232, -23.23015],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210906,
        school: 'MATHEKO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.88232,
        old_latitude: -23.23015,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.254741, -23.354917],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919341077,
        school: 'MATHIBADIFATE JUNIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25474122,
        old_latitude: -23.35491651,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.91456, -24.62081],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610476,
        school: 'MATHIBENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.91456,
        old_latitude: -24.62081,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.033848, -22.930721],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210913,
        school: 'MATHIPA MAKGATO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.03384844,
        old_latitude: -22.93072094,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11392, -24.67189],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630487,
        school: 'MATHOLENI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11392,
        old_latitude: -24.67189,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.285258, -23.166487],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321370,
        school: 'MATHOTHWE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28525836,
        old_latitude: -23.16648699,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.73946, -25.1043],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650450,
        school: 'MATHULA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73946,
        old_latitude: -25.1043,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.588113, -23.708516],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921142416,
        school: 'MATHULAMISHA HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.58811337,
        old_latitude: -23.70851564,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.03618526, 30.38963114],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320490,
        school: 'MATHULE PRIMARY-2',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38963114,
        old_latitude: -23.03618526,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.857777, -23.240676],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210920,
        school: 'MATHUWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.85777677,
        old_latitude: -23.24067602,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.954163, -24.28749],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631480,
        school: 'MATIANYANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95416329,
        old_latitude: -24.28749001,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.01166, -24.76539],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925660747,
        school: 'MATIME PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.01166,
        old_latitude: -24.76539,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.651468, -24.314392],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923260758,
        school: 'MATIME SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.6514684,
        old_latitude: -24.31439238,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.241872, -23.92629],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915540218,
        school: 'MATIMU HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24187203,
        old_latitude: -23.92628978,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.635669, -23.055734],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332141,
        school: 'MATITIVHALA JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.63566936,
        old_latitude: -23.05573434,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.674089, -24.544342],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621223,
        school: 'MATLABONG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.67408877,
        old_latitude: -24.54434152,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.684728, -24.53653],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641463,
        school: 'MATLEBJOANE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68472794,
        old_latitude: -24.53652967,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.047284, -24.981017],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606807,
        school: 'MATLEREKENG PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.04728408,
        old_latitude: -24.98101686,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.91474, -24.46561],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610599,
        school: 'MATLEU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.91474,
        old_latitude: -24.46561,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.52216, -23.59609],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141925,
        school: 'MATLOU MEMORIAL PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.52216,
        old_latitude: -23.59609,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.163415, -23.23246],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931521514,
        school: 'MATOANZIE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.16341495,
        old_latitude: -23.23246016,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.515944, -23.23434],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321172,
        school: 'MATODZI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.51594396,
        old_latitude: -23.23433987,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.386178, -22.940756],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350774,
        school: 'MATONDONI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.38617829,
        old_latitude: -22.94075638,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.61162, -23.96186],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140731,
        school: 'MATOPA SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.61162,
        old_latitude: -23.96186,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.555542, -23.212009],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911410591,
        school: 'MATSAMBU HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55554205,
        old_latitude: -23.21200914,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.56611, -24.884314],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924551125,
        school: 'MATSEDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.56611048,
        old_latitude: -24.88431433,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.969514, 30.278681],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 995504601,
        school: 'Matseutseu Maropeng Primary',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.278681,
        old_latitude: -23.969514,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.104269, -22.973015],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331308,
        school: 'MATSHAVHAWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.1042691,
        old_latitude: -22.97301524,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.5875, -24.691133],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924320468,
        school: 'MATSHELE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.5875,
        old_latitude: -24.691133,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.095715, -25.26205],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606616,
        school: 'MATSHIPHE PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.09571517,
        old_latitude: -25.26205009,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.36042, -23.587409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510606,
        school: 'MATSHWI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36042032,
        old_latitude: -23.58740937,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.916046, -24.079134],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921120935,
        school: 'MATSIBE HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.91604629,
        old_latitude: -24.07913392,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.563337, -24.352663],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540936,
        school: 'MATSIKINYANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.56333699,
        old_latitude: -24.35266328,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.264109, -23.196358],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321745,
        school: 'MATSILA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.26410862,
        old_latitude: -23.19635796,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.209434, -23.172462],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321424,
        school: 'MATSINDEVHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.20943416,
        old_latitude: -23.17246154,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.849688, -23.855603],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921140748,
        school: 'MATSOGELLA SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.84968841,
        old_latitude: -23.85560303,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.699414, -22.975545],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210951,
        school: 'MATSUOKWANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.69941401,
        old_latitude: -22.97554451,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.05191, -23.12099],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210968,
        school: 'MATSWAKE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.05191,
        old_latitude: -23.12099,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.963938, -23.633104],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 901340746,
        school: 'MATSWIDIKANYE',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96393842,
        old_latitude: -23.63310384,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.686913, -23.321538],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922212407,
        school: 'MATUNTUNYANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.68691336,
        old_latitude: -23.32153821,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.380267, -23.420077],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918520665,
        school: 'MAUFOTA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38026743,
        old_latitude: -23.42007676,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.135405, -22.928292],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330664,
        school: 'MAULUMA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.13540479,
        old_latitude: -22.92829239,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.42774, -22.97373],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350682,
        school: 'MAUNGANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42774048,
        old_latitude: -22.97372971,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.454387, -23.685041],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530778,
        school: 'MAVABAZA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.45438668,
        old_latitude: -23.68504113,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.63038, -23.006892],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361750,
        school: 'MAVAMBE COMBINE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63038032,
        old_latitude: -23.0068915,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.188907, -23.188352],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321325,
        school: 'MAVHINA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.18890736,
        old_latitude: -23.188352,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.371361, -22.745258],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929311328,
        school: 'MAVHODE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37136051,
        old_latitude: -22.74525774,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.117167, -22.926686],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330794,
        school: 'MAVHUNGA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1171669,
        old_latitude: -22.9266864,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.7452, -22.71476],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310660,
        school: 'MAVUNDE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.7452,
        old_latitude: -22.71476,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.17003, -23.27139],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931520672,
        school: 'MAWELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.17003,
        old_latitude: -23.27139,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.806802, -22.8317],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360269,
        school: 'MAYEKE HIGHER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.80680192,
        old_latitude: -22.83169986,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.781624, -23.587835],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916420376,
        school: 'MAYEPHU PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.78162351,
        old_latitude: -23.58783452,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.911904, -23.21896],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110594,
        school: 'MAZWE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.91190386,
        old_latitude: -23.21896022,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.593087, -22.894674],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350675,
        school: 'MBAHE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.59308709,
        old_latitude: -22.89467443,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.452128, -22.810156],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310905,
        school: 'MBAHELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.45212789,
        old_latitude: -22.81015642,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.426774, -23.97215],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915531337,
        school: 'MBETANA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42677436,
        old_latitude: -23.97214995,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.500216, -23.119572],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911320971,
        school: 'MBHALATI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.50021581,
        old_latitude: -23.11957191,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.70356, -23.24294],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 916410614,
        school: 'MBHANGAZEKI HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.70356,
        old_latitude: -23.24294,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.475412, -22.931926],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351081,
        school: 'MBILWANA SENIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.47541212,
        old_latitude: -22.93192616,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.093932, -23.199884],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520689,
        school: 'MBOKOTA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.09393223,
        old_latitude: -23.19988413,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.44363, -22.78356],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311175,
        school: 'MBONENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44363,
        old_latitude: -22.78356,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.336976, -23.050986],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320551,
        school: 'MBOSWOBENI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.33697649,
        old_latitude: -23.05098554,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.490205, -22.905753],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350781,
        school: 'MBULAHENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49020469,
        old_latitude: -22.90575289,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.555901, -22.828878],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350224,
        school: 'MBULU JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55590079,
        old_latitude: -22.82887778,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.732887, -23.001659],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 911361361,
        school: 'MDANISI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.73288718,
        old_latitude: -23.00165853,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.40246, -24.00794],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 907130840,
        school: 'MELKRIVIER PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.40246,
        old_latitude: -24.00794,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.998146, -24.962442],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606823,
        school: 'METSANANGWANA PRIMARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.99814595,
        old_latitude: -24.96244178,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.03941, -23.17996],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110600,
        school: 'MFAKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.03941,
        old_latitude: -23.17996,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.811323, -23.675868],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420390,
        school: 'MHITLWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.8113228,
        old_latitude: -23.67586787,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.672817, -23.390922],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410645,
        school: 'MHLANGANISWENI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.672817,
        old_latitude: -23.39092241,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.029887, -22.907437],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350835,
        school: 'MIKOSI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.02988694,
        old_latitude: -22.90743701,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.23625, -22.88773],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330282,
        school: 'MILABONI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.23625,
        old_latitude: -22.88773,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.95232206, 29.13263809],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220811,
        school: 'MILENTE SECONDARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.13263809,
        old_latitude: -23.95232206,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.64637, 30.63696],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901530822,
        school: 'Minloon Primary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63696,
        old_latitude: -23.64637,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.590909, -23.624134],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510460,
        school: 'MIRAGOMA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.59090901,
        old_latitude: -23.6241343,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.341994, -23.170511],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 993306400,
        school: 'MISSION PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34199401,
        old_latitude: -23.17051076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.961366, -22.804292],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331834,
        school: 'MITUMBA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96136602,
        old_latitude: -22.80429236,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.694221, -23.570842],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420406,
        school: 'MIZUZWANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.69422148,
        old_latitude: -23.57084156,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.954264, -24.147158],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121990,
        school: 'MMADIKANA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95426388,
        old_latitude: -24.14715782,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.153356, -23.688234],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250621,
        school: 'MMADITSHIKA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.15335603,
        old_latitude: -23.68823359,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.066907, -24.891666],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606308,
        school: 'MMAHLAGARE COMBINED SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.06690684,
        old_latitude: -24.89166587,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.733254, -24.688788],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641531,
        school: 'MMAKADIKWE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7332543,
        old_latitude: -24.68878782,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.444228, -24.206947],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540959,
        school: 'MMAKAU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.44422816,
        old_latitude: -24.20694707,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.314431, -23.714961],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220842,
        school: 'MMAKGABO SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.31443093,
        old_latitude: -23.71496111,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.97027076, 29.58607512],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651385,
        school: 'MMAKGATLE PRIMARY',
        status: 'School may have merged with another school',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.58607512,
        old_latitude: -24.97027076,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.065933, -23.267763],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920210999,
        school: 'MMAKGOMOTSHWEU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.06593333,
        old_latitude: -23.26776307,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.974646, -24.984114],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606825,
        school: 'MMAKOLA PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.97464643,
        old_latitude: -24.98411392,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.604912, -24.481598],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641555,
        school: 'MMAKOPI SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.60491155,
        old_latitude: -24.48159771,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.768348, -24.789915],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643209,
        school: 'MMAKUBU PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.7683481,
        old_latitude: -24.7899147,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.019515, -23.260302],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211015,
        school: 'MMALOTLO SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.01951547,
        old_latitude: -23.26030197,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.63310259, 29.40123859],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220859,
        school: 'MMAMAFA SENIOR SECONDARY',
        status: 'School is on a project list, but school has now closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.40123859,
        old_latitude: -23.63310259,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.94668, -23.63291],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250645,
        school: 'MMAMOLOPE SENIOR SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.94668,
        old_latitude: -23.63291,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.30657, 30.17373],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630838,
        school: 'MMANYABA SENIOR SECONDARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.17373,
        old_latitude: -24.30657,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.752964, -24.009423],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240822,
        school: 'MMAPADI SEN SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.75296408,
        old_latitude: -24.00942303,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.10699, -23.48926],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250652,
        school: 'MMASEHLONG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.10699,
        old_latitude: -23.48926,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.801998, -23.950553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121006,
        school: 'MMATEDU SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.80199757,
        old_latitude: -23.95055306,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.996661, -24.635907],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925660792,
        school: 'MMATHABENG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.99666066,
        old_latitude: -24.63590729,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.50175, -25.02506],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 924650344,
        school: 'MMATHOLO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.50175,
        old_latitude: -25.02506,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.36874, -23.68914],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915510569,
        school: 'MMATJATJI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36874,
        old_latitude: -23.68914,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.184863, -23.706199],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220903,
        school: 'MMATLOU PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.18486289,
        old_latitude: -23.70619864,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.769273, -24.788958],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643193,
        school: 'MMAUWANE SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.7692728,
        old_latitude: -24.78895806,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.13141, -23.39254],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111634,
        school: 'MMAY SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.13141,
        old_latitude: -23.39254,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.68867, -23.089281],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332080,
        school: 'MMBEREGENI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68867009,
        old_latitude: -23.08928101,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.779779, -24.881312],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641647,
        school: 'MMESHI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.77977914,
        old_latitude: -24.88131236,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.62904, -23.02732],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332288,
        school: 'MMILIGE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.62904,
        old_latitude: -23.02732,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.84541, -23.9341],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121020,
        school: 'MMOPA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.84541,
        old_latitude: -23.9341,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88861, -24.57951],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610483,
        school: 'MMOTONG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88861,
        old_latitude: -24.57951,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.79489, -25.01829],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650474,
        school: 'MNINWAMAHLANGU PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.79489,
        old_latitude: -25.01829,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.251609, -23.606472],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 922250676,
        school: 'MOCHEDI HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.25160936,
        old_latitude: -23.60647181,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.13579, -23.32409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110648,
        school: 'MOCHEKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.13579,
        old_latitude: -23.32409,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.231244, -24.834605],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 910130932,
        school: 'MODDERSPRUIT PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.23124421,
        old_latitude: -24.83460539,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.95816, -23.59492],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250683,
        school: 'MODIANYANA PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95816,
        old_latitude: -23.59492,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.79138, -23.767901],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240853,
        school: 'MODIBONE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.79138042,
        old_latitude: -23.76790093,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.32698, -23.53885],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901510620,
        school: 'MODIKA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.32698,
        old_latitude: -23.53885,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.76992, -24.31733],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906610780,
        school: 'MODIMOLLE PRIMARY-2',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76992,
        old_latitude: -24.31733,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.613547, -24.112971],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923240860,
        school: 'MOEFADI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.6135472,
        old_latitude: -24.11297062,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.743681, -24.440315],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924610377,
        school: 'MOENYANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.7436805,
        old_latitude: -24.44031521,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.91893, -24.63081],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610049,
        school: 'MOEPADIRA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.91893,
        old_latitude: -24.63081,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.9714, -23.65905],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251655,
        school: 'MOETAGARE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9714,
        old_latitude: -23.65905,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.787406, -23.731929],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240877,
        school: 'MOGABANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.78740607,
        old_latitude: -23.73192919,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.455785, -24.953413],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650924,
        school: 'MOGADIME SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4557849,
        old_latitude: -24.95341294,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.871165, -24.261738],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641685,
        school: 'MOGALATLADI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.87116499,
        old_latitude: -24.26173767,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.4348, -24.73315],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924652237,
        school: 'MOGALATSANA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4348,
        old_latitude: -24.73315,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.955181, -24.264066],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631503,
        school: 'MOGALE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95518101,
        old_latitude: -24.26406564,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.64693, -24.70049],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641692,
        school: 'MOGALETLWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.64693,
        old_latitude: -24.70049,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.56611, -25.05914],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650481,
        school: 'MOGANETSWA HIGHER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.56611,
        old_latitude: -25.05914,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.74261, -24.05563],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923240884,
        school: 'MOGANO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.74261,
        old_latitude: -24.05563,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.283261, -24.065465],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540997,
        school: 'MOGAPENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28326054,
        old_latitude: -24.06546451,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.984242, -24.791],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660815,
        school: 'MOGASHOA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.98424191,
        old_latitude: -24.79099974,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.439085, -24.537924],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230344,
        school: 'MOGOLOGOLO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.43908478,
        old_latitude: -24.53792371,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.284691, -24.345702],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230351,
        school: 'MOGOTO PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.28469108,
        old_latitude: -24.34570225,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.536595, -25.040882],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650276,
        school: 'MOGUDI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.53659548,
        old_latitude: -25.04088158,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.076764, -25.139445],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606618,
        school: 'MOHLABETSI SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.07676353,
        old_latitude: -25.13944488,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.100822, -23.141325],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211091,
        school: 'MOHLABI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10082205,
        old_latitude: -23.14132523,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.446694, -24.662868],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230368,
        school: 'MOHLAHLANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.44669405,
        old_latitude: -24.66286752,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.77552, -24.708385],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924644315,
        school: 'MOHLAHLEDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.77551954,
        old_latitude: -24.70838486,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.048639, -25.238435],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606611,
        school: 'MOHLAKO PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.04863851,
        old_latitude: -25.23843544,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.084012, -24.530416],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630524,
        school: 'MOHLALAMORUOI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.08401158,
        old_latitude: -24.53041608,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88512, -24.50095],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610193,
        school: 'MOHLALETSE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.88512,
        old_latitude: -24.50095,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.235117, -24.629564],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621421,
        school: 'MOHLARUTSE SECONDARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.23511684,
        old_latitude: -24.62956399,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.830006, -24.967409],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641746,
        school: 'MOHLODI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83000581,
        old_latitude: -24.96740924,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.189561, -24.02697],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541006,
        school: 'MOHLODUMELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.18956125,
        old_latitude: -24.02696977,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.298597, -24.397862],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230375,
        school: 'MOHLOPHENG SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.29859676,
        old_latitude: -24.39786223,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.104947, -24.303131],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630876,
        school: 'MOHLOPING PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.10494661,
        old_latitude: -24.30313137,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.823909, -24.369261],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610261,
        school: 'MOHLOTLWANE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.82390881,
        old_latitude: -24.36926073,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.865875, -24.573378],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610056,
        school: 'MOHWADUBA SENIOR SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.86587464,
        old_latitude: -24.57337805,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.353263, -23.647045],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220958,
        school: 'MOHWIBIDU PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.35326261,
        old_latitude: -23.64704457,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.531423, -25.038757],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651552,
        school: 'MOILANONG MATSEPE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.53142252,
        old_latitude: -25.03875651,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.224853, -24.498281],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620756,
        school: 'MOISELE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.22485309,
        old_latitude: -24.49828062,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.66996, -24.57415],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641777,
        school: 'MOKALAPA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66996,
        old_latitude: -24.57415,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.005695, -24.734146],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660839,
        school: 'MOKALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.00569458,
        old_latitude: -24.73414615,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.543276, -24.569638],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641791,
        school: 'MOKGAPI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.54327596,
        old_latitude: -24.56963806,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.303471, -23.745248],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922220972,
        school: 'MOKGOBA JUNIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.30347115,
        old_latitude: -23.74524789,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.450155, 29.40274],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230382,
        school: 'Mokgohlwe Makopo Secondary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.40274,
        old_latitude: -24.450155,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.767421, -24.564358],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925643186,
        school: 'MOKGOKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.76742079,
        old_latitude: -24.56435768,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.29722307, 29.65076976],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923260833,
        school: 'MOKGOROTLWANE SECONDARY',
        status: 'Project completed in 2018, but school is now closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65076976,
        old_latitude: -24.29722307,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.716791, -24.944743],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641814,
        school: 'MOKGOSHI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.71679057,
        old_latitude: -24.94474338,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.617569, -24.691146],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641821,
        school: 'MOKGUBI SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.61756857,
        old_latitude: -24.69114615,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.934538, -24.568254],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610209,
        school: 'MOKHINE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.93453845,
        old_latitude: -24.56825378,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.549352, -24.152497],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260840,
        school: 'MOKHOPO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.54935221,
        old_latitude: -24.15249732,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.44540608, 29.84026759],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610148,
        school: 'MOKHULWANE SECONDARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.84026759,
        old_latitude: -24.44540608,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.576148, -24.896708],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924651927,
        school: 'MOKOENEAMABULA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.57614792,
        old_latitude: -24.89670761,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.816325, -23.469009],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 919340418,
        school: 'MOKOMENE HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81632509,
        old_latitude: -23.46900887,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.427568, -24.338517],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230399,
        school: 'MOKONE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.42756762,
        old_latitude: -24.33851661,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.370524, -23.577197],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510699,
        school: 'MOKOPE SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.37052352,
        old_latitude: -23.57719745,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.378553, -23.562054],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510705,
        school: 'MOKOTO HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37855346,
        old_latitude: -23.56205436,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.020991, -23.264722],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 920211138,
        school: 'MOKUMURU PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.02099086,
        old_latitude: -23.26472222,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.598499, -24.581273],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620787,
        school: 'MOKUTUNG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.59849939,
        old_latitude: -24.58127299,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.287289, -23.636447],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511098,
        school: 'MOKWASELE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.28728854,
        old_latitude: -23.63644732,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.79571, -23.975174],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240907,
        school: 'MOKWATEDI SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.79570968,
        old_latitude: -23.97517386,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.295875, -23.978989],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530174,
        school: 'MOLABOSANE HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.29587473,
        old_latitude: -23.97898853,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.593518, -24.329013],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541020,
        school: 'MOLALANA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.59351849,
        old_latitude: -24.32901291,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.474399, -24.837607],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541037,
        school: 'MOLALATLADI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.47439859,
        old_latitude: -24.83760741,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.316028, -24.047343],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915541044,
        school: 'MOLATI PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31602761,
        old_latitude: -24.04734321,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.768863, -24.867717],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641838,
        school: 'MOLEIJANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7688628,
        old_latitude: -24.86771734,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.869118, -24.203891],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260871,
        school: 'MOLEKE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8691181,
        old_latitude: -24.20389054,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.061808, -24.553731],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630517,
        school: 'MOLEKWANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0618084,
        old_latitude: -24.55373073,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.708384, -23.486248],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340890,
        school: 'MOLEMOLE JUNIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70838359,
        old_latitude: -23.48624801,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.94548, -24.55235],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610605,
        school: 'MOLETSE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.94548,
        old_latitude: -24.55235,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.784916, -23.723909],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240945,
        school: 'MOLOISI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.78491573,
        old_latitude: -23.72390883,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.486795, -23.625921],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511111,
        school: 'MOLOKWANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.48679522,
        old_latitude: -23.62592076,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.671767, -24.574365],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641906,
        school: 'MOLOMOTOATAU SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.67176692,
        old_latitude: -24.57436459,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.116074, -24.208114],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260888,
        school: 'MOLOTOADI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.11607401,
        old_latitude: -24.20811448,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.538919, -24.769285],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641913,
        school: 'MOLWETSI SENIOR',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.53891933,
        old_latitude: -24.76928502,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.532753, -23.484415],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111481,
        school: 'MONALA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.53275263,
        old_latitude: -23.48441537,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.959144, -24.68685],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660204,
        school: 'MONAMOLEDI',
        status: 'School name has changed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.95914438,
        old_latitude: -24.68685018,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.884545, -24.798815],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924661641,
        school: 'MONAPANAPE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.88454523,
        old_latitude: -24.79881537,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.313768, 29.826964],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925611028,
        school: 'Monare Secondary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.826964,
        old_latitude: -24.313768,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.6548, -23.74675],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142225,
        school: 'MONENE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.6548,
        old_latitude: -23.74675,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.404481, -24.973444],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650146,
        school: 'MONTSOSABOSEGO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.40448083,
        old_latitude: -24.97344388,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.703912, -24.547422],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641937,
        school: 'MONYAKU SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70391154,
        old_latitude: -24.54742161,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.250983, -23.296027],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211152,
        school: 'MONYEMATHULA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.25098271,
        old_latitude: -23.296027,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.223499, -24.481802],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620800,
        school: 'MOOKOTSI PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.22349935,
        old_latitude: -24.48180158,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.603695, -24.801113],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924641944,
        school: 'MOOMANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.60369492,
        old_latitude: -24.80111315,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.367347, -25.039127],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606522,
        school: 'MOOSRIVIER PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.36734703,
        old_latitude: -25.03912736,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.129289, -23.951158],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221005,
        school: 'MORELEBAKGANYAGO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.12928894,
        old_latitude: -23.95115764,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.445053, -24.566961],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230429,
        school: 'MOREMOTSE SENIOR SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4450531,
        old_latitude: -24.56696069,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.889752, -24.772178],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660891,
        school: 'MORETSELE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8897522,
        old_latitude: -24.77217778,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.224615, -24.61493],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925620831,
        school: 'MOREWANE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.2246153,
        old_latitude: -24.6149299,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.788341, -23.940107],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240969,
        school: 'MORIA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.78834069,
        old_latitude: -23.94010704,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.230736, -25.298899],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606717,
        school: 'MOROATHEBE PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.23073632,
        old_latitude: -25.29889899,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.112293, -24.561389],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630531,
        school: 'MOROKADIETA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.11229307,
        old_latitude: -24.5613892,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.776222, -24.411643],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610285,
        school: 'MOROKALEBOLE SECONDARY',
        status: 'School is on SAFE project list, but listed as not proceeding',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7762224,
        old_latitude: -24.41164324,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.80081, -22.78677],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211183,
        school: 'MORONGWA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.8008102,
        old_latitude: -22.78676995,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.379527, -24.413461],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230436,
        school: 'MOROPA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.37952724,
        old_latitude: -24.41346127,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.608352, -24.949989],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905651132,
        school: 'MOROPE-MATLALA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.60835206,
        old_latitude: -24.94998872,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.53592, -24.397465],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260901,
        school: 'MOROTSE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.5359202,
        old_latitude: -24.39746533,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.012891, -23.314524],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110730,
        school: 'MORUKHURUKHUNG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.01289083,
        old_latitude: -23.31452449,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.328415, -23.680846],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221029,
        school: 'MORUPAHALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.32841461,
        old_latitude: -23.68084624,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.361781, -23.66373],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511135,
        school: 'MORUTSI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36178082,
        old_latitude: -23.66373009,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.524477, -24.589308],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620848,
        school: 'MORWAKGWADI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5244769,
        old_latitude: -24.58930839,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.113932, -23.941463],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221036,
        school: 'MORWASETHULA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.11393247,
        old_latitude: -23.94146312,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.945646, -24.287958],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631527,
        school: 'MORWASWI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.94564623,
        old_latitude: -24.28795761,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.721877, -23.807268],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923240990,
        school: 'MOSEBO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.72187665,
        old_latitude: -23.80726847,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.4905809, 28.9162838],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250737,
        school: 'MOSEHLENG PRIMARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9162838,
        old_latitude: -23.4905809,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.291034, -23.727051],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221043,
        school: 'MOSHASHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.29103363,
        old_latitude: -23.72705118,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.508532, -25.024939],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650221,
        school: 'MOSHATE SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.50853215,
        old_latitude: -25.02493924,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.62371, -24.89361],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651149,
        school: 'MOSHEGE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.62371,
        old_latitude: -24.89361,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.88655, -24.48189],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925260925,
        school: 'MOSHIANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88655,
        old_latitude: -24.48189,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.003591, -23.5851],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250744,
        school: 'MOSHIBI-MOKOBODI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.00359147,
        old_latitude: -23.58509965,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.94336, -24.051464],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121099,
        school: 'MOSHIBUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.94336019,
        old_latitude: -24.05146352,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.132291, -23.725883],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250584,
        school: 'MOSHIDI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.132291,
        old_latitude: -23.72588271,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.328164, -24.377587],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230443,
        school: 'MOSHODO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.32816444,
        old_latitude: -24.37758683,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.957684, -24.146063],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921121105,
        school: 'MOSHUPSA HIGHER PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.95768382,
        old_latitude: -24.14606338,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.448206, -24.210226],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540263,
        school: 'MOSIBUDI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44820635,
        old_latitude: -24.21022568,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.089694, -25.227228],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606621,
        school: 'MOTETI PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.08969357,
        old_latitude: -25.22722828,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.639844, -23.840248],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241023,
        school: 'MOTHIMAKO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.63984425,
        old_latitude: -23.84024766,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.935118, -23.586756],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250775,
        school: 'MOTJERE HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 28.93511805,
        old_latitude: -23.58675556,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.395184, -24.585671],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620855,
        school: 'MOTLAILANE JUNIOR SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.39518402,
        old_latitude: -24.58567091,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.88493928, 29.86096704],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241047,
        school: 'MOTLAKARO SECONDARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.86096704,
        old_latitude: -23.88493928,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.693435, -23.503674],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340333,
        school: 'MOTLALAOHLE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.69343535,
        old_latitude: -23.50367442,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.85507793, 29.76729752],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642060,
        school: 'MOTLANKANE PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76729752,
        old_latitude: -24.85507793,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.83914, -24.798201],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 907132211,
        school: 'MOTLHAPING PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.83913962,
        old_latitude: -24.79820098,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.880512, -23.216751],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 908110761,
        school: 'MOTLHASEDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.88051206,
        old_latitude: -23.21675069,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.81028, -24.712679],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642077,
        school: 'MOTLOKWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8102797,
        old_latitude: -24.71267922,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.450361, -24.696756],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643414,
        school: 'MOTSATSI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4503614,
        old_latitude: -24.69675616,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.777494, -24.708636],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642091,
        school: 'MOTSEMOGOLO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.77749371,
        old_latitude: -24.70863611,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.701899, -24.986228],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650504,
        school: 'MOTSEPHIRI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70189937,
        old_latitude: -24.98622842,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.482348, -24.422897],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921231064,
        school: 'MOTSERERENG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.48234772,
        old_latitude: -24.42289708,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.953193, -24.136111],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121129,
        school: 'MOTSHITSHI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95319331,
        old_latitude: -24.13611076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.105048, -23.954798],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221074,
        school: 'MOTSIRI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10504845,
        old_latitude: -23.95479849,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.384841, -24.397605],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230450,
        school: 'MOTSOFALA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.38484148,
        old_latitude: -24.39760534,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.14354, -23.356523],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340265,
        school: 'MOTSOKOTSA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.14354045,
        old_latitude: -23.35652296,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.667566, -24.888344],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642114,
        school: 'MOTSWALEDI SECONDARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66756552,
        old_latitude: -24.88834381,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.489892, -23.639095],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918530853,
        school: 'MPAPALATI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48989201,
        old_latitude: -23.63909473,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.53165104, 29.35086135],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905331728,
        school: 'MPATHAKA COMBINED',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.35086135,
        old_latitude: -22.53165104,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.049828, -24.42342],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631541,
        school: 'MPETJE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.04982761,
        old_latitude: -24.42341995,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.652153, -22.884726],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350071,
        school: 'MPFARISENI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65215307,
        old_latitude: -22.88472622,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.76674, -24.29479],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610704,
        school: 'MPHAANENG PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76674,
        old_latitude: -24.29479,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.226946, -23.163437],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321349,
        school: 'MPHAGANE JUNIOR PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.22694598,
        old_latitude: -23.16343686,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.42444, -24.92249],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924650290,
        school: 'MPHAHLELE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.42444,
        old_latitude: -24.92249,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.775711, -23.920858],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141871,
        school: 'MPHAKA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.77571124,
        old_latitude: -23.92085807,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.539092, -24.179033],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260499,
        school: 'MPHAKANYANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.53909231,
        old_latitude: -24.17903256,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.814842, -24.586408],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610506,
        school: 'MPHANAMA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81484228,
        old_latitude: -24.58640764,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.361732, -23.801215],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221081,
        school: 'MPHARENG JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.3617316,
        old_latitude: -23.80121549,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.562326, -22.864652],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350217,
        school: 'MPHATHELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.56232627,
        old_latitude: -22.86465161,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.47209, -24.667128],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642145,
        school: 'MPHEGOLLE SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.47208989,
        old_latitude: -24.66712845,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.701254, -23.02363],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211220,
        school: 'MPHELA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.70125351,
        old_latitude: -23.02362967,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.162613, -25.20164],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606620,
        school: 'MPHELENG PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.16261261,
        old_latitude: -25.20163974,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.735336, -25.028411],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650696,
        school: 'MPHEZULU SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.73533613,
        old_latitude: -25.02841144,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.484307, -22.875006],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350798,
        school: 'MPHIGALALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.48430734,
        old_latitude: -22.87500646,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.477905, -23.472517],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918510781,
        school: 'MPHIRI-O-TEE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.47790452,
        old_latitude: -23.47251673,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.104004, -23.073089],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211244,
        school: 'MPHOKANENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10400399,
        old_latitude: -23.07308907,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.490374, -23.234168],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320988,
        school: 'MPOFU COMBINED PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.49037374,
        old_latitude: -23.23416775,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.455597, -24.520247],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230924,
        school: 'MPOTLA SENIOR SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.4555973,
        old_latitude: -24.5202472,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.88754, -23.11028],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361415,
        school: 'MTITITI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.88754,
        old_latitude: -23.11028,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.616915, -22.722856],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310684,
        school: 'MUBALANGANYI SECONDARY',
        status: 'Project was on list in 2019 awaiting approval',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.61691525,
        old_latitude: -22.72285619,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.786557, -22.744642],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361132,
        school: 'MUBVUMONI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.78655656,
        old_latitude: -22.7446424,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.562521, -23.163625],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360689,
        school: 'MUDABULA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.56252149,
        old_latitude: -23.16362459,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.186691, -22.962183],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331957,
        school: 'MUDZINGA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.18669143,
        old_latitude: -22.96218321,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.193121, -23.247015],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931520702,
        school: 'MUFEBA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.19312089,
        old_latitude: -23.2470145,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.514608, -22.671582],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 939311182,
        school: 'MUFULWI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.51460841,
        old_latitude: -22.67158246,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.05814032, 30.37215751],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320612,
        school: 'MUGIVHI JUNIOR PRIMARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.37215751,
        old_latitude: -23.05814032,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.45466, -23.726759],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530235,
        school: 'MUGWAZENI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.4546603,
        old_latitude: -23.72675881,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.364618, -22.852354],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351296,
        school: 'MUHANELWA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36461803,
        old_latitude: -22.85235381,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.845367, -23.276177],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410669,
        school: 'MUHAWU SECONDARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.84536693,
        old_latitude: -23.27617655,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.25982, -23.338463],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520719,
        school: 'MUHUNGUTI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25981965,
        old_latitude: -23.33846287,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.982046, -23.362018],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340159,
        school: 'MUILA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.98204637,
        old_latitude: -23.36201775,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.28892, -22.828532],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929330176,
        school: 'MUILADI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28891985,
        old_latitude: -22.82853207,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.982321, -23.32514],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340142,
        school: 'MUISE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.98232088,
        old_latitude: -23.32513978,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.578176, -23.069295],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360696,
        school: 'MUKHOMI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.5781762,
        old_latitude: -23.0692954,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.575531, -22.863121],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351302,
        school: 'MUKULA HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5755308,
        old_latitude: -22.86312076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.410844, -22.898185],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350804,
        school: 'MUKUMBANI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.41084422,
        old_latitude: -22.89818463,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.768061, -22.468143],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310127,
        school: 'MUKUNUNDE PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.76806056,
        old_latitude: -22.46814345,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.595474, -23.118677],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360702,
        school: 'MULAMULA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.59547444,
        old_latitude: -23.11867681,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.29422, -23.04468],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320506,
        school: 'MULANGAPHUMA JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.29422,
        old_latitude: -23.04468,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.44613, -22.992251],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350385,
        school: 'MULEDANE JUNIOR PRIMARY',
        status: 'School is on SAFE project list, but listed as not proceeding',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.44612975,
        old_latitude: -22.9922508,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.047397, -23.306783],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931341138,
        school: 'MULIMA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.04739678,
        old_latitude: -23.30678326,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.07271, -22.92026],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330565,
        school: 'MUNGOMANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.07271,
        old_latitude: -22.92026,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.654749, -22.912922],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350408,
        school: 'MURAGA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65474924,
        old_latitude: -22.91292194,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.376455, -22.921207],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350811,
        school: 'MURANGONI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37645455,
        old_latitude: -22.92120697,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.075707, -22.777662],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331803,
        school: 'MUSEKWA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.07570707,
        old_latitude: -22.77766225,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.848092, -22.766318],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361149,
        school: 'MUSHIRU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.84809187,
        old_latitude: -22.76631775,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.904905, -23.602555],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410706,
        school: 'MUSHIYANI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.90490493,
        old_latitude: -23.60255485,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.310991, -23.951706],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530891,
        school: 'MUSHOTI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31099131,
        old_latitude: -23.95170591,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.352274, -23.048511],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320599,
        school: 'MUTANGWAMANUGU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.35227369,
        old_latitude: -23.04851147,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.611862, -22.857581],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350200,
        school: 'MUTAVHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.61186175,
        old_latitude: -22.8575808,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.916114, -22.473815],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310318,
        school: 'MUTELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.91611417,
        old_latitude: -22.47381518,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.39284, -22.989429],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320117,
        school: 'MUTENDE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.39284047,
        old_latitude: -22.98942929,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.443486, -23.037935],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320285,
        school: 'MUTHAMARO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44348623,
        old_latitude: -23.03793466,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.355039, -22.979556],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320049,
        school: 'MUTHUNDINNE PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.35503934,
        old_latitude: -22.97955618,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.995324, -22.883229],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330572,
        school: 'MUTITITI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.99532437,
        old_latitude: -22.88322853,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.639704, -24.307386],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923260956,
        school: 'MUTLE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.63970377,
        old_latitude: -24.30738626,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.228667, -23.191009],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520740,
        school: 'Mutsetweni Primary School',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.228629,
        old_latitude: -23.191008,
        quintile: '3',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.524315, -22.888239],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350828,
        school: 'MUTSHALINGANA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.52431494,
        old_latitude: -22.88823917,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.439033, -22.806298],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310757,
        school: 'MUTSHENZHENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.43903313,
        old_latitude: -22.80629835,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.396339, -23.026285],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321905,
        school: 'MUTSHIPISI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.396339,
        old_latitude: -23.02628517,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.437289, -22.808689],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310974,
        school: 'MUTSHUTSHU SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.43728904,
        old_latitude: -22.80868927,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.208097, -22.924557],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330206,
        school: 'MUTUWAFHETHU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.20809662,
        old_latitude: -22.92455711,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.588582, -23.064325],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332011,
        school: 'MUUNGADI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.58858236,
        old_latitude: -23.06432537,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.371329, -23.068724],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321899,
        school: 'MUUNGAMUNWE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37132897,
        old_latitude: -23.06872406,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.492113, -22.995406],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350378,
        school: 'MUVHI TSHIKOVHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49211328,
        old_latitude: -22.99540605,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.114471, -23.322951],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340111,
        school: 'MUWAWENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11447072,
        old_latitude: -23.32295118,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.45415, -22.988278],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351726,
        school: 'MVUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.45414985,
        old_latitude: -22.98827849,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.809627, -23.088988],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211268,
        school: 'MY DARLING SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.80962714,
        old_latitude: -23.08898796,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.319241, -23.603919],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 916420437,
        school: 'MZILELA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31924063,
        old_latitude: -23.60391882,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.674195, -25.0352],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651439,
        school: 'MZIMHLOPHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.67419495,
        old_latitude: -25.03519998,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.418627, 29.813088],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610292,
        school: 'NAKA MMAKGOMO Secondary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.813088,
        old_latitude: -24.418627,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.026312, -22.912265],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211275,
        school: 'NAKEDI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.02631219,
        old_latitude: -22.91226535,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.717934, -23.552083],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140908,
        school: 'NAKONKWETLOU SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.71793409,
        old_latitude: -23.55208307,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.24014, -25.33716],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606705,
        school: 'NALA SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.24014,
        old_latitude: -25.33716,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.520728, -24.878048],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909131164,
        school: 'NALETSANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 27.52072834,
        old_latitude: -24.87804758,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.02837, -23.269539],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 931341121,
        school: 'NAMADZAVHO SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.02837021,
        old_latitude: -23.26953947,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.161333, -23.4316],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919341919,
        school: 'NAMATSABO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.16133287,
        old_latitude: -23.43160006,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.82236, -24.97815],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642213,
        school: 'NAMUDI SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.82236,
        old_latitude: -24.97815,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.162711, -23.30277],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211282,
        school: 'NANEDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.16271094,
        old_latitude: -23.30277007,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.127758, -22.92155],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330589,
        school: 'NANGA PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12775784,
        old_latitude: -22.92154983,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.3551, -23.793247],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221111,
        school: 'NAPE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.35509995,
        old_latitude: -23.793247,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.141898, -23.885199],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221135,
        school: 'NAPO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.14189769,
        old_latitude: -23.88519928,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.454314, -24.284054],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540294,
        school: 'NAPSADI SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.45431363,
        old_latitude: -24.28405376,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.41144, -23.67437],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221142,
        school: 'NARE SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.41144,
        old_latitude: -23.67437,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.993145, -23.52452],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250799,
        school: 'NAREMOHLALERWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.99314464,
        old_latitude: -23.52451985,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.90712, -23.66065],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251754,
        school: 'NCHELELENG SENIOR SECONDARY',
        status:
          'Project completed in 2019, but also on project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.90712,
        old_latitude: -23.66065,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.426947, -23.060973],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320230,
        school: 'NDAEDZO SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42694707,
        old_latitude: -23.06097319,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.467797, -23.178348],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320919,
        school: 'NDAENI JUNIOR PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.46779718,
        old_latitude: -23.17834773,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.61907, -25.05237],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651293,
        school: 'NDENDEKA HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.61907,
        old_latitude: -25.05237,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.52728, -22.78728],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 929310837,
        school: 'NDIDIVHANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.52728,
        old_latitude: -22.78728,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.965353, -23.383263],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410744,
        school: 'NDINDANI-HLOMELA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.96535301,
        old_latitude: -23.38326255,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.232014, -23.202155],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520771,
        school: 'NDLHAVHEYA  PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.2320138,
        old_latitude: -23.2021545,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.654714, -23.578524],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420468,
        school: 'NDZHOVELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.65471369,
        old_latitude: -23.57852428,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.808235, -22.918425],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361255,
        school: 'NGHEZIMANI HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.80823476,
        old_latitude: -22.91842507,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.866204, -22.959779],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360023,
        school: 'NGHOMUNGHOMU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.86620406,
        old_latitude: -22.95977926,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.782323, -22.863191],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361828,
        school: 'NGULA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.78232295,
        old_latitude: -22.86319088,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.9793, -24.87526],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925660952,
        school: 'NGWAABE COMPREHENSIVE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.9793,
        old_latitude: -24.87526,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.34929, -23.71057],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221166,
        school: 'NGWAKO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.34928969,
        old_latitude: -23.71057007,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.641641, -24.306997],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923260994,
        school: 'NGWANA MOHUBE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.64164078,
        old_latitude: -24.3069972,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.378276, -24.372755],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230498,
        school: 'NGWANA MOKWENA KEKANA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.37827562,
        old_latitude: -24.37275461,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.395745, -24.292553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230504,
        school: 'NGWANABAHLALERWA SECONDARY',
        status: 'Construction was underway in 2019',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.39574523,
        old_latitude: -24.29255285,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.64847, -24.63026],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924642251,
        school: 'NGWANABEKANE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.64847,
        old_latitude: -24.63026,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.401925, -24.967934],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651866,
        school: 'NGWANAKWENA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.40192532,
        old_latitude: -24.96793442,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.93722, -23.51952],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251815,
        school: 'NGWANALLELA HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.93722,
        old_latitude: -23.51952,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.592097, -24.10003],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923060994,
        school: 'NGWANAMAGO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.59209666,
        old_latitude: -24.10002976,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398383, -24.235117],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540331,
        school: 'NGWANAMAKHUTSWE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.398383,
        old_latitude: -24.235117,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.81105, -24.43044],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925610162,
        school: 'NGWANAMALA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81105,
        old_latitude: -24.43044,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.681696, -24.816025],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642299,
        school: 'NGWANASENANA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68169604,
        old_latitude: -24.81602511,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.0967, -24.80066],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660983,
        school: 'NGWANATHEKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0967,
        old_latitude: -24.80066,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.995803, -24.878641],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925660990,
        school: 'NGWANATHULARE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.99580301,
        old_latitude: -24.8786409,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.431124, -22.938033],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351128,
        school: 'NGWENANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.43112392,
        old_latitude: -22.93803312,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.586725, -23.389359],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410805,
        school: 'NHLUVUKO HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.58672452,
        old_latitude: -23.38935858,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.239997, -23.212366],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520788,
        school: 'NJAKANJAKA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.23999651,
        old_latitude: -23.21236597,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.243043, -23.194051],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520795,
        school: 'NJHINGHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24304256,
        old_latitude: -23.1940513,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.04015, -25.248817],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606617,
        school: 'NKADIMENG PRIMARY SCHOOL',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.04015,
        old_latitude: -25.24881667,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.725494, -23.124537],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410812,
        school: 'NKAMI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.72549389,
        old_latitude: -23.12453657,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.706084, -22.940711],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360863,
        school: 'NKATINI HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.706084,
        old_latitude: -22.940711,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.111292, -23.410046],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340258,
        school: 'NKEI LOWER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.111292,
        old_latitude: -23.410046,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.71337, -23.72464],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141895,
        school: 'NKEKETHLALWA PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.71337,
        old_latitude: -23.72464,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.82959, -23.86429],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142461,
        school: 'NKGAKGAUTHA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.82959,
        old_latitude: -23.86429,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.354056, 29.295523],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230511,
        school: 'NKGALABELE Secondary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.295523,
        old_latitude: -24.354056,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.68352, -24.53484],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642312,
        school: 'NKGARI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68352,
        old_latitude: -24.53484,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.97113, -24.06934],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921121853,
        school: 'NKGODI SIPHUNGO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.97113,
        old_latitude: -24.06934,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.17533, -24.47437],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631206,
        school: 'NKGOMELENG SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.17533,
        old_latitude: -24.47437,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.73877, -24.63324],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642329,
        school: 'NKGONYELETSE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73877,
        old_latitude: -24.63324,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.082931, -23.67841],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251761,
        school: 'NKGOPOLENG BAKONE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.08293139,
        old_latitude: -23.67840978,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.62169, -23.88786],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140953,
        school: 'NKGORU HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.62169,
        old_latitude: -23.88786,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.899793, -22.757063],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360290,
        school: 'NKHAVI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.89979291,
        old_latitude: -22.75706258,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.182341, -23.108443],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905331124,
        school: 'NKHENSA JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.18234088,
        old_latitude: -23.10844283,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.59859, -23.55986],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140960,
        school: 'NKIDIKITLANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.59859,
        old_latitude: -23.55986,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.407218, -24.998311],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924651606,
        school: 'NKOANA WILLY MADITSI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.40721775,
        old_latitude: -24.99831083,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.39993172, 28.74151239],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921250829,
        school: 'NKOBO SECONDARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 28.74151239,
        old_latitude: -23.39993172,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.166563, -22.830553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330374,
        school: 'NKONENI SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.166563,
        old_latitude: -22.830553,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.61155, -23.8583],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921140977,
        school: 'NKONTLHA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.61155,
        old_latitude: -23.8583,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.27575, -24.49669],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621315,
        school: 'NKOTA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.27575,
        old_latitude: -24.49669,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.03235, -23.370683],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110839,
        school: 'NKU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.03235,
        old_latitude: -23.370683,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.680529, -23.926021],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921140984,
        school: 'NKUBE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.680529,
        old_latitude: -23.926021,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.552821, -23.213406],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911410850,
        school: 'NKURI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55282102,
        old_latitude: -23.21340643,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.789798, -24.405283],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610728,
        school: 'NKWANA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.78979796,
        old_latitude: -24.40528338,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.35602, -23.06095],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320759,
        school: 'NNDAMULELENI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.35602,
        old_latitude: -23.06095,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.190617, -22.872668],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330473,
        school: 'NNDITSHENI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.190617,
        old_latitude: -22.872668,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.334379, -23.058176],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320629,
        school: 'NNDWAMMBI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.33437859,
        old_latitude: -23.05817592,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.239, -22.86802],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330343,
        school: 'NNDWELWENI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.239,
        old_latitude: -22.86802,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.43312, -23.18056],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320957,
        school: 'NNGWEKHULU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.43312,
        old_latitude: -23.18056,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.38208, -23.34071],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521521,
        school: 'NOBLEHOEK SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38208,
        old_latitude: -23.34071,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.33888, -23.48727],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918520825,
        school: 'NOKANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.33888,
        old_latitude: -23.48727,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.057822, -24.184387],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261010,
        school: 'NOKOTLOU SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0578221,
        old_latitude: -24.18438655,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.902749, -23.668523],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250850,
        school: 'NONG MODIKOA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.902749,
        old_latitude: -23.66852325,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.95918, -24.84775],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661047,
        school: 'NTAKE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.95918,
        old_latitude: -24.84775,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.972395, -24.136615],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121198,
        school: 'NTATA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9723951,
        old_latitude: -24.13661517,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.222265, -24.56316],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620909,
        school: 'NTEPANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.22226479,
        old_latitude: -24.56316006,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.791897, -22.754269],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360986,
        school: 'NTEVEDZENI SECONDARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.79189702,
        old_latitude: -22.75426933,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.960622, -23.286602],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340104,
        school: 'NTHABALALA PRIMARY',
        status: 'Construction on project began in 2019',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.96062214,
        old_latitude: -23.28660176,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.090306, -23.567831],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250867,
        school: 'NTHEMA SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.09030575,
        old_latitude: -23.56783102,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.493609, -22.935529],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351418,
        school: 'NTHETSHELESENI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.49360854,
        old_latitude: -22.93552867,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.837748, -23.524827],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142010,
        school: 'NTHUBA HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.83774779,
        old_latitude: -23.52482669,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.205668, -24.46033],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620916,
        school: 'NTIBANENG SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.2056678,
        old_latitude: -24.46033046,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.511471, -23.432117],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918521293,
        school: 'NTLAGENE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.51147085,
        old_latitude: -23.43211747,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.88299, -22.914266],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361446,
        school: 'NTLHAVENI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.88299023,
        old_latitude: -22.91426608,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.210005, -23.31074],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211312,
        school: 'NTLHODUMELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.21000546,
        old_latitude: -23.31073963,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.730242, -22.800525],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360993,
        school: 'NTODENI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.73024198,
        old_latitude: -22.80052492,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.764965, -22.805482],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361088,
        school: 'NTSENDZENI SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.76496549,
        old_latitude: -22.80548198,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.05716, -24.77521],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661078,
        school: 'NTSHITSHIMALE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05716,
        old_latitude: -24.77521,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.558288, -24.975136],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651873,
        school: 'NTSHOENG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.55828828,
        old_latitude: -24.9751362,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.045922, -23.904924],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 917420482,
        school: 'NTSHUXEKO HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 31.04592187,
        old_latitude: -23.9049239,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.35167, -23.259839],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520832,
        school: 'NTSHUXI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.35167032,
        old_latitude: -23.25983898,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.004554, -22.980379],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211329,
        school: 'NTSOSE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.00455447,
        old_latitude: -22.98037908,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.58735, -22.94763],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350361,
        school: 'NTSUMBEDZENI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.58735,
        old_latitude: -22.94763,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.23474, -23.220257],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520849,
        school: 'NWA MHANDZI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.23474001,
        old_latitude: -23.22025698,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.872425, -23.577222],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411466,
        school: 'NWAKHADA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.8724252,
        old_latitude: -23.57722188,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.457251, -23.659354],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 915531382,
        school: 'NWAMUNGOLOLO PRIMARY',
        status: 'Project completed in 2018. School also on 2019 project list',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.45725129,
        old_latitude: -23.65935433,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.925924, -22.856624],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360917,
        school: 'NWANATI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.92592403,
        old_latitude: -22.8566236,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.457444, -22.654744],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311212,
        school: 'NWANEDI PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.4574438,
        old_latitude: -22.65474382,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.160447, -23.192434],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520856,
        school: 'NWAXINYANAMI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.16044667,
        old_latitude: -23.19243446,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.61505, -22.88138],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350651,
        school: 'NWELI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.61505,
        old_latitude: -22.88138,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.622393, -22.675216],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310721,
        school: 'NYADZANI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.62239269,
        old_latitude: -22.67521582,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.57097, -22.85395],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350194,
        school: 'NYAHANELANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.57097,
        old_latitude: -22.85395,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.380453, -23.959831],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530976,
        school: 'NYANTSHIRI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38045308,
        old_latitude: -23.95983076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.163257, -22.980345],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331049,
        school: 'NYATEMA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.16325662,
        old_latitude: -22.98034547,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.13733, -23.19771],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520863,
        school: 'NYELETI JUNIOR PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.13733,
        old_latitude: -23.19771,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.031305, -23.61804],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420512,
        school: 'NYIKO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 31.03130486,
        old_latitude: -23.61804011,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.692754, -23.569256],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420529,
        school: 'NYUMBANI HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.69275442,
        old_latitude: -23.56925558,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.45106, -23.10054],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321042,
        school: 'NZWELULE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.45106,
        old_latitude: -23.10054,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.369025, -23.802395],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221227,
        school: 'O.R. MABOTJA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.3690249,
        old_latitude: -23.80239453,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.360045, -23.156981],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905321592,
        school: 'ONGEDACHT SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36004489,
        old_latitude: -23.15698059,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.53202, -23.627058],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511210,
        school: 'OOGHOEK PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.53201992,
        old_latitude: -23.62705796,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.056742, -23.14362],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931331421,
        school: 'OZIAS DAVHANA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05674172,
        old_latitude: -23.14361982,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.009844, -24.606412],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925661399,
        school: 'PAAPA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.00984407,
        old_latitude: -24.60641224,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.894095, -24.931605],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642435,
        school: 'PATANTSHWANA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.89409491,
        old_latitude: -24.93160482,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.93777, -24.61861],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610513,
        school: 'PEBETSE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.93777,
        old_latitude: -24.61861,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.768452, -24.345857],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610735,
        school: 'PELANGWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.76845184,
        old_latitude: -24.3458568,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.14978, -23.34754],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919341107,
        school: 'PELO YA KGOMO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.14978,
        old_latitude: -23.34754,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.29513, -24.36886],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620947,
        school: 'PENGE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.29513,
        old_latitude: -24.36886,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.31104, -23.903021],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 915530259,
        school: 'PETANENGE JUNIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31104015,
        old_latitude: -23.90302096,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.757707, -24.868025],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642398,
        school: 'PETLOANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.75770711,
        old_latitude: -24.86802461,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.91493, -24.468014],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610216,
        school: 'PEU SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.91492994,
        old_latitude: -24.46801437,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.970841, -25.035384],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606801,
        school: 'PEZUNGA SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.97084097,
        old_latitude: -25.03538393,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.485619, -22.873952],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350866,
        school: 'PFANO PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48561857,
        old_latitude: -22.87395225,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.289227, -23.352308],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520900,
        school: 'PFUMELANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28922719,
        old_latitude: -23.35230791,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.85194, -23.189756],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916410898,
        school: 'PFUNANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.85194,
        old_latitude: -23.189756,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.284383, -24.60085],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925620954,
        school: 'PHAAHLA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.28438307,
        old_latitude: -24.60085028,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.05484, -24.39065],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925631589,
        school: 'PHAFANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.05484,
        old_latitude: -24.39065,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.101619, -23.304553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211350,
        school: 'PHAGAMANG SENIOR SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10161912,
        old_latitude: -23.30455292,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.613229, -24.985272],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651545,
        school: 'PHAKGAMANG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.61322851,
        old_latitude: -24.98527186,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.333723, -23.989754],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915530266,
        school: 'PHANGASASA SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.33372338,
        old_latitude: -23.98975379,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.47316, -22.94483],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351425,
        school: 'PHASWANA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.47316,
        old_latitude: -22.94483,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.75133, -24.864044],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924642428,
        school: 'PHATAMETSANE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.75132973,
        old_latitude: -24.86404398,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.77807, -24.75931],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642442,
        school: 'PHATLANE JUN SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.77807,
        old_latitude: -24.75931,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.150079, -23.502612],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250881,
        school: 'PHAUDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.15007934,
        old_latitude: -23.502612,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.05162, -23.454756],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340715,
        school: 'PHEEHA PRIMARY',
        status: 'Not on a project list. Project on hold',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05162039,
        old_latitude: -23.45475603,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.05037, -23.76235],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250898,
        school: 'PHEHLE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.05037,
        old_latitude: -23.76235,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.852873, -24.562679],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610520,
        school: 'PHEPANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.85287287,
        old_latitude: -24.5626785,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.150523, -23.455494],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340241,
        school: 'PHETOLE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1505233,
        old_latitude: -23.45549418,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.210849, -23.164666],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321486,
        school: 'PHINIMINI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.21084906,
        old_latitude: -23.16466641,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.40667, -22.95593],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351135,
        school: 'PHIPHIDI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.40667,
        old_latitude: -22.95593,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.875564, -24.831697],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642466,
        school: 'PHIRIAGAE SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.87556391,
        old_latitude: -24.83169686,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.925655, -23.806168],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250904,
        school: 'PHOFFU PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.92565483,
        old_latitude: -23.80616801,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.152707, -24.263315],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261065,
        school: 'PHOPHEDI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1527069,
        old_latitude: -24.26331494,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.813676, -24.814059],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642497,
        school: 'PHOTO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81367566,
        old_latitude: -24.81405872,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.77356, -24.48438],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925610384,
        school: 'PHUKUBJANE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.77355985,
        old_latitude: -24.48438015,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.69802, -24.055968],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923241160,
        school: 'PHUNYAKO HIGH',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.69801988,
        old_latitude: -24.05596774,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.861023, -24.461756],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610223,
        school: 'PHUTAKWE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8610229,
        old_latitude: -24.46175637,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.398033, -23.6589],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221302,
        school: 'PHUTI MAKIBELO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.398033,
        old_latitude: -23.6589,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.879494, -23.387889],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922211381,
        school: 'PHUTI MOKWENA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 28.87949431,
        old_latitude: -23.38788862,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.112159, -25.128939],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606605,
        school: 'PHUTIKWENA COMBINED SCHOOL',
        status: 'Project was on list in 2019 awaiting approval',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.11215872,
        old_latitude: -25.12893902,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.98799, -24.75369],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925661115,
        school: 'PHUTLOTAU SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.98799,
        old_latitude: -24.75369,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.37879, -24.44878],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230566,
        school: 'PIET N. APHANE HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.37879,
        old_latitude: -24.44878,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.469753, -22.776652],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311311,
        school: 'PILE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.46975338,
        old_latitude: -22.77665203,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.63481, -24.46387],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925620985,
        school: 'PITSANENG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63481,
        old_latitude: -24.46387,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.68241, -24.3067],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261102,
        school: 'PITSENG YA THUTO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68241,
        old_latitude: -24.3067,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.023572, -24.674183],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661122,
        school: 'PITSI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.02357235,
        old_latitude: -24.67418277,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.962193, -22.988919],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211398,
        school: 'POKANONG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.96219292,
        old_latitude: -22.98891882,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.268798, -23.953212],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915541112,
        school: 'PONANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.26879799,
        old_latitude: -23.95321212,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.796281, -23.142231],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211404,
        school: 'POTOKELA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.79628081,
        old_latitude: -23.14223102,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.13049, -23.395304],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110877,
        school: 'POULOS MOSIMA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.13048975,
        old_latitude: -23.39530375,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.01186, -23.616],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251822,
        school: 'PROSPECT PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.01186,
        old_latitude: -23.616,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.79761, -23.53935],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142706,
        school: 'PT MATLALA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.79761,
        old_latitude: -23.53935,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.066874, -23.784763],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250942,
        school: 'PUDUTJE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.06687359,
        old_latitude: -23.78476277,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.98038, -23.80365],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250959,
        school: 'PULA SEOPA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.98038,
        old_latitude: -23.80365,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.721132, -23.484386],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340913,
        school: 'PULEDI LOWER AND HIGHER PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.72113178,
        old_latitude: -23.48438576,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.097361, -22.876111],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330671,
        school: 'RABALI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.09736092,
        old_latitude: -22.87611074,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.752406, -23.85516],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142027,
        school: 'RABASOTHO PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.75240645,
        old_latitude: -23.85516006,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.11105, -24.43944],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909131324,
        school: 'RABUGALE PUBLIC PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.11105,
        old_latitude: -24.43944,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.026066, -23.716333],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250966,
        school: 'RACHEBOLE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.02606643,
        old_latitude: -23.71633333,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.93439, -23.24402],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921110891,
        school: 'RADIBAKI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 27.93439,
        old_latitude: -23.24402,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.591223, -24.102207],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261119,
        school: 'RADIKGOMO SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.59122334,
        old_latitude: -24.1022066,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.857853, -24.569087],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610537,
        school: 'RADIMELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.85785309,
        old_latitude: -24.56908667,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.262781, -23.192454],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321578,
        school: 'RADZAMBO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.26278056,
        old_latitude: -23.19245424,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.18561, -22.97349],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330725,
        school: 'RADZILANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.18561,
        old_latitude: -22.97349,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.168983, -25.157783],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606622,
        school: 'RAHLAGANE PRIMARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.16898333,
        old_latitude: -25.15778333,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.36069, -24.35041],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230573,
        school: 'RAKGOATHA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.36069,
        old_latitude: -24.35041,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.512505, -24.30753],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540379,
        school: 'RAKGOLOKWANA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.51250506,
        old_latitude: -24.30753047,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.606008, -23.734125],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141079,
        school: 'RAKUDUBANE SECONDARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.60600824,
        old_latitude: -23.73412474,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.33969, -23.729421],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922222626,
        school: 'RALEMA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.33968981,
        old_latitude: -23.7294208,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.47519, -22.94474],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351432,
        school: 'RALUSWIELO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.47519,
        old_latitude: -22.94474,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.29806, -23.04214],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320414,
        school: 'RALUTHAGA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.29806019,
        old_latitude: -23.04213965,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.570175, -22.808801],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351319,
        school: 'RALUVHIMBA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.57017483,
        old_latitude: -22.80880072,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.761982, -23.749588],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241214,
        school: 'RAMABU HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76198223,
        old_latitude: -23.7495878,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.321714, -24.34818],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230597,
        school: 'RAMAKGOTHO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.32171402,
        old_latitude: -24.34817982,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.980397, -23.176572],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906111771,
        school: 'RAMAKWAPENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.9803966,
        old_latitude: -23.17657169,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.046031, -23.714198],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250973,
        school: 'RAMALAPA PRIMARY',
        status: 'School is on SAFE project list, but listed as not proceeding',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.04603076,
        old_latitude: -23.714198,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.625952, 30.148416],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 902331940,
        school: 'Ramana Farm Primary',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.148416,
        old_latitude: -22.625952,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.40639, -23.49701],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918510880,
        school: 'RAMAOLWANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.40639,
        old_latitude: -23.49701,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.01985, -23.12758],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211466,
        school: 'RAMARULA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.01985,
        old_latitude: -23.12758,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.70301, -23.36929],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921250980,
        school: 'RAMASHILO PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.70301,
        old_latitude: -23.36929,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.08328, -23.21769],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211473,
        school: 'RAMATEMA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.08328,
        old_latitude: -23.21769,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.230888, -24.27983],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261133,
        school: 'RAMATSEDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.2308884,
        old_latitude: -24.27982992,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.45204, -22.78709],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311588,
        school: 'RAMBUDA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.45204,
        old_latitude: -22.78709,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.155249, -23.612142],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922250997,
        school: 'RAMETLOANA LOWER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.15524931,
        old_latitude: -23.61214205,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.50622, -25.05445],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650351,
        school: 'RAMMUPUDU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.50622,
        old_latitude: -25.05445,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.08143, -23.2171],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211480,
        school: 'RAMMUTLA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.08143,
        old_latitude: -23.2171,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.202812, -24.013308],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540393,
        school: 'RAMOBA HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.20281204,
        old_latitude: -24.0133085,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.404092, -23.709902],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221357,
        school: 'RAMOGOBE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.40409154,
        old_latitude: -23.70990208,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.713966, -23.220572],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211497,
        school: 'RAMOHLAKANA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.71396626,
        old_latitude: -23.22057209,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.939844, -24.145795],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921121327,
        school: 'RAMOKGABUDI SECONDARY',
        status:
          'Project completed in 2018, but also on project list to start from 2021',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.93984413,
        old_latitude: -24.1457954,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.65953, -23.87693],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141109,
        school: 'RAMOKGAKGELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.65953,
        old_latitude: -23.87693,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.85614, -23.246046],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211503,
        school: 'RAMOKGETHI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.85614,
        old_latitude: -23.246046,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.81685, -23.46966],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340708,
        school: 'RAMOKGOPA HIGH PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.81685,
        old_latitude: -23.46966,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.103817, -25.258633],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606615,
        school: 'RAMOKHUTLWANE PRIMARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10381667,
        old_latitude: -25.25863333,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.40459, -23.42346],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918520924,
        school: 'RAMOLLO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.40459,
        old_latitude: -23.42346,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.59084, -23.98519],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141116,
        school: 'RAMOLLWANE SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.59084,
        old_latitude: -23.98519,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.277494, -24.358031],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230610,
        school: 'RAMOLOKOANE PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.27749353,
        old_latitude: -24.35803148,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.394425, -23.731042],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221364,
        school: 'RAMONGWANA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.39442516,
        old_latitude: -23.73104197,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.76785, -23.86546],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241238,
        school: 'RAMOSHOBOHLE HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76785,
        old_latitude: -23.86546,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.65309, -23.79543],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921142287,
        school: 'RAMOTLHATSWANE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.65309,
        old_latitude: -23.79543,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.755389, -24.007346],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923241252,
        school: 'RAMPHERI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.75538924,
        old_latitude: -24.00734643,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.705996, -23.506117],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340340,
        school: 'RAMPO HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.70599559,
        old_latitude: -23.50611721,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.494024, -22.907069],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351517,
        school: 'RAMUGONDO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49402377,
        old_latitude: -22.90706893,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.483972, -24.226959],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541143,
        school: 'RANKAGALE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48397166,
        old_latitude: -24.22695895,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.485246, -23.588352],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111726,
        school: 'RANOKO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.4852458,
        old_latitude: -23.5883521,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.712216, -24.783939],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642565,
        school: 'RANONG PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.71221625,
        old_latitude: -24.78393885,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.611627, -23.963019],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141154,
        school: 'RANTJIE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.61162699,
        old_latitude: -23.96301855,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.073302, -23.875202],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221371,
        school: 'RANTSHU PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.0733018,
        old_latitude: -23.87520194,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.02864, -23.15217],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211541,
        school: 'RAPHATLHA SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.02864,
        old_latitude: -23.15217,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.76342, -22.806273],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211565,
        school: 'RAPHOTOLOLO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.76342005,
        old_latitude: -22.80627328,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.108959, -23.591472],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251013,
        school: 'RAPITSI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.10895886,
        old_latitude: -23.5914725,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.812118, -23.726094],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904242354,
        school: 'RARANG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81211825,
        old_latitude: -23.72609352,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.95741, -23.13216],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211589,
        school: 'RASEASALA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.95741,
        old_latitude: -23.13216,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.4536, -23.9123],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920213168,
        school: 'RASEKGALA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4536,
        old_latitude: -23.9123,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.14562, -23.33208],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 992201301,
        school: 'RASELAELO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.14562,
        old_latitude: -23.33208,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.078444, -23.635364],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251020,
        school: 'RASELETE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.07844426,
        old_latitude: -23.63536434,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.90109, -23.23264],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111443,
        school: 'RASERITE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 27.90109,
        old_latitude: -23.23264,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.943769, -23.369075],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 993303202,
        school: 'RASILA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.94376878,
        old_latitude: -23.36907513,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.378963, -23.096846],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321004,
        school: 'RASIVHETSHELE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.37896281,
        old_latitude: -23.0968461,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.06828, -24.58458],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630609,
        school: 'RATAU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.06828,
        old_latitude: -24.58458,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.80001, -23.460077],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340647,
        school: 'RATHOMA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.80001007,
        old_latitude: -23.46007666,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.82864, -23.4838],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142270,
        school: 'RATINKE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.82864,
        old_latitude: -23.4838,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.48552, -22.58105],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310424,
        school: 'RATSHIBVUMO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48552,
        old_latitude: -22.58105,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.394317, -22.976176],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321783,
        school: 'RATSHIEDANA SENIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.39431713,
        old_latitude: -22.97617601,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.61158, -22.47369],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310387,
        school: 'RATSHISASE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.61158,
        old_latitude: -22.47369,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.408921, -22.947446],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351142,
        school: 'RATSHITANGA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.40892099,
        old_latitude: -22.94744645,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.112516, -22.971123],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331452,
        school: 'RAVHUHALI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11251553,
        old_latitude: -22.97112299,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.56089, -22.78314],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310899,
        school: 'RAVHURA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.56089,
        old_latitude: -22.78314,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.58674, -23.97989],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141161,
        school: 'RAWESHI LOWER AND HIGHER PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.58674,
        old_latitude: -23.97989,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.61276, -23.42865],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 906111696,
        school: 'REABILWE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.61276,
        old_latitude: -23.42865,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.05705, -24.22777],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 907111832,
        school: 'REAHLAHLWA PRIMARY',
        status: 'Project completed in 2019',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.05705,
        old_latitude: -24.22777,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.334044, -23.487115],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918521323,
        school: 'REALEDISHA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3340438,
        old_latitude: -23.48711536,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.27297, -24.25206],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909131423,
        school: 'REENPAN PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'A',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.27297,
        old_latitude: -24.25206,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.757426, -25.114197],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606527,
        school: 'REHLAHLILWE PRIMARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.75742611,
        old_latitude: -25.11419699,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.989874, -24.932806],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 996606815,
        school: 'REKHUDITSE SECONDARY SCHOOL',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.98987405,
        old_latitude: -24.93280635,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.506069, -24.469151],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261812,
        school: 'REKHUTJITJE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.50606923,
        old_latitude: -24.46915126,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.244354, -23.299995],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931520948,
        school: 'REMBULUWANI JUNIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.24435434,
        old_latitude: -23.29999485,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.061971, -24.983102],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606824,
        school: 'REPHAFOGILE SECONDARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.06197095,
        old_latitude: -24.98310244,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.311859, -24.08378],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915541167,
        school: 'RHULANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31185929,
        old_latitude: -24.08378043,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.053647, -23.23261],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931520955,
        school: 'RIVERPLAATS PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.05364729,
        old_latitude: -23.23260997,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.292336, -23.281628],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520962,
        school: 'RIVONINGO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.2923358,
        old_latitude: -23.28162765,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.177819, -23.106872],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905332516,
        school: 'RIVUBYE HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.1778194,
        old_latitude: -23.1068723,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.564934, -22.705606],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311342,
        school: 'ROBERT MBULUNGENI SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5649343,
        old_latitude: -22.70560581,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.987989, -23.495021],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251037,
        school: 'ROSENKRANTZ PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.98798879,
        old_latitude: -23.49502088,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.323879, -24.333898],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621032,
        school: 'ROTOLE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.32387936,
        old_latitude: -24.33389824,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.67119, -24.47165],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924623704,
        school: 'S. M. NKOANA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.67119,
        old_latitude: -24.47165,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.764314, -23.298684],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922211619,
        school: 'SADU LOWER AND HIGHER PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.76431375,
        old_latitude: -23.2986839,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.653637, -22.739676],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310820,
        school: 'SAMBANDOU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65363727,
        old_latitude: -22.73967587,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.337466, -23.700592],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511319,
        school: 'SARA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.33746612,
        old_latitude: -23.70059165,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.80688, -24.234309],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261164,
        school: 'SCHELDING PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.80687966,
        old_latitude: -24.2343088,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.087557, -22.680293],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 902331667,
        school: 'SCHUITDRIFT INTERMEDIATE',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.08755674,
        old_latitude: -22.6802927,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.147418, -24.266489],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261171,
        school: 'SEALANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.14741766,
        old_latitude: -24.26648883,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.69994, -23.76455],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241290,
        school: 'SEBAYENG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.69994,
        old_latitude: -23.76455,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.170165, -23.378584],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340227,
        school: 'SEBELAOLO PRIMARY',
        status: 'Project was on list in 2019 awaiting approval',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.17016455,
        old_latitude: -23.37858407,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.41368, -24.541106],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230658,
        school: 'SEBITJA SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.41367973,
        old_latitude: -24.54110562,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.96703, -24.66731],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661177,
        school: 'SEBOENG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.96703,
        old_latitude: -24.66731,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.331414, -24.183549],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904230665,
        school: 'SEBOTSI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.33141435,
        old_latitude: -24.1835486,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.198687, -23.907867],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221425,
        school: 'Sebushi Primary',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.198685,
        old_latitude: -23.907875,
        quintile: '2',
        no_fee: 'Yes',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.422009, -24.542743],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925621049,
        school: 'SEDIBENG SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42200854,
        old_latitude: -24.54274305,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.883158, -24.856871],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642640,
        school: 'SEDIKWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88315846,
        old_latitude: -24.85687077,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.246874, -23.597727],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251051,
        school: 'SEETLA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.24687441,
        old_latitude: -23.59772682,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.233379, -23.658663],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511326,
        school: 'SEFAHONE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.23337907,
        old_latitude: -23.65866334,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.031617, -23.151649],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211640,
        school: 'SEFIHLAMPYANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.03161652,
        old_latitude: -23.15164931,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.979249, -23.129483],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111016,
        school: 'SEFITLHOGO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.97924877,
        old_latitude: -23.12948303,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.706723, -23.13157],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211657,
        school: 'SEFOTWANE PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.70672304,
        old_latitude: -23.13156952,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.313415, -24.452503],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230689,
        school: 'SEGAENG SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.31341453,
        old_latitude: -24.45250307,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.529691, -24.135454],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923621056,
        school: 'SEGASHIFE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.52969136,
        old_latitude: -24.13545381,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.736133, -23.689069],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142195,
        school: 'SEGOBOKO SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.73613312,
        old_latitude: -23.68906867,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.346568, -23.670005],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221456,
        school: 'SEHLAGANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.34656849,
        old_latitude: -23.67000452,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.773625, -25.172192],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606529,
        school: 'SEHLAKWANA PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.77362459,
        old_latitude: -25.17219243,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.803048, -23.999656],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241313,
        school: 'SEHLALE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.80304764,
        old_latitude: -23.99965648,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.29104, -24.51643],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925621070,
        school: 'SEHLOI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.29104,
        old_latitude: -24.51643,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.575066, -24.106978],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261201,
        school: 'SEHLOLA HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.57506576,
        old_latitude: -24.10697771,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.709469, -23.071865],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211664,
        school: 'SEIKGONI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.70946876,
        old_latitude: -23.07186549,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.027589, -23.877384],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221470,
        school: 'SEIPONE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.02758861,
        old_latitude: -23.87738387,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.209084, -24.626925],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925621094,
        school: 'SEKAKATE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.20908404,
        old_latitude: -24.62692534,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.607786, -23.832419],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921141857,
        school: 'SEKANEKAMOYI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.60778594,
        old_latitude: -23.8324189,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.536298, -24.389496],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261218,
        school: 'SEKATE  SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.53629849,
        old_latitude: -24.38949605,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.753629, -24.852785],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 907131522,
        school: 'SEKATEKATE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.75362912,
        old_latitude: -24.85278456,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.104637, -23.72211],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251075,
        school: 'SEKATI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.10463704,
        old_latitude: -23.72211011,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.435858, -24.2659],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926540530,
        school: 'SEKGALABJANA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.43585818,
        old_latitude: -24.26589998,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.96192, -23.640941],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 901340654,
        school: 'SEKGOPO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.96192,
        old_latitude: -23.64094101,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.336132, -23.581626],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918510934,
        school: 'SEKGWARE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.33613151,
        old_latitude: -23.58162555,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.047997, -23.739841],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251082,
        school: 'SEKGWARI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.04799705,
        old_latitude: -23.73984131,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.674241, -24.089121],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241337,
        school: 'SEKGWENG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.67424103,
        old_latitude: -24.08912076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.063864, -23.1395],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211701,
        school: 'SEKHWIBITSANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.06386423,
        old_latitude: -23.13950023,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.607935, -24.476403],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621100,
        school: 'SEKIBIDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.60793464,
        old_latitude: -24.47640278,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.870455, -23.996825],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'Yes',
        NatEmis: 921121389,
        school: 'SEKOBA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.87045481,
        old_latitude: -23.99682537,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.699342, -24.333062],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541631,
        school: 'SEKOKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.69934237,
        old_latitude: -24.33306239,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.212769, -23.457336],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922211718,
        school: 'SEKURUWE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.21276926,
        old_latitude: -23.45733611,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.342201, -24.318467],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230702,
        school: 'SEKUTUPU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.34220067,
        old_latitude: -24.31846667,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.874051, -24.830279],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642718,
        school: 'SEKWENA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.87405068,
        old_latitude: -24.8302785,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.766678, -24.296283],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610315,
        school: 'SELEBALO SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76667754,
        old_latitude: -24.2962831,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.019059, -22.9165],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211732,
        school: 'SELELO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.01905928,
        old_latitude: -22.9165,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.923363, -24.348234],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630296,
        school: 'SELEPE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.92336263,
        old_latitude: -24.34823378,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.8008425, 28.78388456],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141215,
        school: 'SELWABOSEGO PRIMARY',
        status: 'School may have merged with another school',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.78388456,
        old_latitude: -23.8008425,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.417404, -23.545904],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511357,
        school: 'SENAKWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.41740394,
        old_latitude: -23.54590402,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.971987, -24.845092],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661221,
        school: 'SENGANGE SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.97198686,
        old_latitude: -24.84509216,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.38295, -23.561417],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511364,
        school: 'SENOPELWA PRIMARY',
        status: 'Project reached "Close Out" status in 2019',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38295045,
        old_latitude: -23.561417,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.154662, -23.410141],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340777,
        school: 'SENWAMOKGOPE SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.15466198,
        old_latitude: -23.4101408,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.628701, -23.76865],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921142773,
        school: 'SENWANE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.62870135,
        old_latitude: -23.76865002,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.915395, -23.489325],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251136,
        school: 'SEO SECONDARY',
        status: 'School is on SAFE project list, but listed as not proceeding',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.91539483,
        old_latitude: -23.48932459,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.091607, -23.908043],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221494,
        school: 'SEOKA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.09160719,
        old_latitude: -23.9080431,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.131703, -23.683603],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251143,
        school: 'SEOKENG SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.13170326,
        old_latitude: -23.68360314,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.839281, -23.833955],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 904241368,
        school: 'SEOLWANA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.83928097,
        old_latitude: -23.83395484,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.929405, -24.085222],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121396,
        school: 'SEPEDI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.92940548,
        old_latitude: -24.08522179,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.813554, -23.453356],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340630,
        school: 'SEPHALA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.81355361,
        old_latitude: -23.45335556,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.760705, -23.584705],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142256,
        school: 'SEPOBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.7607051,
        old_latitude: -23.58470466,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.68637998, 30.05411674],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661245,
        school: 'SERAGENG PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.05411674,
        old_latitude: -24.68637998,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.948849, -24.649397],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661252,
        school: 'SERAKI SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.94884873,
        old_latitude: -24.64939686,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.930972, -24.505941],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610636,
        school: 'SEROKA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.93097191,
        old_latitude: -24.50594087,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.54495, -24.253204],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261256,
        school: 'SEROKOLOSENYANE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.54495008,
        old_latitude: -24.25320367,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.506612, -23.563388],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141246,
        school: 'SERUPA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.50661173,
        old_latitude: -23.56338754,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.30692, -24.37764],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230733,
        school: 'SERUTLE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.30692,
        old_latitude: -24.37764,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.922772, -23.144169],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211800,
        school: 'SESALONG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.92277242,
        old_latitude: -23.14416944,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.952056, -23.323163],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922211817,
        school: 'SESHANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.95205635,
        old_latitude: -23.32316342,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.344515, -23.672662],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511388,
        school: 'SETHONG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34451531,
        old_latitude: -23.67266229,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.775367, -23.14445],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211824,
        school: 'SETHUNYA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.77536722,
        old_latitude: -23.14444964,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.13639, -23.717399],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251150,
        school: 'SETLHAKO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.13638967,
        old_latitude: -23.71739861,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.455188, -23.537266],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511395,
        school: 'SETLOUMANE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.45518839,
        old_latitude: -23.53726601,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.252238, -23.35205],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912361606,
        school: 'SETSEKANA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25223792,
        old_latitude: -23.35204988,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.55682, -22.616685],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311229,
        school: 'SHAKADZA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55682,
        old_latitude: -22.61668541,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.328584, -23.193536],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321356,
        school: 'SHANDUKANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.32858438,
        old_latitude: -23.19353605,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.743729, -22.957799],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361347,
        school: 'SHIBANGWA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.74372858,
        old_latitude: -22.95779874,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.852145, -23.094444],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360054,
        school: 'SHIGOMBE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.85214538,
        old_latitude: -23.09444413,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.806351, -23.66097],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420697,
        school: 'SHIHLOMULE HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.8063505,
        old_latitude: -23.66096991,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.231539, -23.941299],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915541457,
        school: 'SHIKHATI JUNIOR PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.2315389,
        old_latitude: -23.94129874,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.813208, -22.841569],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360931,
        school: 'SHIKUNDU HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.81323,
        old_latitude: -22.841478,
        quintile: '2',
        no_fee: 'Yes',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.788312, -22.977882],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360085,
        school: 'SHILUME PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.78831205,
        old_latitude: -22.97788205,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.715061, -22.927385],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361330,
        school: 'SHIMAMBANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.71506065,
        old_latitude: -22.92738516,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.648947, -23.006716],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360825,
        school: 'SHIRILELE SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.64894722,
        old_latitude: -23.00671629,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.830521, -23.082062],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361408,
        school: 'SHITLHANGOMA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.83052118,
        old_latitude: -23.08206229,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.68163, -23.024345],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361323,
        school: 'SHITLHELANI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.68163014,
        old_latitude: -23.0243452,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-23.01712917, 30.36051984],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320681,
        school: 'SHONDONI SECONDARY',
        status: 'School is on a project list, but school has now closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.36051984,
        old_latitude: -23.01712917,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.925536, -24.871548],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925361276,
        school: 'SHORWANE SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.92553607,
        old_latitude: -24.87154768,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.159944, -22.880611],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330183,
        school: 'SHURA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.15994352,
        old_latitude: -22.88061122,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.714505, -22.370147],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 929310264,
        school: 'SIGONDE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.71450544,
        old_latitude: -22.37014701,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.931528, -22.86968],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331766,
        school: 'SIKHWIVHILU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.93152826,
        old_latitude: -22.86968012,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.190267, -22.896268],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330329,
        school: 'SILOAM PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.19026667,
        old_latitude: -22.89626764,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.124295, -23.202182],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520986,
        school: 'SITHUMANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12429493,
        old_latitude: -23.20218181,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.456688, -23.091728],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321721,
        school: 'SIWADAWADA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.45668769,
        old_latitude: -23.09172775,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.660731, -23.300282],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411044,
        school: 'SIYANDANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.66073081,
        old_latitude: -23.30028151,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.709341, -25.341135],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606507,
        school: 'SIZANI COMBINED SCHOOL',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70934084,
        old_latitude: -25.34113453,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.17333, -23.18981],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912520993,
        school: 'SKHOSANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.17333026,
        old_latitude: -23.18980995,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.762591, -25.164504],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606528,
        school: 'SKHOSANA SECONDARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7625915,
        old_latitude: -25.16450442,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.667186, -23.50533],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340944,
        school: 'SOKA LEHOLO HIGHER PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66718618,
        old_latitude: -23.50532998,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.656924, -25.059643],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650559,
        school: 'SOKALI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.65692409,
        old_latitude: -25.05964317,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.892103, -23.314004],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411051,
        school: 'SOLANI PRIMARY',
        status: 'Construction is underway',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.8921033,
        old_latitude: -23.31400374,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.125609, -22.993479],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331384,
        school: 'SOLOMON MAELULA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12560938,
        old_latitude: -22.99347938,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.689126, -23.774957],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241405,
        school: 'SOLOMONDALE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68912587,
        old_latitude: -23.7749569,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.811159, -24.996197],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650566,
        school: 'SOMAKATA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.81115886,
        old_latitude: -24.9961969,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.97826, -24.073701],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121433,
        school: 'SOMAVUGHA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.97826027,
        old_latitude: -24.07370062,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.596569, -25.012094],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650740,
        school: "ST. PAUL'S HIGH",
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.59656944,
        old_latitude: -25.01209395,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.75824, -22.657359],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310707,
        school: 'SUMBANA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.75824006,
        old_latitude: -22.65735931,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.68712, -23.81699],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921142331,
        school: 'SUPI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.68712,
        old_latitude: -23.81699,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.685673, -23.885165],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 921142249,
        school: 'SUSWE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.6856735,
        old_latitude: -23.88516497,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.91719, -24.842698],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642824,
        school: 'SWIKE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 29.91718987,
        old_latitude: -24.84269754,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.719883, -23.091572],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332332,
        school: 'SWOBANI SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.71988257,
        old_latitude: -23.09157243,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.826983, -23.533979],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340616,
        school: 'TABUDI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8269832,
        old_latitude: -23.53397867,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.250313, -25.308689],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606720,
        school: 'TAGANE PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.25031267,
        old_latitude: -25.30868917,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.588488, -22.859453],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350170,
        school: 'TAKALANI PRIMARY-2',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.58848772,
        old_latitude: -22.85945307,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.803655, -23.30836],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411075,
        school: 'TANANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.8036547,
        old_latitude: -23.30835993,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.875301, -23.005159],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905331063,
        school: 'TANGANEDZWA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.87530069,
        old_latitude: -23.00515924,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.062055, -23.711702],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251167,
        school: 'TAU-KWENA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.06205532,
        old_latitude: -23.71170177,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.919993, -24.95409],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 996606813,
        school: 'TAU-TLOU PRIMARY SCHOOL',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.91999261,
        old_latitude: -24.95409012,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.552957, -23.520234],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511425,
        school: 'TAULOME PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.55295694,
        old_latitude: -23.52023367,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.049801, -24.432763],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 925630654,
        school: 'TEKANANG SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0498013,
        old_latitude: -24.4327626,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.041645, -24.435837],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630661,
        school: 'TELEKI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.04164464,
        old_latitude: -24.435837,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.573143, 29.887845],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610070,
        school: 'Telelo Secondary',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.887845,
        old_latitude: -24.573143,
        quintile: 'NA',
        no_fee: 'NA',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.732972, -24.71801],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642831,
        school: 'TEME PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73297239,
        old_latitude: -24.71801046,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.809468, -24.69693],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642848,
        school: 'TENYANE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.80946776,
        old_latitude: -24.69692988,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.944933, -24.139603],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921121464,
        school: 'TETEMA SECONDARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.9449327,
        old_latitude: -24.13960293,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.641664, -23.938477],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923241436,
        school: 'THABA DORA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.64166421,
        old_latitude: -23.93847684,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.699301, -24.542447],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924642862,
        school: 'THABAMPHSE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.69930087,
        old_latitude: -24.5424467,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.608912, -24.531338],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996605501,
        school: 'THABANASWANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.60891158,
        old_latitude: -24.53133846,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.291536, -24.542104],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621162,
        school: 'THABANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.29153637,
        old_latitude: -24.54210409,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.220833, -23.949291],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541235,
        school: 'THABENG PRIMARY',
        status: 'Construction underway',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.22083255,
        old_latitude: -23.94929094,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.607331, -23.424168],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 918511432,
        school: 'THABISONG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.60733069,
        old_latitude: -23.42416791,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.767539, -24.965769],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924642893,
        school: 'THABONG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.7675386,
        old_latitude: -24.96576931,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.42066, -24.497253],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921231026,
        school: 'THADUKU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.42065959,
        old_latitude: -24.49725317,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.372577, -23.821287],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221562,
        school: 'THAKGALANG PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.37257664,
        old_latitude: -23.82128709,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.881883, -24.871742],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643148,
        school: 'THAKGUDI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.88188293,
        old_latitude: -24.87174232,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.531883, -24.383251],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261317,
        school: 'THAMAGANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.53188295,
        old_latitude: -24.38325066,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.405516, -22.990096],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320261,
        school: 'THAMBATSHIRA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.40551569,
        old_latitude: -22.99009601,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398445, -22.851987],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310950,
        school: 'THATHE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.39844454,
        old_latitude: -22.85198686,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.104661, -25.259277],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606630,
        school: 'THEJANE SECONDARY SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.10466132,
        old_latitude: -25.25927662,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.49153842, 29.89395846],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610230,
        school: 'THETE SECONDARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.89395846,
        old_latitude: -24.49153842,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.659259, -22.895728],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350644,
        school: 'THIFHURIWI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.65925859,
        old_latitude: -22.89572842,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.628424, -22.84407],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351333,
        school: 'THINASHAKA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.6284244,
        old_latitude: -22.84406998,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.415257, -23.019366],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320179,
        school: 'THIVHULAWI RUDZANI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.41525705,
        old_latitude: -23.01936619,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.081447, -25.226856],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606625,
        school: 'THLAKO COMBINED SCHOOL',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.08144723,
        old_latitude: -25.22685556,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.772425, -24.504472],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610407,
        school: 'THOBEHLALE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.77242537,
        old_latitude: -24.50447225,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.471824, -24.267049],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261331,
        school: 'THOKA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.47182397,
        old_latitude: -24.26704879,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.513263, -24.961345],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651514,
        school: 'THOKE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.51326327,
        old_latitude: -24.96134459,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.082781, -24.412085],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630678,
        school: 'THOKWANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.08278085,
        old_latitude: -24.41208459,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.61499, -24.68677],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642923,
        school: 'THOLONG PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.61499,
        old_latitude: -24.68677,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.736576, -22.805475],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361187,
        school: 'THOMANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.73657586,
        old_latitude: -22.80547492,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.392281, -23.153823],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321363,
        school: 'THOMAS NTSHAVHENI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3922809,
        old_latitude: -23.15382317,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.79385, -23.243322],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411099,
        school: 'THOMO PRIMARY-1',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.79385036,
        old_latitude: -23.24332161,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.2371, -22.86553],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330312,
        school: 'THONONDA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.2371,
        old_latitude: -22.86553,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.043533, -23.311489],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931340081,
        school: 'THONZWE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.04353333,
        old_latitude: -23.31148896,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.698482, -24.527803],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621179,
        school: 'THOROMETSANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.69848238,
        old_latitude: -24.52780307,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.870422, -24.898968],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642947,
        school: 'THOTO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.8704218,
        old_latitude: -24.89896764,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.22332378, 30.39721705],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541259,
        school: 'THUBISANE PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39721705,
        old_latitude: -24.22332378,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.897147, -24.673762],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661313,
        school: 'THULARE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.89714702,
        old_latitude: -24.67376218,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.689645, -24.77417],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642961,
        school: 'THULWAMAKWA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.68964543,
        old_latitude: -24.77416998,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.835233, -23.870325],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919241467,
        school: 'THUNE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.83523285,
        old_latitude: -23.8703255,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.589432, -22.89143],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350019,
        school: 'THUSALUSHAKA SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.58943201,
        old_latitude: -22.89142998,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.96669, -24.864926],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661320,
        school: 'TIBAMOSHITO PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.96668987,
        old_latitude: -24.86492626,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.009921, -23.648033],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251679,
        school: 'TIBANEFONTEIN PRIMARY',
        status: 'New toilets built in 2019, but needed to be fixed',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.00992077,
        old_latitude: -23.64803347,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.73958, -23.493822],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340609,
        school: 'TIDIMA HIGH',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.73957976,
        old_latitude: -23.49382168,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.439018, -24.221012],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541266,
        school: 'TIMAMOGOLO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.43901841,
        old_latitude: -24.22101175,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.876896, -23.06506],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 911360092,
        school: 'TINYIKO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.87689564,
        old_latitude: -23.06506009,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.49426, -24.759512],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924642985,
        school: 'TISANA PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.49426015,
        old_latitude: -24.75951186,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.707952, -23.040152],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 911361316,
        school: 'TITIRHELENI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.70795172,
        old_latitude: -23.04015179,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.64565, -23.033654],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 911361842,
        school: 'TIVANANI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.64564972,
        old_latitude: -23.03365445,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.310802, -23.294707],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521565,
        school: 'TIYANI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.31080244,
        old_latitude: -23.29470672,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.605838, -24.414319],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 923261362,
        school: 'TJIANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.60583805,
        old_latitude: -24.41431916,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.690439, -23.459486],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919340951,
        school: 'TLADI HIGH PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.69043908,
        old_latitude: -23.45948648,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.790412, -24.426585],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610414,
        school: 'TLAKALE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.79041227,
        old_latitude: -24.42658542,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.042224, -25.028052],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606810,
        school: 'TLHAKANANG SECONDARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.04222377,
        old_latitude: -25.0280523,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.714539, -23.455177],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141864,
        school: 'TLHAKO LOWER AND HIGHER PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.71453908,
        old_latitude: -23.45517678,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.574052, -23.283889],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411129,
        school: 'TLHARIHANI PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.57405219,
        old_latitude: -23.28388924,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.783203, -22.896813],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211886,
        school: 'TLHONA SEDIMONG PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.78320318,
        old_latitude: -22.89681289,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.642647, -23.603488],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141321,
        school: 'TLOU MATLALA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.64264724,
        old_latitude: -23.60348849,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.8548, -24.56811],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610087,
        school: 'TLOU NARE SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 29.8548,
        old_latitude: -24.56811,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.191195, -25.283989],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606724,
        school: 'TLOU-KWENA PRIMARY SCHOOL',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.19119514,
        old_latitude: -25.2839892,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.709414, -23.138684],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211909,
        school: 'TLOU-MATOME SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 28.70941419,
        old_latitude: -23.1386836,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.65308, -22.7437],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311007,
        school: 'TOMBOLAGOLE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65308,
        old_latitude: -22.7437,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.274332, -24.101624],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 926541310,
        school: 'TOURS PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.27433206,
        old_latitude: -24.10162397,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.117002, -23.743402],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 901531177,
        school: 'TSANENG COMBINE JUNIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11700248,
        old_latitude: -23.74340216,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.945083, -24.641699],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925661337,
        school: 'TSATANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.94508291,
        old_latitude: -24.64169863,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.84269, -24.441],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925610421,
        school: 'TSEKE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.84269,
        old_latitude: -24.441,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.150523, -23.373589],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 919361613,
        school: 'TSEKERE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.15052337,
        old_latitude: -23.37358878,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.703422, -24.035795],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923241504,
        school: 'TSELA CHUEU SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.70342192,
        old_latitude: -24.03579545,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.61667, -23.632211],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921141345,
        school: 'TSELAPEDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.61667016,
        old_latitude: -23.63221058,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.12076, -22.79018],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905332853,
        school: 'TSHAKANDE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.12076,
        old_latitude: -22.79018,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.303388, -23.048838],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930320575,
        school: 'TSHAKHUMA JUNIOR PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.30338849,
        old_latitude: -23.04883767,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.11724, -22.87053],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330381,
        school: 'TSHALA SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.11724,
        old_latitude: -22.87053,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.65328, -22.88615],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350637,
        school: 'TSHAMAVHUDZI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65328,
        old_latitude: -22.88615,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.657758, -22.826052],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930361118,
        school: 'TSHAMUTSHEDZI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65775763,
        old_latitude: -22.82605165,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.503605, -22.756926],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311250,
        school: 'TSHANDAMA JUNIOR AND SENIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.50360497,
        old_latitude: -22.75692619,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.657985, -22.701554],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310943,
        school: 'TSHANZHE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.65798526,
        old_latitude: -22.70155385,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.460849, -22.782101],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311422,
        school: 'TSHAPASHA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.46084865,
        old_latitude: -22.78210083,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.746238, -22.800175],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360221,
        school: 'TSHAULU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.74623825,
        old_latitude: -22.80017469,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.430353, -22.794314],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311274,
        school: 'TSHAVHADINDA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.4303529,
        old_latitude: -22.79431392,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.45339, -24.698666],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643049,
        school: 'TSHEGE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4533905,
        old_latitude: -24.69866594,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.949807, -23.269539],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921111467,
        school: 'TSHELAMMAKE PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 27.94980701,
        old_latitude: -23.2695392,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.885459, -23.216025],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211916,
        school: 'TSHENYANE JUNIOR SECONDARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.8854593,
        old_latitude: -23.21602483,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.82897, -22.38481],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310172,
        school: 'TSHENZHELANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.82897,
        old_latitude: -22.38481,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.689492, -22.671204],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310585,
        school: 'TSHIAKHATHO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.68949206,
        old_latitude: -22.67120392,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.445853, -23.098919],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321103,
        school: 'TSHIAWELO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44585261,
        old_latitude: -23.0989186,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.67570151, 30.79852981],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310608,
        school: 'TSHIBALO PRIMARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.79852981,
        old_latitude: -22.67570151,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.544977, -22.87137],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351340,
        school: 'TSHIDIMBINI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.54497741,
        old_latitude: -22.87136994,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.587362, -22.693008],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310592,
        school: 'TSHIDONGOLOLWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.58736179,
        old_latitude: -22.69300849,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.427061, -23.000647],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 915320193,
        school: 'TSHIDUMBI SENIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42706085,
        old_latitude: -23.00064722,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.670022, -22.838211],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360214,
        school: 'TSHIDZINI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.67002223,
        old_latitude: -22.83821056,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.30136, -23.059711],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320711,
        school: 'TSHIEMUEMU SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.30135966,
        old_latitude: -23.05971076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.172008, -22.829935],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330275,
        school: 'TSHIENDEULU PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.17200755,
        old_latitude: -22.82993463,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.541052, -22.872],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350163,
        school: 'TSHIFHATANI SENIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.54105223,
        old_latitude: -22.87199959,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.225098, -22.913602],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330459,
        school: 'TSHIFHENA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.22509769,
        old_latitude: -22.91360228,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.149396, -22.983581],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331193,
        school: 'TSHIFHIRE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.14939642,
        old_latitude: -22.98358094,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.552891, -22.917522],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351166,
        school: 'TSHIFIFI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55289104,
        old_latitude: -22.91752155,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.698555, -22.816985],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930360245,
        school: 'TSHIFUDI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.69855487,
        old_latitude: -22.81698509,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.388856, -23.039451],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320537,
        school: 'TSHIFULANANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38885621,
        old_latitude: -23.03945147,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.257475, -22.881406],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330046,
        school: 'TSHIHENI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.25747483,
        old_latitude: -22.88140608,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.857447, -22.586962],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310554,
        school: 'TSHIKALANGE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.85744661,
        old_latitude: -22.58696236,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.94572, -24.97911],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 996606828,
        school: 'TSHIKANOSHI SECONDARY SCHOOL',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.94572,
        old_latitude: -24.97910984,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.383408, -23.033546],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 993306201,
        school: 'TSHIKHOVHOKHOVHO',
        status: 'Construction was underway in 2019',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.38340791,
        old_latitude: -23.03354615,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.535615, -22.95855],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350354,
        school: 'TSHIKHUDINI JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.53561511,
        old_latitude: -22.95855009,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.098622, -23.263189],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921520344,
        school: 'TSHIKHUTHULA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.09862236,
        old_latitude: -23.26318924,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.791375, -23.138815],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928331254,
        school: 'TSHIKHWANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.79137531,
        old_latitude: -23.13881548,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.784223, -23.009158],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911360115,
        school: 'TSHIKIWANI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.78422348,
        old_latitude: -23.00915837,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.219544, -22.907525],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330138,
        school: 'TSHIKOMBANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.21954366,
        old_latitude: -22.90752452,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.874513, -22.482227],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310639,
        school: 'TSHIKONDENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.87451321,
        old_latitude: -22.48222724,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.249829, -22.835593],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330145,
        school: 'TSHIKOVHANI SENIOR PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24982883,
        old_latitude: -22.83559303,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.950764, -22.41828],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310233,
        school: 'TSHIKUYU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.95076422,
        old_latitude: -22.41827965,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.470276, -22.928398],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351173,
        school: 'TSHILAMBUVHE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.47027559,
        old_latitude: -22.92839846,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.35996, -22.991064],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320308,
        school: 'TSHILAVHUTUME SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.35995956,
        old_latitude: -22.99106389,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.564283, -22.957474],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350347,
        school: 'TSHILIVHO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.56428343,
        old_latitude: -22.95747381,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.216576, -22.929297],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330404,
        school: 'TSHILOGONI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.21657579,
        old_latitude: -22.9292969,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.361358, -22.852685],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310929,
        school: 'TSHILUNGWI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36135835,
        old_latitude: -22.85268544,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.94945953, 30.19090548],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330633,
        school: 'TSHILUVHADI SENIOR PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.19090548,
        old_latitude: -22.94945953,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.459801, -22.979268],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350620,
        school: 'TSHILUVHI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.4598006,
        old_latitude: -22.97926768,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.053789, -22.861797],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330688,
        school: 'TSHILUWI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0537889,
        old_latitude: -22.86179726,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.810929, -23.114904],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332202,
        school: 'TSHILWAVHUSUKU JUNIOR PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.81092877,
        old_latitude: -23.11490371,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.493319, -23.17914],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321196,
        school: 'TSHIMBUPFE SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.49331949,
        old_latitude: -23.17914005,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.420539, -22.923104],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350903,
        school: 'TSHIMEDZWA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42053879,
        old_latitude: -22.92310422,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.063521, -23.137901],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931331346,
        school: 'TSHIMONELA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.06352135,
        old_latitude: -23.13790069,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.510724, -23.034543],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350330,
        school: 'TSHINETISE PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.51072362,
        old_latitude: -23.03454303,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398606, -23.116064],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321028,
        school: 'TSHINO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.3986063,
        old_latitude: -23.11606385,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.502107, -22.80618],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310813,
        school: 'TSHIOMBO PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.50210687,
        old_latitude: -22.80618005,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.480131, -22.840996],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350149,
        school: 'TSHIPAKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.48013136,
        old_latitude: -22.84099622,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.392, -23.1157],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321264,
        school: 'TSHIPAKONI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.392,
        old_latitude: -23.1157,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.36599, -23.061806],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320766,
        school: 'TSHIPETANE SECONDARY',
        status: 'Project reached "Close Out" status in 2019',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.36599022,
        old_latitude: -23.06180556,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.677397, -22.532105],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310110,
        school: 'TSHIPISE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.6773966,
        old_latitude: -22.53210541,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.96849, -23.299321],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 906111788,
        school: 'TSHIRELETSO SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.96849,
        old_latitude: -23.29932073,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.206398, -22.903375],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330152,
        school: 'TSHIRENZHENI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.20639756,
        old_latitude: -22.90337463,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.011114, -22.9003],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330800,
        school: 'TSHIROLWE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.01111353,
        old_latitude: -22.90030026,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.720351, -23.067583],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332349,
        school: 'TSHIRULULUNI SECONDARY',
        status: 'School is on SAFE project list, but listed as not proceeding',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.72035073,
        old_latitude: -23.06758314,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.398483, -23.162127],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321455,
        school: 'TSHIRUNZANANI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39848316,
        old_latitude: -23.16212669,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.644646, -22.573185],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310097,
        school: 'TSHIRUNZINI PRIMARY',
        status: 'School is on SAFE project list, but listed as not proceeding',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.64464597,
        old_latitude: -22.57318549,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.473008, -23.154983],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320926,
        school: 'TSHISEVHE JUNIOR PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.47300828,
        old_latitude: -23.15498303,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.484693, -23.165012],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321035,
        school: 'TSHITAMBAMUNWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.484693,
        old_latitude: -23.16501178,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.409155, -22.707508],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311298,
        school: 'TSHITANDANI PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.40915522,
        old_latitude: -22.70750842,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.154709, -23.235141],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931521002,
        school: 'TSHITANGULE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.1547092,
        old_latitude: -23.23514062,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.507517, -22.883692],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350910,
        school: 'TSHITEREKE PRIMARY',
        status: 'School is on SAFE project list, but not listed as active',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.50751734,
        old_latitude: -22.88369151,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.197513, -22.87274],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330077,
        school: 'TSHITHUTHUNI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.19751308,
        old_latitude: -22.87274005,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-22.66412463, 30.28739505],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332639,
        school: 'TSHITSHIRU PRIMARY',
        status: 'School not on 2021 master list',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.28739505,
        old_latitude: -22.66412463,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.047902, -22.910714],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330596,
        school: 'TSHITUNI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.04790218,
        old_latitude: -22.91071388,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.502654, -22.985411],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350323,
        school: 'TSHIULUNGOMA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.50265401,
        old_latitude: -22.98541076,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.581953, -22.499508],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310134,
        school: 'TSHIUNGANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.58195344,
        old_latitude: -22.49950751,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.633737, -23.065713],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928332295,
        school: 'TSHIUNGULELA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.63373709,
        old_latitude: -23.0657131,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.482963, -22.883029],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351531,
        school: 'TSHIVASE SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.48296347,
        old_latitude: -22.88302934,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.175949, -23.184762],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321493,
        school: 'TSHIVHADE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.17594929,
        old_latitude: -23.18476163,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.393244, -22.979947],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320063,
        school: 'TSHIVHAMBE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39324388,
        old_latitude: -22.97994679,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.396273, -23.102252],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321059,
        school: 'TSHIVHAZWAULU PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.39627288,
        old_latitude: -23.10225172,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.243457, -22.931225],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930332370,
        school: 'TSHIVHILIDULU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24345711,
        old_latitude: -22.93122473,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.63151, -22.844],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930351579,
        school: 'TSHIVHILWI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.63151,
        old_latitude: -22.844,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.422233, -22.904645],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350927,
        school: 'TSHIVHUNGULULU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.42223256,
        old_latitude: -22.90464463,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.082973, -23.236697],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 931521026,
        school: 'TSHIVHUYUNI PRIMARY-1',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.08297325,
        old_latitude: -23.236697,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.440091, -23.010484],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 931521507,
        school: 'TSHIVHUYUNI PRIMARY-2',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44009132,
        old_latitude: -23.01048388,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.428749, -23.132278],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930321011,
        school: 'TSHIVULANA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.4287494,
        old_latitude: -23.13227761,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.347684, -22.996266],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930320216,
        school: 'TSHIWEDZA PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34768422,
        old_latitude: -22.99626626,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.34734, -22.799445],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311304,
        school: 'TSHIXWADZA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.34734033,
        old_latitude: -22.79944548,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [31.009992, -23.446915],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411143,
        school: 'Tshovani Primary',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 31.009976,
        old_latitude: -23.446939,
        quintile: '1',
        no_fee: 'Yes',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.979903, -23.651383],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251808,
        school: 'TSHUKUTSWE HIGH',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.97990278,
        old_latitude: -23.65138333,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.761183, -24.92365],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924643056,
        school: 'TSHWATLHAKGE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.76118328,
        old_latitude: -24.92365044,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.644566, -22.67261],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929311540,
        school: 'TSILAWA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.64456629,
        old_latitude: -22.67261044,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.507899, -24.833301],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651521,
        school: 'TSIMANYANE PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.50789913,
        old_latitude: -24.83330094,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.470656, -24.669687],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 924643070,
        school: 'TSOATAGO PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.47065563,
        old_latitude: -24.66968659,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.553518, -23.476908],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918511487,
        school: 'TSOGANG PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.55351776,
        old_latitude: -23.47690788,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.765323, -22.962078],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 911361293,
        school: 'TSUNDZUKANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.765323,
        old_latitude: -22.96207803,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.064171, -24.527617],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925630630,
        school: 'TSWAKO PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.0641715,
        old_latitude: -24.52761667,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.07248, -23.1856],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 920211923,
        school: 'TSWATSANE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.07248,
        old_latitude: -23.1856,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-24.26628472, 27.34521434],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909131737,
        school: 'TSWELAPELE PRIMARY',
        status: 'School closed',
        Category: 'E',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.34521434,
        old_latitude: -24.26628472,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.302177, -24.599479],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 925621216,
        school: 'TSWELOPELE PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.30217693,
        old_latitude: -24.5994792,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.610957, -22.756431],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930310746,
        school: 'TSWERA PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.61095669,
        old_latitude: -22.7564309,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.119623, -22.922085],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330947,
        school: 'TSWIME SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.11962316,
        old_latitude: -22.9220853,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.424212, -24.454906],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 921230818,
        school: 'TUBAKE SENIOR SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.4242123,
        old_latitude: -24.4549056,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.107333, -23.97446],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922221609,
        school: 'TUTWANA PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.1073332,
        old_latitude: -23.97446009,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.711295, -23.549062],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916420727,
        school: 'UKUTHULA PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.71129526,
        old_latitude: -23.54906243,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.344712, -24.84291],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 910131775,
        school: 'ULANDO INTERMEDIATE',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.34471167,
        old_latitude: -24.84290975,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [27.074894, -23.889206],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 909131782,
        school: 'VAALPENSKRAAL PRIMARY',
        status: 'School is on SAFE project list',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 27.07489426,
        old_latitude: -23.88920612,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.58399, -23.579396],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 918511500,
        school: 'VALLAMBROSA PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.5839901,
        old_latitude: -23.57939615,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.981051, -23.266971],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931341190,
        school: 'VARI PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.98105104,
        old_latitude: -23.2669713,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.968118, -23.695379],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 917420734,
        school: 'VATSWATSI PRIMARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.96811781,
        old_latitude: -23.69537867,
        quintile: '3',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.187851, -23.140682],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 905321585,
        school: 'VHUDZANI SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 30.18785079,
        old_latitude: -23.14068236,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.484566, -22.883668],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 930350934,
        school: 'VHUFULI SENIOR SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'COMBINED SCHOOL',
        old_longitude: 30.48456597,
        old_latitude: -22.88366764,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.185065, -22.961289],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330930,
        school: 'VHULAUDZI SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.18506529,
        old_latitude: -22.96128931,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.244436, -22.932755],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930330428,
        school: 'VHUSENDEKA SECONDARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.24443605,
        old_latitude: -22.93275534,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.434376, -22.836342],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 929310844,
        school: 'VHUTALU PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'INTERMEDIATE SCHOOL',
        old_longitude: 30.43437602,
        old_latitude: -22.83634154,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.142568, -22.874882],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 928330299,
        school: 'VHUTUWANGADZEBU PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.14256835,
        old_latitude: -22.87488222,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.541631, -22.877044],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 930350118,
        school: 'VONDWE PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.54163067,
        old_latitude: -22.87704392,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.303382, -23.304825],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521040,
        school: 'VUNGELA PRIMARY',
        status: 'Construction started on project around 2019',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.30338241,
        old_latitude: -23.30482544,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.444064, -23.283009],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 916411198,
        school: 'VUTLHARI HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.44406439,
        old_latitude: -23.2830095,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.218053, -23.240466],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 931521057,
        school: 'WAYENI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.21805339,
        old_latitude: -23.24046569,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.136619, -24.136789],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 906121556,
        school: 'WEENEN COMBINED',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.1366186,
        old_latitude: -24.13678931,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.98673, -23.445026],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251563,
        school: 'WEGELEGEN PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.98673031,
        old_latitude: -23.44502619,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [28.973311, -23.549837],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 922251204,
        school: 'WINGFIELD PRIMARY',
        status: 'On project list to start from 2021',
        Category: 'D',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 28.97331132,
        old_latitude: -23.54983709,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.148843, -23.198549],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521064,
        school: 'XILUMANI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.14884303,
        old_latitude: -23.19854938,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.334823, -23.411411],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521071,
        school: 'XIMUWINI COMBINED PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 30.33482329,
        old_latitude: -23.41141073,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [30.22269, -23.205571],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 912521088,
        school: 'XITACHI PRIMARY',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 30.22268955,
        old_latitude: -23.20557083,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.732378, -25.102933],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650764,
        school: 'ZAMOKUHLE HIGH',
        status: 'Not on a project list',
        Category: 'A',
        'SAFE LIST': 'No',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.73237775,
        old_latitude: -25.10293317,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.718375, -25.062719],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924650771,
        school: 'ZENZELENI SENIOR SECONDARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'Yes',
        phase: 'SECONDARY SCHOOL',
        old_longitude: 29.71837528,
        old_latitude: -25.0627186,
        quintile: '1',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.618133, -24.0988],
      },
      properties: {
        annex_a: 'No',
        annex_c: 'Yes',
        NatEmis: 923241542,
        school: 'ZOETFONTEIN SECONDARY',
        status: 'Project completed in 2018',
        Category: 'B',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.618133,
        old_latitude: -24.0988,
        quintile: '2',
        no_fee: 'YES',
      },
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [29.66796, -25.0205],
      },
      properties: {
        annex_a: 'Yes',
        annex_c: 'No',
        NatEmis: 924651477,
        school: 'ZWANANI PRIMARY',
        status: 'School is on a 2019 project list ',
        Category: 'C',
        'SAFE LIST': 'No',
        phase: 'PRIMARY SCHOOL',
        old_longitude: 29.66796,
        old_latitude: -25.02050002,
        quintile: '1',
        no_fee: 'YES',
      },
    },
  ],
}
