import * as d3 from 'd3'
import { s27schools } from './section27schools-new'
let upgrades = false
let resetChart = document.querySelector('.action-reset')
resetChart.style.display = 'none'

s27schools.forEach((d) => {
  if (d.upgrade_completed === 'Yes') {
    d.upgrade_status = 'u-yes'
  }
  if (d.upgrade_completed === 'No') {
    d.upgrade_status = 'u-no'
  }
  if (d.upgrade_completed === 'No information') {
    d.upgrade_status = 'u-ni'
  }
})

export function addSchoolChart() {
  let width = d3.select('#chart').style('width').replace('px', '')
  let height = width * 0.5
  //   d3.select('#chart').style('height', height + 'px')
  let tooltipTwo = document.querySelector('.tooltip-two')

  const svg = d3
    .select('#chart')
    .append('svg')
    .attr('width', width)
    .attr('height', height)

  //   svg
  //     .selectAll('rect')
  //     .data(s27schools)
  //     .enter()
  //     .append('rect')
  //     .attr('width', 10)
  //     .attr('height', 10)
  //     .attr('x', (d, i) => {
  //       return i * 12
  //     })
  //     .attr('y', 10)
  let x = 30
  let y = 10
  let count = 0
  let blocksH = 15
  let boxWidth = (width - 30 - 30 - blocksH * 5) / blocksH
  let boxHolder = boxWidth + 5
  d3.select('#chart').style('height', 6 * (boxWidth + 5) + 30 + 10 + 'px')

  svg
    .append('defs')
    .append('pattern')
    .attr('id', 'diagonalHatch')
    .attr('patternUnits', 'userSpaceOnUse')
    .attr('width', 4)
    .attr('height', 4)
    .append('path')
    .attr('d', 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2')
    .attr('stroke', 'gray')
    .attr('stroke-width', 1)
  let s27schoolsnew = s27schools.sort((a, b) =>
    a.classification_two > b.classification_two ? -1 : 1
  )

  s27schoolsnew.forEach((d, i) => {
    // console.log(d)

    svg
      .append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', boxWidth)
      .attr('height', boxWidth)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('id', d.NatEmis)
      .attr('class', () => {
        if (d.Upgrades == 'Yes') {
          return 'upgrade_yes'
        } else {
          return 'upgrade_no'
        }
      })
      .classed(d.S27Classification, true)
      .classed('pit-toilets-' + d.did_school_have_pit_toilets, true)
      .classed(d.upgrade_status, true)
      .style('fill', d.S27Classification)
      .style('opacity', 0.5)
      .on('mouseover', function (e) {
        d3.select(this).style('opacity', 1)

        let data = s27schools.filter((f) => f.NatEmis == e.target.id)
        console.log(data)

        let ttWidth = tooltipTwo.offsetWidth

        let ttInner = `<div class='tt-row'><div class='tt-title'>${d.School_Name}</div></div>`
        if (upgrades) {
          ttInner += `<div class='tt-row'><div class='tt-details-two'>${d.blurb_on_description_of_upgrades}</div></div>`
        }
        tooltipTwo.innerHTML = ttInner

        tooltipTwo.style.left = e.clientX - ttWidth / 2 + 'px'
        tooltipTwo.style.top = e.clientY + 10 + 'px'
      })
      .on('mousemove', (e) => {
        let ttWidth = tooltipTwo.offsetWidth
        tooltipTwo.style.left = e.clientX - ttWidth / 2 + 'px'
        tooltipTwo.style.top = e.clientY + 20 + 'px'
      })
      .on('mouseout', function (e) {
        d3.select(this).style('opacity', 0.5)
        tooltipTwo.style.left = '-1000px'
      })
    svg
      .append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', boxWidth)
      .attr('height', boxWidth)
      .attr('rx', 5)
      .attr('ry', 5)
      .attr('fill', 'url(#diagonalHatch)')
      .attr('id', d.NatEmis)
      .style('opacity', 0.5)
      .attr('class', 'hashes')

    count = count + 1

    x = x + boxHolder
    if (count > blocksH - 1) {
      count = 0
      y = y + boxHolder
      x = 30
    }
  })

  let pitToilets = false
  let upgrades = false
  let completed = false

  document.querySelector('.action-toilets').addEventListener('click', () => {
    if (pitToilets === false) {
      document.querySelector('.action-toilets').style.background = 'black'
      document.querySelector('.action-toilets').style.color = '#fff'
      svg
        .selectAll('.pit-toilets-Yes')
        .style('stroke', '#000')
        .style('stroke-width', 3)
      pitToilets = true
    } else {
      document.querySelector('.action-toilets').style.background =
        'rgb(245, 245, 245)'
      document.querySelector('.action-toilets').style.color = '#000'
      svg.selectAll('.pit-toilets-Yes').style('stroke', 'none')
      pitToilets = false
    }
  })

  document.querySelector('.action-upgrades').addEventListener('click', (e) => {
    if (upgrades === false) {
      svg.selectAll('.upgrade_no').style('fill', '#fff')
      document.querySelector('.action-upgrades').style.background = 'black'
      document.querySelector('.action-upgrades').style.color = '#fff'
      upgrades = true
    } else {
      svg.selectAll('.upgrade_no').style('fill', '#fff')
      document.querySelector('.action-upgrades').style.background =
        'rgb(245, 245, 245)'
      document.querySelector('.action-upgrades').style.color = '#000'
      let blocks = document.querySelectorAll('.upgrade_no')

      blocks.forEach((b) => {
        console.log(b.classList[1])
        b.style.fill = b.classList[1]
      })
      upgrades = false
    }
  })

  document.querySelector('.action-completed').addEventListener('click', (e) => {
    if (completed === false) {
      // svg.selectAll('.upgrade_no').style('fill', '#fff')
      svg
        .selectAll('.u-yes')
        .style('stroke', 'dodgerblue')
        .style('stroke-width', 5)
      document.querySelector('.action-completed').style.background = 'black'
      document.querySelector('.action-completed').style.color = '#fff'
      completed = true
    } else {
      // svg.selectAll('.upgrade_no').style('fill', '#fff')
      document.querySelector('.action-completed').style.background =
        'rgb(245, 245, 245)'
      document.querySelector('.action-completed').style.color = '#000'
      svg.selectAll('.u-yes').style('stroke', 'none')
      completed = false
    }
  })
}
